<div class="familiensituation-view gesuch-modul static-position">
    <h1 class="access-for-all-title" data-test="container.sztitle" translate>
        AFA_GESUCHSFORMULAR
    </h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                <span *ngIf="isMutation()" translate
                    >MUTATION_FAMSITUATION_TITLE</span
                >
                <span *ngIf="!isMutation()" translate data-test="gesuchtitle"
                    >FAMILIENSITUATION</span
                >
            </h2>
            <hr class="header" />
            <p
                *ngIf="showError()"
                role="alert"
                translate
                class="dv-error-messages"
            >
                ERROR_DATUM_GESETZT_BEI_MUTATION
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <form name="form" class="" novalidate unsaved-warning-form>
                <!--Aenderung per-->
                <div class="row" *ngIf="isMutation()">
                    <div class="col-md-12">
                        <dv-input-container-x class="form-group">
                            <div class="row">
                                <div
                                    class="col-xs-12 dv-input-container-medium"
                                >
                                    <label
                                        class="md-no-float"
                                        translate=""
                                        for="aenderungPer_ID"
                                        >AENDERUNG_PER
                                        <dv-tooltip
                                            [text]="'AENDERUNG_PER_DATUM_INFO'"
                                        ></dv-tooltip>
                                    </label>
                                    <div
                                        class="dv-input-container-medium-label"
                                    >
                                        <dv-date-picker-x
                                            [inputId]="'aenderungPer_ID'"
                                            class="input-element"
                                            [(date)]="
                                                getFamiliensituation()
                                                    .aenderungPer
                                            "
                                            (dateChange)="onDatumBlur()"
                                            [datePickerEnabled]="false"
                                            [disabled]="isGesuchReadonly()"
                                        >
                                        </dv-date-picker-x>
                                        <dv-bisher-x
                                            [showBisher]="showBisher()"
                                            [deklaration]="
                                                getFamiliensituationGS()
                                                    ?.aenderungPer
                                            "
                                            [korrektur]="
                                                getFamiliensituation()
                                                    ?.aenderungPer
                                            "
                                        ></dv-bisher-x>
                                    </div>
                                </div>
                            </div>
                        </dv-input-container-x>
                    </div>
                </div>

                <!--gesuchstellerKardinalitaet-->
                <div class="row">
                    <div class="col-xs-12 dv-input-container-question">
                        <div class="form-group">
                            <span class="ebegu-h1 required">
                                <span
                                    >{{
                                        'FAMILIENSITUATION_FRAGE_2' | translate
                                    }}
                                    <dv-tooltip
                                        [text]="
                                            'FAMILIENSITUATION_FRAGE_2_HINT'
                                        "
                                    ></dv-tooltip
                                ></span>
                            </span>
                            <dv-radio-container-x>
                                <mat-radio-group
                                    [(ngModel)]="
                                        getFamiliensituation()
                                            .gesuchstellerKardinalitaet
                                    "
                                    [required]="true"
                                    #gesuchstellerKardinalitaet="ngModel"
                                    name="gesuchstellerKardinalitaet"
                                    data-test="container.gesuchsteller-kardinalitaet"
                                    class="input-element sp"
                                    [disabled]="
                                        !(
                                            isFamiliensituationEnabled() &&
                                            isOneOfRoles(allowedRoles)
                                        )
                                    "
                                    [attr.aria-label]="
                                        'FAMILIENSITUATION_FRAGE_2' | translate
                                    "
                                    aria-describedby="gesuchstellerKardinalitaet-error"
                                >
                                    <mat-radio-button
                                        data-test="familienstatus.VERHEIRATET"
                                        [value]="
                                            TSGesuchstellerKardinalitaet.ZU_ZWEIT
                                        "
                                        >{{
                                            'LABEL_JA' | translate
                                        }}</mat-radio-button
                                    >
                                    <mat-radio-button
                                        [value]="
                                            TSGesuchstellerKardinalitaet.ALLEINE
                                        "
                                        >{{
                                            'LABEL_NEIN' | translate
                                        }}</mat-radio-button
                                    >
                                </mat-radio-group>
                            </dv-radio-container-x>
                            <dv-error-messages
                                *ngIf="
                                    savedClicked ||
                                    gesuchstellerKardinalitaet.touched
                                "
                                [inputId]="'gesuchstellerKardinalitaet'"
                                class="error"
                            ></dv-error-messages>
                            <dv-bisher-x
                                [showBisher]="showBisher()"
                                [specificBisherText]="getBisherText()"
                                [deklaration]="
                                    getFamiliensituationGS()
                                        ?.gesuchstellerKardinalitaet
                                "
                                [korrektur]="
                                    getFamiliensituation()
                                        ?.gesuchstellerKardinalitaet
                                "
                            ></dv-bisher-x>
                        </div>
                    </div>
                </div>

                <dv-warning
                    ><span>{{
                        'FAMILIENSITUATION_ONLY_KINDER_AUS_HAUSHALT_WARNING'
                            | translate
                    }}</span></dv-warning
                >

                <dv-warning>
                    <span
                        [innerHTML]="
                            'FAMILIENSITUATION_PATCHWORKFAMILIE' | translate
                        "
                    ></span>
                    <ul>
                        <li>
                            <span
                                [innerHTML]="
                                    'FAMILIENSITUATION_PATCHWORKFAMILIE_VERHEIRATET'
                                        | translate
                                "
                            ></span>
                        </li>
                        <li>
                            <span
                                [innerHTML]="
                                    'FAMILIENSITUATION_PATCHWORKFAMILIE_UNVERHEIRATET'
                                        | translate
                                "
                            ></span>
                        </li>
                        <li>
                            <span
                                [innerHTML]="
                                    'FAMILIENSITUATION_PATCHWORKFAMILIE_UNVERHEIRATET_GEMEINSAME_KINDER'
                                        | translate
                                "
                            ></span>
                        </li>
                        <li>
                            <span
                                [innerHTML]="
                                    'FAMILIENSITUATION_PATCHWORKFAMILIE_GEMEINSAME_KINDER'
                                        | translate
                                "
                            ></span>
                        </li>
                    </ul>
                    <span
                        [innerHTML]="
                            'FAMILIENSITUATION_PATCHWORKFAMILIE_UNKLARHEITEN'
                                | translate
                        "
                    ></span>
                </dv-warning>

                <div class="row">
                    <div class="col-md-12">
                        <dv-navigation-x
                            *dvNgShowElement="
                                true;
                                roles: getAllRolesButTraegerschaftInstitutionSteueramt()
                            "
                            [dvPrevious]="true"
                            [dvNext]="true"
                            (dvSave)="confirmAndSave($event.onResult)"
                        >
                        </dv-navigation-x>
                        <dv-navigation-x
                            *dvNgShowElement="
                                true;
                                roles: getTraegerschaftInstitutionSteueramtOnlyRoles()
                            "
                            [dvNext]="true"
                        >
                        </dv-navigation-x>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
