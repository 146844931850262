<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="tagesschule-angaben">
    <h1 class="access-for-all-title">
        {{
            'LASTENAUSGLEICH_TAGESSCHULEN_ANGABEN_TAGESSCHULEN_TITLE'
                | translate
                    : {name: latsAngabenInstitutionContainer?.institution?.name}
        }}
    </h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                {{
                    'LASTENAUSGLEICH_TAGESSCHULEN_ANGABEN_TAGESSCHULEN_TITLE'
                        | translate
                            : {
                                  name: latsAngabenInstitutionContainer
                                      ?.institution.name
                              }
                }}
            </h2>
            <hr class="header" />
        </div>
    </div>

    <div
        class="well well-with-button"
        *ngIf="angabenAusKibon && (canSeeDurchKibonAusfuellen | async)"
    >
        <span translate>LATS_AUTOMATISCHE_BERECHNUNGEN_HINT</span>
        <!--        <div class="flex-whitespace-item"></div>-->
        <button
            class="dv-btn cancel-button"
            (click)="fillOutCalculationsFromKiBon()"
        >
            {{ 'LATS_DURCH_KIBON_AUSFUELLEN' | translate }}
        </button>
    </div>

    <form
        *ngIf="latsAngabenInstitutionContainer"
        [formGroup]="form"
        class="tagesschule-angaben-wrapper"
        (ngSubmit)="onFormSubmit()"
    >
        <div class="max-width-container-750">
            <h3 translate class="uppercase">ALLGEMEINE_ANGABEN</h3>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.isLehrbetrieb.errors
                    }"
                    id="isLehrbetrieb-radio-group"
                >
                    {{
                        'LATS_IST_LEHRBETRIEB'
                            | translate
                                : {periode: gesuchsPeriode | nextPeriodeStr}
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="isLehrbetrieb-radio-group"
                        class=""
                        name="isLehrbetrieb"
                        horizontal
                        [disabled]="form.controls.isLehrbetrieb.disabled"
                        formControlName="isLehrbetrieb"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.isLehrbetrieb
                        "
                        [korrektur]="form.value.isLehrbetrieb"
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 class="uppercase">
                {{ 'LATS_QUANTITATIVE_ANGABEN' | translate }}
            </h3>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.anzahlEingeschriebeneKinder.errors
                    }"
                >
                    {{
                        'LATS_ANZAHL_EINGESCHRIEBENE_KINDER'
                            | translate: {datum: (stichtag | async)}
                    }}
                    <dv-tooltip
                        [text]="
                            'LATS_ANZAHL_EINGESCHRIEBENE_KINDER_INFO'
                                | translate
                        "
                    ></dv-tooltip>
                </label>

                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="anzahlEingeschriebeneKinder"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls.anzahlEingeschriebeneKinder.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.anzahlEingeschriebeneKinder.errors
                                ?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.anzahlEingeschriebeneKinder.errors
                                ?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.anzahlEingeschriebeneKinder
                        "
                        [korrektur]="form.value.anzahlEingeschriebeneKinder"
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.anzahlEingeschriebeneKinderBasisstufe
                                .errors
                    }"
                >
                    {{ 'LATS_DAVON_BASISSTUFE' | translate }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="anzahlEingeschriebeneKinderBasisstufe"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls.anzahlEingeschriebeneKinderBasisstufe
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.anzahlEingeschriebeneKinderBasisstufe
                                .errors?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.anzahlEingeschriebeneKinderBasisstufe
                        "
                        [korrektur]="
                            form.value.anzahlEingeschriebeneKinderBasisstufe
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls
                                .anzahlEingeschriebeneKinderKindergarten.errors
                    }"
                >
                    {{ 'LATS_DAVON_KINDERGARTEN' | translate }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="anzahlEingeschriebeneKinderKindergarten"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls
                                .anzahlEingeschriebeneKinderKindergarten.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls
                                .anzahlEingeschriebeneKinderKindergarten.errors
                                ?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.anzahlEingeschriebeneKinderKindergarten
                        "
                        [korrektur]="
                            form.value.anzahlEingeschriebeneKinderKindergarten
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.anzahlEingeschriebeneKinderPrimarstufe
                                .errors
                    }"
                >
                    {{ 'LATS_DAVON_PRIMARSTUFE' | translate }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="anzahlEingeschriebeneKinderPrimarstufe"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls.anzahlEingeschriebeneKinderPrimarstufe
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.anzahlEingeschriebeneKinderPrimarstufe
                                .errors?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.anzahlEingeschriebeneKinderPrimarstufe
                        "
                        [korrektur]="
                            form.value.anzahlEingeschriebeneKinderPrimarstufe
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls
                                .anzahlEingeschriebeneKinderSekundarstufe.errors
                    }"
                    [innerHTML]="'LATS_DAVON_SEKUNDARSTUFE' | translate"
                >
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="anzahlEingeschriebeneKinderSekundarstufe"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls
                                .anzahlEingeschriebeneKinderSekundarstufe.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls
                                .anzahlEingeschriebeneKinderSekundarstufe.errors
                                ?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.anzahlEingeschriebeneKinderSekundarstufe
                        "
                        [korrektur]="
                            form.value.anzahlEingeschriebeneKinderSekundarstufe
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <div
                class="well well-status-warten"
                *ngIf="
                    !(
                        (allAnzahlFieldsFilledOut() &&
                            abweichungenAnzahlKinder === 0) ||
                        allAnzahlFieldsEmpty()
                    )
                "
            >
                <i class="fa fa-exclamation" aria-hidden="true"></i>
                <span>
                    <span translate>LATS_ABWEICHUNGEN_ANZAHL_KINDER</span>
                    <span
                        *ngIf="
                            autoFilled &&
                            anzahlEingeschriebeneKinder?.vorschulalter === 1
                        "
                    >
                        {{
                            'LATS_ABWEICHUNGEN_ANZAHL_KINDER_VORSCHULALTER_HINT'
                                | translate
                        }}
                    </span>
                    <span
                        *ngIf="
                            autoFilled &&
                            anzahlEingeschriebeneKinder?.vorschulalter > 1
                        "
                    >
                        {{
                            'LATS_ABWEICHUNGEN_ANZAHL_KINDER_VORSCHULALTER_HINT_MEHRZAHL'
                                | translate
                                    : {
                                          numberAntraege:
                                              anzahlEingeschriebeneKinder?.vorschulalter
                                      }
                        }}
                    </span>
                </span>
            </div>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls
                                .anzahlEingeschriebeneKinderMitBesonderenBeduerfnissen
                                .errors
                    }"
                >
                    {{
                        'LATS_ANZAHL_KINDER_BESONDERE_ANFORDERUNGEN' | translate
                    }}
                    <dv-tooltip
                        [text]="
                            'LATS_ANZAHL_KINDER_BESONDERE_ANFORDERUNGEN_INFO'
                                | translate
                        "
                    ></dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="anzahlEingeschriebeneKinderMitBesonderenBeduerfnissen"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls
                                .anzahlEingeschriebeneKinderMitBesonderenBeduerfnissen
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls
                                .anzahlEingeschriebeneKinderMitBesonderenBeduerfnissen
                                .errors?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.anzahlEingeschriebeneKinderMitBesonderenBeduerfnissen
                        "
                        [korrektur]="
                            form.value
                                .anzahlEingeschriebeneKinderMitBesonderenBeduerfnissen
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls
                                .anzahlEingeschriebeneKinderVolksschulangebot
                                .errors
                    }"
                >
                    {{
                        'LATS_ANZAHL_KINDER_BESONDERE_VOLKSSCHULANGEBOT'
                            | translate
                    }}
                    <dv-tooltip
                        [text]="
                            'LATS_ANZAHL_KINDER_BESONDERE_VOLKSSCHULANGEBOT_INFO'
                                | translate
                        "
                    ></dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="anzahlEingeschriebeneKinderVolksschulangebot"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls
                                .anzahlEingeschriebeneKinderVolksschulangebot
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls
                                .anzahlEingeschriebeneKinderVolksschulangebot
                                .errors?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.anzahlEingeschriebeneKinderVolksschulangebot
                        "
                        [korrektur]="
                            form.value
                                .anzahlEingeschriebeneKinderVolksschulangebot
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider class="margin-bottom-20"></mat-divider>

        <div class="max-width-container-750">
            <dv-input-container-x>
                <label>{{
                    'LATS_IN_BETREUUNG_EINLEITUNG'
                        | translate: {datum: stichtag | async}
                }}</label>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.durchschnittKinderProTagFruehbetreuung
                                .errors
                    }"
                >
                    {{ 'LATS_IN_FRUEH_BETREUUNG' | translate }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="durchschnittKinderProTagFruehbetreuung"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls.durchschnittKinderProTagFruehbetreuung
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.durchschnittKinderProTagFruehbetreuung
                                .errors?.isNotFloat
                        "
                        translate
                        >ERROR_NUMBER
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.durchschnittKinderProTagFruehbetreuung
                                .errors?.pattern &&
                            !form.controls
                                .durchschnittKinderProTagFruehbetreuung.errors
                                ?.isNotFloat
                        "
                    >
                        {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.durchschnittKinderProTagFruehbetreuung
                        "
                        [korrektur]="
                            form.value.durchschnittKinderProTagFruehbetreuung
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.durchschnittKinderProTagMittag.errors
                    }"
                >
                    {{ 'LATS_ANZAHL_IN_MITTAGSBETREUUNG' | translate }}
                </label>

                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="durchschnittKinderProTagMittag"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls.durchschnittKinderProTagMittag.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.durchschnittKinderProTagMittag.errors
                                ?.isNotFloat
                        "
                        translate
                        >ERROR_NUMBER
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.durchschnittKinderProTagMittag.errors
                                ?.pattern &&
                            !form.controls.durchschnittKinderProTagMittag.errors
                                ?.isNotFloat
                        "
                    >
                        {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.durchschnittKinderProTagMittag
                        "
                        [korrektur]="form.value.durchschnittKinderProTagMittag"
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.durchschnittKinderProTagNachmittag1
                                .errors
                    }"
                    [innerHTML]="'LATS_ANZAHL_NACHMITTAG_1' | translate"
                >
                </label>

                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="durchschnittKinderProTagNachmittag1"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls.durchschnittKinderProTagNachmittag1
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.durchschnittKinderProTagNachmittag1
                                .errors?.isNotFloat
                        "
                        translate
                        >ERROR_NUMBER
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.durchschnittKinderProTagNachmittag1
                                .errors?.pattern &&
                            !form.controls.durchschnittKinderProTagNachmittag1
                                .errors?.isNotFloat
                        "
                    >
                        {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.durchschnittKinderProTagNachmittag1
                        "
                        [korrektur]="
                            form.value.durchschnittKinderProTagNachmittag1
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.durchschnittKinderProTagNachmittag2
                                .errors
                    }"
                    [innerHTML]="'LATS_ANZAHL_NACHMITTAG_2' | translate"
                >
                </label>

                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="durchschnittKinderProTagNachmittag2"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls.durchschnittKinderProTagNachmittag2
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.durchschnittKinderProTagNachmittag2
                                .errors?.isNotFloat
                        "
                        translate
                        >ERROR_NUMBER
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls.durchschnittKinderProTagNachmittag2
                                .errors?.pattern &&
                            !form.controls.durchschnittKinderProTagNachmittag2
                                .errors?.isNotFloat
                        "
                    >
                        {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.durchschnittKinderProTagNachmittag2
                        "
                        [korrektur]="
                            form.value.durchschnittKinderProTagNachmittag2
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls
                                .betreuungsstundenEinschliesslichBesondereBeduerfnisse
                                .errors
                    }"
                    required
                >
                    {{
                        'LATS_BETREUUNGSSTUNDEN_EINSCHLIESSLICH_BESONDERE_BEDUERFNISSE'
                            | translate
                                : {
                                      schuljahr:
                                          gesuchsPeriode?.gesuchsperiodeString
                                  }
                    }}
                    <dv-tooltip
                        [text]="
                            'LATS_BETREUUNGSSTUNDEN_EINSCHLIESSLICH_BESONDERE_BEDUERFNISSE_INFO'
                                | translate
                                    : {
                                          jahr: gemeindeAntragContainer?.gesuchsperiode?.getBasisJahrPlus2()
                                      }
                        "
                    ></dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="betreuungsstundenEinschliesslichBesondereBeduerfnisse"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            formFreigebenTriggered &&
                            form.controls
                                .betreuungsstundenEinschliesslichBesondereBeduerfnisse
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.controls
                                .betreuungsstundenEinschliesslichBesondereBeduerfnisse
                                .errors?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.betreuungsstundenEinschliesslichBesondereBeduerfnisse
                        "
                        [korrektur]="
                            form.value
                                .betreuungsstundenEinschliesslichBesondereBeduerfnisse
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 translate class="uppercase">
                QUALITATIVE_VORGABEN_TAGESSCHULVERORDNUNG
            </h3>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.schuleAufBasisOrganisatorischesKonzept
                                .errors
                    }"
                    id="schuleAufBasisOrganisatorischesKonzept-radio-group"
                >
                    {{ 'LATS_AUF_BASIS_ORG_KONZEPT' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="schuleAufBasisOrganisatorischesKonzept-radio-group"
                        class=""
                        name="schuleAufBasisOrganisatorischesKonzept"
                        horizontal
                        [disabled]="
                            form.controls.schuleAufBasisOrganisatorischesKonzept
                                .disabled
                        "
                        formControlName="schuleAufBasisOrganisatorischesKonzept"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.schuleAufBasisOrganisatorischesKonzept
                        "
                        [korrektur]="
                            form.value.schuleAufBasisOrganisatorischesKonzept
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.schuleAufBasisPaedagogischesKonzept
                                .errors
                    }"
                    id="schuleAufBasisPaedagogischesKonzept-radio-group"
                >
                    {{ 'LATS_AUF_BASIS_PAEDAGOGISCH_KONZEPT' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="schuleAufBasisPaedagogischesKonzept-radio-group"
                        class=""
                        name="schuleAufBasisPaedagogischesKonzept"
                        horizontal
                        [disabled]="
                            form.controls.schuleAufBasisPaedagogischesKonzept
                                .disabled
                        "
                        formControlName="schuleAufBasisPaedagogischesKonzept"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.schuleAufBasisPaedagogischesKonzept
                        "
                        [korrektur]="
                            form.value.schuleAufBasisPaedagogischesKonzept
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.raeumlicheVoraussetzungenEingehalten
                                .errors
                    }"
                    id="raeumlicheVoraussetzungenEingehalten-radio-group"
                >
                    {{
                        'LATS_RAEULICHE_VORAUSSETZUNGEN_EINGEHALTEN' | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="raeumlicheVoraussetzungenEingehalten-radio-group"
                        class=""
                        name="raeumlicheVoraussetzungenEingehalten"
                        horizontal
                        [disabled]="
                            form.controls.raeumlicheVoraussetzungenEingehalten
                                .disabled
                        "
                        formControlName="raeumlicheVoraussetzungenEingehalten"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.raeumlicheVoraussetzungenEingehalten
                        "
                        [korrektur]="
                            form.value.raeumlicheVoraussetzungenEingehalten
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.betreuungsverhaeltnisEingehalten
                                .errors
                    }"
                    id="betreuungsverhaeltnisEingehalten-radio-group"
                >
                    {{ 'LATS_BETREUUNGSVERHAELTNIS_EINGEHALTEN' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="betreuungsverhaeltnisEingehalten-radio-group"
                        class=""
                        name="betreuungsverhaeltnisEingehalten"
                        horizontal
                        [disabled]="
                            form.controls.betreuungsverhaeltnisEingehalten
                                .disabled
                        "
                        formControlName="betreuungsverhaeltnisEingehalten"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.betreuungsverhaeltnisEingehalten
                        "
                        [korrektur]="
                            form.value.betreuungsverhaeltnisEingehalten
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formFreigebenTriggered &&
                            form.controls.ernaehrungsGrundsaetzeEingehalten
                                .errors
                    }"
                    id="ernaehrungsGrundsaetzeEingehalten-radio-group"
                >
                    {{ 'LATS_ERNAEHRUNGS_GRUNDSAETZE_EINGEHALTEN' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="ernaehrungsGrundsaetzeEingehalten-radio-group"
                        class=""
                        name="ernaehrungsGrundsaetzeEingehalten"
                        horizontal
                        [disabled]="
                            form.controls.ernaehrungsGrundsaetzeEingehalten
                                .disabled
                        "
                        formControlName="ernaehrungsGrundsaetzeEingehalten"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.ernaehrungsGrundsaetzeEingehalten
                        "
                        [korrektur]="
                            form.value.ernaehrungsGrundsaetzeEingehalten
                        "
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="
                            'LATS_BISHER_LABEL_INSTITUTION' | translate
                        "
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>
        </div>
        <mat-divider></mat-divider>
        <div class="max-width-container-750">
            <h3 class="uppercase">
                {{ 'LATS_ANGABEN_GEMEINDE_BEMERKUNGEN' | translate }}
            </h3>

            <dv-input-container-x [verticalAlign]="true">
                <mat-form-field floatLabel="always" class="no-margin-left">
                    <textarea
                        matInput
                        formControlName="bemerkungen"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            latsAngabenInstitutionContainer?.angabenDeklaration
                                ?.bemerkungen
                        "
                        [korrektur]="form.value.bemerkungen"
                        [showBisher]="
                            latsAngabenInstitutionContainer?.isAtLeastInBearbeitungGemeinde()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider></mat-divider>
        <div class="max-width-container-750">
            <h3 translate class="uppercase">TAGESSCHULE_OEFFNUNGSZEITEN</h3>
            <table class="tagesschule-oeffnungszeiten">
                <tr class="head">
                    <th></th>
                    <th translate>MONDAY</th>
                    <th translate>TUESDAY</th>
                    <th translate>WEDNESDAY</th>
                    <th translate>THURSDAY</th>
                    <th translate>FRIDAY</th>
                </tr>
                <tr>
                    <td translate class="big-border-left">
                        OEFFNUNGSZEIT_FRUEHBETREUUNG
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="fruehbetreuungOeffnungszeiten.montag"
                            (change)="
                                fruehbetreuungOeffnungszeiten.montag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="fruehbetreuungOeffnungszeiten.dienstag"
                            (change)="
                                fruehbetreuungOeffnungszeiten.dienstag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="fruehbetreuungOeffnungszeiten.mittwoch"
                            (change)="
                                fruehbetreuungOeffnungszeiten.mittwoch =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="fruehbetreuungOeffnungszeiten.donnerstag"
                            (change)="
                                fruehbetreuungOeffnungszeiten.donnerstag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td class="big-border-right">
                        <mat-checkbox
                            [checked]="fruehbetreuungOeffnungszeiten.freitag"
                            (change)="
                                fruehbetreuungOeffnungszeiten.freitag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                </tr>
                <tr class="dunkel">
                    <td class="big-border-left"></td>
                    <td colspan="5" class="big-border-right" translate>
                        OEFFNUNGSZEIT_UNTERRICHT_BLOCKZEITEN
                    </td>
                </tr>
                <tr>
                    <td translate class="big-border-left">
                        OEFFNUNGSZEIT_MITTAGSBETREUUNG
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="mittagsbetreuungOeffnungszeiten.montag"
                            (change)="
                                mittagsbetreuungOeffnungszeiten.montag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="mittagsbetreuungOeffnungszeiten.dienstag"
                            (change)="
                                mittagsbetreuungOeffnungszeiten.dienstag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="mittagsbetreuungOeffnungszeiten.mittwoch"
                            (change)="
                                mittagsbetreuungOeffnungszeiten.mittwoch =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                mittagsbetreuungOeffnungszeiten.donnerstag
                            "
                            (change)="
                                mittagsbetreuungOeffnungszeiten.donnerstag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td class="big-border-right">
                        <mat-checkbox
                            [checked]="mittagsbetreuungOeffnungszeiten.freitag"
                            (change)="
                                mittagsbetreuungOeffnungszeiten.freitag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                </tr>
                <tr>
                    <td
                        [innerHTML]="
                            'OEFFNUNGSZEIT_NACHMITTAGSBETREUUNG_1' | translate
                        "
                        class="big-border-left"
                    ></td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung1Oeffnungszeiten.montag
                            "
                            (change)="
                                nachmittagsbetreuung1Oeffnungszeiten.montag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung1Oeffnungszeiten.dienstag
                            "
                            (change)="
                                nachmittagsbetreuung1Oeffnungszeiten.dienstag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung1Oeffnungszeiten.mittwoch
                            "
                            (change)="
                                nachmittagsbetreuung1Oeffnungszeiten.mittwoch =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung1Oeffnungszeiten.donnerstag
                            "
                            (change)="
                                nachmittagsbetreuung1Oeffnungszeiten.donnerstag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td class="big-border-right">
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung1Oeffnungszeiten.freitag
                            "
                            (change)="
                                nachmittagsbetreuung1Oeffnungszeiten.freitag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                </tr>
                <tr>
                    <td
                        [innerHTML]="
                            'OEFFNUNGSZEIT_NACHMITTAGSBETREUUNG_2' | translate
                        "
                        class="big-border-left"
                    ></td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung2Oeffnungszeiten.montag
                            "
                            (change)="
                                nachmittagsbetreuung2Oeffnungszeiten.montag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung2Oeffnungszeiten.dienstag
                            "
                            (change)="
                                nachmittagsbetreuung2Oeffnungszeiten.dienstag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung2Oeffnungszeiten.mittwoch
                            "
                            (change)="
                                nachmittagsbetreuung2Oeffnungszeiten.mittwoch =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung2Oeffnungszeiten.donnerstag
                            "
                            (change)="
                                nachmittagsbetreuung2Oeffnungszeiten.donnerstag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                    <td class="big-border-right">
                        <mat-checkbox
                            [checked]="
                                nachmittagsbetreuung2Oeffnungszeiten.freitag
                            "
                            (change)="
                                nachmittagsbetreuung2Oeffnungszeiten.freitag =
                                    $event.checked
                            "
                            [disabled]="!canEditForm()"
                        ></mat-checkbox>
                    </td>
                </tr>
            </table>
        </div>

        <div class="actions">
            <button
                class="dv-btn cancel-button uppercase"
                type="button"
                *ngIf="
                    ((canSeeFalscheAngaben | async) && isInstiUser) ||
                    latsAngabenInstitutionContainer?.isGeprueftGemeinde()
                "
                (click)="onFalscheAngaben()"
            >
                <span translate>FERIENBETREUUNG_ANGABEN_KORRIGIEREN</span>
            </button>

            <dv-loading-button
                class="uppercase"
                *ngIf="canSeeFreigeben | async"
                type="button"
                (buttonClick)="onFreigeben()"
            >
                <span translate>FREIGEBEN</span>
            </dv-loading-button>

            <dv-loading-button
                class="uppercase"
                *ngIf="canSeeAbschliessen | async"
                type="button"
                (buttonClick)="onGeprueft()"
            >
                <span translate>GEPRUEFT</span>
            </dv-loading-button>

            <button
                class="dv-btn cancel-button uppercase"
                type="button"
                *ngIf="canSeeSave | async"
                (click)="onFormSubmit()"
            >
                <span translate>SAVE</span>
            </button>

            <button
                class="dv-btn cancel-button margin-left-5 uppercase"
                type="button"
                *ngIf="canSeeSave | async"
                (click)="navigateBack($event)"
            >
                <span translate>CANCEL</span>
            </button>

            <button
                class="dv-btn cancel-button uppercase margin-left-5"
                type="button"
                *ngIf="
                    (canSeeFalscheAngaben | async) &&
                    !isInstiUser &&
                    latsAngabenInstitutionContainer?.isInPruefungGemeinde()
                "
                (click)="onFalscheAngaben()"
            >
                <span translate
                    >FERIENBETREUUNG_ANGABEN_ZURUECK_AN_INSTITUTION</span
                >
            </button>
        </div>
    </form>
</div>
