<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->
<input
    id="{{ inputId }}"
    type="text"
    [name]="name"
    #inputField="ngModel"
    [ngModelOptions]="{updateOn: 'blur'}"
    [ngModel]="model | ebeguNumber"
    (input)="valueHasChange($event)"
    (blur)="onBlur($event)"
    [required]="required"
    [disabled]="disabled"
    [pattern]="getInputValidationPattern()"
    class="form-control"
/>
<dv-eingabe-hint
    *ngIf="vergleichswert"
    [neuerWert]="neuerWert"
    [vergleichswert]="vergleichswert"
    [label]="vergleichswertLabel"
>
</dv-eingabe-hint>
<dv-bisher-x
    [showBisher]="showBisher"
    [deklaration]="deklaration"
    [korrektur]="korrektur"
>
</dv-bisher-x>
<dv-error-messages inputId="{{ inputId }}" [errorObject]="inputField.errors">
</dv-error-messages>
