<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div
    *ngIf="allowedMap$ | async as allowedGemeinden"
    [ngClass]="{'form-group': true, 'ie-workaround': isIE()}"
>
    <label *ngIf="showLabel" [for]="inputId" translate>GEMEINDEN</label>
    <mat-select
        class="form-control"
        [ngModel]="selected"
        [name]="inputId"
        [disabled]="disabled"
        [id]="inputId"
        [required]="required"
        [multiple]="true"
    >
        <mat-option
            *ngFor="let item of allowedGemeinden | keyvalue"
            (onSelectionChange)="onSelectionChange($event)"
            [value]="item.key"
        >
            {{ item.key.name }}
        </mat-option>
    </mat-select>
    <dv-error-messages
        [inputId]="inputId"
        [errorObject]="form.controls[inputId]?.errors"
    >
    </dv-error-messages>
</div>
