<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="ferienbetreuung-nutzung">
    <h1 class="access-for-all-title" translate>
        FERIENBETREUUNG_NUTZUNG_TITLE
    </h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                {{
                    'FERIENBETREUUNG_NUTZUNG_TITLE'
                        | translate
                            : {
                                  periode:
                                      container?.gesuchsperiode
                                          ?.gesuchsperiodeString,
                                  von: addOneMonthToMoment(
                                      container?.gesuchsperiode?.gueltigkeit
                                          .gueltigAb
                                  ),
                                  bis: addOneMonthToMoment(
                                      container?.gesuchsperiode?.gueltigkeit
                                          .gueltigBis
                                  )
                              }
                }}
            </h2>
            <hr class="header" />
        </div>
    </div>

    <form *ngIf="container" [formGroup]="form">
        <h3 translate class="uppercase">FERIENBETREUUNG_BETREUUNGSTAGE</h3>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    for="anzahlBetreuungstageKinderBern_id"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls.anzahlBetreuungstageKinderBern
                                .errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_ANZAHL_BETREUUNGTAGE_KINDER_BERN'
                            | translate
                    }}
                    <dv-tooltip
                        inputId="anzahlBetreuungstageKinderBern_id"
                        [text]="
                            'FERIENBETREUUNG_ANZAHL_BETREUUNGTAGE_KINDER_BERN_HINT'
                                | translate
                        "
                    >
                    </dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahlBetreuungstageKinderBern_id"
                        formControlName="anzahlBetreuungstageKinderBern"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls.anzahlBetreuungstageKinderBern
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls.anzahlBetreuungstageKinderBern
                                .errors?.isNotHalf
                        "
                        translate
                        >ERROR_NOT_HALF
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.anzahlBetreuungstageKinderBern
                        "
                        [korrektur]="
                            this.form.value.anzahlBetreuungstageKinderBern
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.anzahlBetreuungstageKinderBern
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="custom-errors">
            <div
                *ngIf="
                    form &&
                    this.form.controls.anzahlBetreuungstageKinderBern.errors
                        ?.betreuungstageError
                "
            >
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span translate>ERROR_FB_BETREUUNGSTAGE</span>
            </div>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="betreuungstageKinderDieserGemeinde_id"
                    class="padding-left-20"
                    required
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls
                                .betreuungstageKinderDieserGemeinde.errors
                    }"
                    [innerHTML]="
                        'FERIENBETREUUNG_BETREUUNGSTAGE_KINDER_DIESER_GEMEINDE'
                            | translate
                    "
                >
                    <dv-tooltip
                        inputId="betreuungstageKinderDieserGemeinde_id"
                        [text]="
                            'FERIENBETREUUNG_BETREUUNGSTAGE_KINDER_DIESER_GEMEINDE_HINT'
                                | translate
                        "
                    >
                    </dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="betreuungstageKinderDieserGemeinde_id"
                        formControlName="betreuungstageKinderDieserGemeinde"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls
                                .betreuungstageKinderDieserGemeinde.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls
                                .betreuungstageKinderDieserGemeinde.errors
                                ?.isNotHalf
                        "
                        translate
                        >ERROR_NOT_HALF
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.betreuungstageKinderDieserGemeinde
                        "
                        [korrektur]="
                            this.form.value.betreuungstageKinderDieserGemeinde
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.betreuungstageKinderDieserGemeinde
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="betreuungstageKinderDieserGemeindeSonderschueler_id"
                    class="padding-left-50"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls
                                .betreuungstageKinderDieserGemeindeSonderschueler
                                .errors
                    }"
                    [innerHTML]="
                        'FERIENBETREUUNG_BETREUUNGSTAGE_SONDERSCHUELER'
                            | translate
                    "
                >
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="betreuungstageKinderDieserGemeindeSonderschueler_id"
                        formControlName="betreuungstageKinderDieserGemeindeSonderschueler"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls
                                .betreuungstageKinderDieserGemeindeSonderschueler
                                .errors?.isNotHalf
                        "
                        translate
                        >ERROR_NOT_HALF
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.betreuungstageKinderDieserGemeindeSonderschueler
                        "
                        [korrektur]="
                            this.form.value
                                .betreuungstageKinderDieserGemeindeSonderschueler
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.betreuungstageKinderDieserGemeindeSonderschueler
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="custom-errors">
            <div
                *ngIf="
                    form &&
                    this.form.controls
                        .betreuungstageKinderDieserGemeindeSonderschueler.errors
                        ?.sonderschuelerError
                "
                translate
            >
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span translate>ERROR_FB_SONDERSCHUELER</span>
            </div>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    class="padding-left-20"
                    for="davonBetreuungstageKinderAndererGemeinden"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls
                                .davonBetreuungstageKinderAndererGemeinden
                                .errors
                    }"
                    [innerHTML]="
                        'FERIENBETREUUNG_DAVON_BETREUUNGSTAGE_KINDER_ANDERER_GEMEINDEN'
                            | translate
                    "
                >
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="davonBetreuungstageKinderAndererGemeinden"
                        formControlName="davonBetreuungstageKinderAndererGemeinden"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls
                                .davonBetreuungstageKinderAndererGemeinden
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls
                                .davonBetreuungstageKinderAndererGemeinden
                                .errors?.isNotHalf
                        "
                        translate
                        >ERROR_NOT_HALF
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.davonBetreuungstageKinderAndererGemeinden
                        "
                        [korrektur]="
                            this.form.value
                                .davonBetreuungstageKinderAndererGemeinden
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.davonBetreuungstageKinderAndererGemeinden
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="davonBetreuungstageKinderAndererGemeindenSonderschueler_id"
                    class="padding-left-50"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls
                                .davonBetreuungstageKinderAndererGemeindenSonderschueler
                                .errors
                    }"
                    [innerHTML]="
                        'FERIENBETREUUNG_BETREUUNGSTAGE_SONDERSCHUELER'
                            | translate
                    "
                >
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="davonBetreuungstageKinderAndererGemeindenSonderschueler_id"
                        formControlName="davonBetreuungstageKinderAndererGemeindenSonderschueler"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls
                                .davonBetreuungstageKinderAndererGemeindenSonderschueler
                                .errors?.isNotHalf
                        "
                        translate
                        >ERROR_NOT_HALF
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.davonBetreuungstageKinderAndererGemeindenSonderschueler
                        "
                        [korrektur]="
                            this.form.value
                                .davonBetreuungstageKinderAndererGemeindenSonderschueler
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.davonBetreuungstageKinderAndererGemeindenSonderschueler
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>
        <div class="custom-errors">
            <div
                *ngIf="
                    form &&
                    this.form.controls
                        .davonBetreuungstageKinderAndererGemeindenSonderschueler
                        .errors?.sonderschuelerError
                "
                translate
            >
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span translate>ERROR_FB_SONDERSCHUELER</span>
            </div>
        </div>

        <mat-divider></mat-divider>

        <h3 translate class="uppercase">KINDER</h3>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="anzahlBetreuteKinder_id"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls.anzahlBetreuteKinder.errors
                    }"
                >
                    <span
                        [innerHTML]="
                            'FERIENBETREUUNG_ANZAHL_BETREUTE_KINDER' | translate
                        "
                    ></span>
                    <dv-tooltip
                        inputId="anzahlBetreuteKinder_id"
                        [text]="
                            'FERIENBETREUUNG_ANZAHL_BETREUTE_KINDER_HINT'
                                | translate
                        "
                    >
                    </dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahlBetreuteKinder_id"
                        formControlName="anzahlBetreuteKinder"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls.anzahlBetreuteKinder.errors
                                ?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.anzahlBetreuteKinder
                        "
                        [korrektur]="this.form.value.anzahlBetreuteKinder"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung?.anzahlBetreuteKinder
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="anzahl_betreute_kinder_sonderschueler_id"
                    class="padding-left-20"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls
                                .anzahlBetreuteKinderSonderschueler.errors
                    }"
                    [innerHTML]="
                        'FERIENBETREUUNG_ANZAHL_BETREUTE_KINDER_SONDERSCHUELER'
                            | translate
                    "
                >
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahl_betreute_kinder_sonderschueler_id"
                        formControlName="anzahlBetreuteKinderSonderschueler"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls
                                .anzahlBetreuteKinderSonderschueler.errors
                                ?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.anzahlBetreuteKinderSonderschueler
                        "
                        [korrektur]="
                            this.form.value.anzahlBetreuteKinderSonderschueler
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.anzahlBetreuteKinderSonderschueler
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="anzahlBetreuteKinder1Zyklus_id"
                    class="padding-left-20"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls.anzahlBetreuteKinder1Zyklus
                                .errors
                    }"
                >
                    <span
                        [innerHTML]="
                            'FERIENBETREUUNG_DAVON_KINDER_IM_1_ZYKLUS'
                                | translate
                        "
                    ></span>
                    <dv-tooltip
                        inputId="anzahlBetreuteKinder1Zyklus_id"
                        [text]="
                            'FERIENBETREUUNG_DAVON_KINDER_IM_ZYKLUS_HINT'
                                | translate
                        "
                    >
                    </dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahlBetreuteKinder1Zyklus_id"
                        formControlName="anzahlBetreuteKinder1Zyklus"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls.anzahlBetreuteKinder1Zyklus
                                .errors?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.anzahlBetreuteKinder1Zyklus
                        "
                        [korrektur]="
                            this.form.value.anzahlBetreuteKinder1Zyklus
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.anzahlBetreuteKinder1Zyklus
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="anzahlBetreuteKinder2Zyklus_id"
                    class="padding-left-20"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls.anzahlBetreuteKinder2Zyklus
                                .errors
                    }"
                >
                    <span
                        [innerHTML]="
                            'FERIENBETREUUNG_DAVON_KINDER_IM_2_ZYKLUS'
                                | translate
                        "
                    ></span>
                    <dv-tooltip
                        inputId="anzahlBetreuteKinder2Zyklus_id"
                        [text]="
                            'FERIENBETREUUNG_DAVON_KINDER_IM_ZYKLUS_HINT'
                                | translate
                        "
                    >
                    </dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahlBetreuteKinder2Zyklus_id"
                        formControlName="anzahlBetreuteKinder2Zyklus"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls.anzahlBetreuteKinder2Zyklus
                                .errors?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.anzahlBetreuteKinder2Zyklus
                        "
                        [korrektur]="
                            this.form.value.anzahlBetreuteKinder2Zyklus
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.anzahlBetreuteKinder2Zyklus
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="anzahlBetreuteKinder3Zyklus_id"
                    class="padding-left-20"
                    [ngClass]="{
                        'label-error':
                            form &&
                            this.form.controls.anzahlBetreuteKinder3Zyklus
                                .errors
                    }"
                >
                    <span
                        [innerHTML]="
                            'FERIENBETREUUNG_DAVON_KINDER_IM_3_ZYKLUS'
                                | translate
                        "
                    ></span>
                    <dv-tooltip
                        inputId="anzahlBetreuteKinder3Zyklus_id"
                        [text]="
                            'FERIENBETREUUNG_DAVON_KINDER_IM_ZYKLUS_HINT'
                                | translate
                        "
                    >
                    </dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahlBetreuteKinder3Zyklus_id"
                        formControlName="anzahlBetreuteKinder3Zyklus"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            this.form.controls.anzahlBetreuteKinder3Zyklus
                                .errors?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.nutzung
                                ?.anzahlBetreuteKinder3Zyklus
                        "
                        [korrektur]="
                            this.form.value.anzahlBetreuteKinder3Zyklus
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.nutzung
                        ?.anzahlBetreuteKinder3Zyklus
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div
            class="well well-status-warten"
            *ngIf="
                !(
                    (allAnzahlFieldsFilledOut() &&
                        abweichungenAnzahlKinder === 0) ||
                    allAnzahlFieldsEmpty()
                )
            "
        >
            <i class="fa fa-exclamation" aria-hidden="true"></i>
            <span>
                <span translate
                    >FERIENBETREUUNG_PLAUSIBILITAET_ANZAHL_BETREUTER_KINDER</span
                >
            </span>
        </div>
    </form>

    <div class="actions margin-top-50">
        <button
            type="button"
            class="dv-btn cancel-button"
            *ngIf="canSeeFalscheAngaben | async"
            (click)="onFalscheAngaben()"
            [textContent]="'FERIENBETREUUNG_ANGABEN_KORRIGIEREN' | translate"
        ></button>
        <button
            type="button"
            class="dv-btn cancel-button"
            *ngIf="canSeeSave | async"
            (click)="save()"
            [textContent]="'SAVE' | translate"
        ></button>
        <dv-loading-button
            *ngIf="canSeeAbschliessen | async"
            type="button"
            (buttonClick)="onAbschliessen()"
        >
            <span *ngIf="!container?.isInPruefungKanton()" translate
                >ABSCHLIESSEN</span
            >
            <span *ngIf="container?.isInPruefungKanton()" translate
                >GEPRUEFT</span
            >
        </dv-loading-button>
    </div>
</div>
