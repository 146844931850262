<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2018 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->
<kibon-guided-tour></kibon-guided-tour>
<nav
    *dvNgShowElement="true; roles: TSRoleUtil.getAllRolesButGesuchsteller()"
    class="dv-navbar-mat"
>
    <mat-toolbar class="dv-mat-toolbar" id="navbar">
        <div
            *dvNgShowElement="
                true;
                roles: TSRoleUtil.getAdministratorOrAmtOrSozialdienstRolle()
            "
            class="dv-ng-navbar-element uppercase"
            uiSrefActive="active"
        >
            <a
                [textContent]="'MENU_PENDENZEN' | translate"
                id="tour_menu_pendenzen"
                uiSref="pendenzen.list-view"
            ></a>
        </div>
        <div
            *dvNgShowElement="
                isTagesschulangebotEnabled();
                roles: TSRoleUtil.getSchulamtRoles()
            "
            class="dv-ng-navbar-element uppercase"
            uiSrefActive="active"
        >
            <a
                [textContent]="'MENU_PENDENZEN_ANMELDUNGEN_SCH' | translate"
                uiSref="pendenzenBetreuungen.list-view"
            ></a>
        </div>
        <div
            *dvNgShowElement="
                true;
                roles: TSRoleUtil.getTraegerschaftInstitutionRoles()
            "
            class="dv-ng-navbar-element uppercase"
            uiSrefActive="active"
        >
            <a
                [textContent]="'MENU_PENDENZEN_INSTITUTION' | translate"
                uiSref="pendenzenBetreuungen.list-view"
            ></a>
        </div>
        <div
            *dvNgShowElement="true; roles: TSRoleUtil.getSteueramtRoles()"
            class="dv-ng-navbar-element uppercase"
            uiSrefActive="active"
        >
            <a
                [textContent]="'MENU_PENDENZEN_STEUERAMT' | translate"
                uiSref="pendenzenSteueramt.list-view"
            ></a>
        </div>
        <div
            *dvNgShowElement="
                true;
                roles: TSRoleUtil.getAllRolesForMenuAlleFaelle()
            "
            class="dv-ng-navbar-element uppercase"
            uiSrefActive="active"
        >
            <a
                [textContent]="'MENU_ALL_FAELLE' | translate"
                uiSref="faelle.list"
            ></a>
        </div>
        <div class="dv-nav-break"></div>
        <div
            *dvNgShowElement="true; roles: TSRoleUtil.getAllRolesForZahlungen()"
            class="dv-ng-navbar-element uppercase"
            uiSrefActive="active"
        >
            <a
                [textContent]="'MENU_ZAHLUNG' | translate"
                uiSref="zahlungsauftrag.view"
            ></a>
        </div>
        <div
            *dvNgShowElement="true; roles: TSRoleUtil.getAllRolesForStatistik()"
            class="dv-ng-navbar-element uppercase"
            uiSrefActive="active"
        >
            <a
                [textContent]="'MENU_STATISTIK' | translate"
                uiSref="statistik.view"
            ></a>
        </div>
        <ng-container *ngIf="gemeindeAntraegeActive">
            <div
                *ngIf="gemeindeAntragVisible | async"
                class="dv-ng-navbar-element uppercase"
                uiSrefActive="active"
            >
                <a
                    [textContent]="'MENU_GEMEINDE_ANTRAEGE' | translate"
                    uiSref="gemeindeantrage.view"
                ></a>
            </div>
        </ng-container>

        <div class="dv-nav-break2"></div>
        <div
            *dvNgShowElement="
                true;
                roles: TSRoleUtil.getAllRolesForPosteingang()
            "
            class="dv-ng-navbar-element uppercase dv-ng-navbar-posteingang"
            uiSrefActive="active"
        >
            <dv-posteingang uiSref="posteingang.view"></dv-posteingang>
        </div>
        <div
            *dvNgShowElement="
                lastenausgleichActive;
                roles: TSRoleUtil.getAllRolesForLastenausgleich()
            "
            class="dv-ng-navbar-element uppercase"
            uiSrefActive="active"
        >
            <a
                [textContent]="'MENU_LASTENAUSGLEICH' | translate"
                uiSref="lastenausgleich.view"
            ></a>
        </div>

        <div
            class="dv-ng-navbar-element-fall-eroeffnen"
            id="navbar_falleroeffnen"
            uiSrefActive="active"
        >
            <button
                (click)="startNewFall()"
                *dvNgShowElement="
                    true;
                    roles: TSRoleUtil.getAmtOrSozialdienstRolle()
                "
                class="dv-btn dv-ng-navbar-button"
                data-test="fall-eroeffnen"
            >
                <i class="fa fa-plus-square"></i>
                <span [textContent]="'MENU_FALL_EROEFFNEN' | translate"></span>
            </button>

            <button
                *dvNgShowElement="true; roles: TSRoleUtil.getSuperAdminRoles()"
                [matMenuTriggerFor]="menu"
                class="dv-btn dv-ng-navbar-button"
                id="navbar_menufalleroeffnen"
                mat-button
            >
                <i class="fa fa-plus-square"></i>
            </button>
            <mat-menu
                #menu="matMenu"
                class="user-menu"
                tabindex="-1"
                xPosition="before"
            >
                <button
                    (click)="startNewFall()"
                    class="dv-btn dv-ng-navbar-button"
                    mat-menu-item
                >
                    <span
                        [textContent]="'MENU_FALL_EROEFFNEN' | translate"
                    ></span>
                </button>
                <button
                    (click)="createNewSozialdienstFall()"
                    class="dv-btn dv-ng-navbar-button"
                    mat-menu-item
                >
                    <span
                        [textContent]="
                            'MENU_SOZIALDIENST_FALL_EROEFFNEN' | translate
                        "
                    ></span>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar>
</nav>
