<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<dv-full-height-container>
    <form
        role="form"
        #form="ngForm"
        [ngClass]="{'ng-submitted': form.submitted}"
        (ngSubmit)="gemeindeEinladen()"
        novalidate
    >
        <div class="dv-content">
            <h1 class="access-for-all-title" translate>
                AFA_GEMEINDEVERWALTUNG
            </h1>
            <div class="view-title">
                <h2 translate>NEUE_GEMEINDE</h2>
                <hr class="header" />
            </div>

            <fieldset>
                <!-- Gemeindename | BFS-Nummer der Gemeinde -->
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label translate for="gemeinde_ID"
                                >GEMEINDE_AUSWAEHLEN</label
                            >
                            <select
                                [(ngModel)]="selectedUnregisteredGemeinde"
                                name="bfsGemeinde"
                                class="form-control"
                                data-test="gemeinde-selection"
                                id="bfsGemeinde"
                                [disabled]="gemeinde.besondereVolksschule"
                                [required]="true"
                                (change)="bfsGemeindeSelected()"
                            >
                                <option value=""></option>
                                <option
                                    *ngFor="
                                        let bfsGemeinde of unregisteredGemeinden$
                                            | async
                                    "
                                    [ngValue]="bfsGemeinde"
                                >
                                    {{ bfsGemeinde.name }}
                                </option>
                            </select>
                            <dv-error-messages
                                [errorObject]="
                                    form.controls.bfsGemeinde?.errors
                                "
                                inputId="bfsGemeinde"
                                class="input-error"
                            >
                            </dv-error-messages>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label translate for="gemeinde_ID"
                                >GEMEINDE_BFS_NR</label
                            >
                            <input
                                id="bfs_nummer_ID"
                                type="number"
                                name="bfs_nummer"
                                #name="ngModel"
                                [(ngModel)]="gemeinde.bfsNummer"
                                [min]="1"
                                [max]="maxBFSNummer"
                                [maxlength]="4"
                                class="form-control"
                                disabled
                            />
                            <dv-error-messages
                                [errorObject]="form.controls.bfs_nummer?.errors"
                                inputId="bfs_nummer_ID"
                            >
                            </dv-error-messages>
                        </div>
                    </div>
                </div>

                <!-- Betreuungsgutscheine anbieten ab | Administrator - E-Mail Adresse -->
                <div class="row">
                    <!-- Konfiguration der Angebote -->
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label
                                class="md-no-float"
                                translate
                                for="begu_start_ID"
                                *ngIf="tageschuleEnabledForMandant"
                                >GEMEINDE_ANGEBOT</label
                            >

                            <mat-checkbox
                                id="angebotBG"
                                name="angebotBG"
                                [(ngModel)]="gemeinde.angebotBG"
                                *ngIf="tageschuleEnabledForMandant"
                            >
                                {{
                                    'GEMEINDE_ANGEBOT_BETREUUNGSGUTSCHEINE'
                                        | translate
                                }}</mat-checkbox
                            >

                            <div
                                class="form-group {{
                                    getCssClassAngebotBGInput()
                                }}"
                                *ngIf="gemeinde.angebotBG"
                            >
                                <div class="form-group">
                                    <label
                                        class="md-no-float"
                                        translate
                                        for="begu_start_ID"
                                        >BEGU_START</label
                                    >
                                    <input
                                        class="dv-mat-datepicker"
                                        type="text"
                                        name="begu_start"
                                        id="begu_start_ID"
                                        [(ngModel)]="
                                            gemeinde.betreuungsgutscheineStartdatum
                                        "
                                        [matDatepicker]="begu_start"
                                        placeholder="{{
                                            'DATE_PLACEHOLDER_FIRSTOFMONTH'
                                                | translate
                                        }}"
                                        required
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="begu_start"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker
                                        #begu_start
                                    ></mat-datepicker>
                                    <dv-error-messages
                                        input-id="begu_start_ID"
                                        [errorObject]="
                                            form.controls.begu_start?.errors
                                        "
                                    >
                                    </dv-error-messages>
                                </div>
                            </div>

                            <mat-checkbox
                                id="angebotTS"
                                name="angebotTS"
                                [(ngModel)]="gemeinde.angebotTS"
                                (ngModelChange)="handleAngebotTSChange()"
                                *ngIf="tageschuleEnabledForMandant"
                            >
                                {{
                                    'GEMEINDE_ANGEBOT_TAGESSCHULE' | translate
                                }}</mat-checkbox
                            >

                            <div
                                *ngIf="gemeinde.angebotTS"
                                class="inner-option"
                            >
                                <div class="form-group">
                                    <label
                                        class="md-no-float"
                                        translate
                                        for="ts_start_ID"
                                        >TS_START</label
                                    >
                                    <input
                                        class="dv-mat-datepicker"
                                        type="text"
                                        name="ts_start"
                                        id="ts_start_ID"
                                        [(ngModel)]="
                                            gemeinde.tagesschulanmeldungenStartdatum
                                        "
                                        [matDatepicker]="ts_start"
                                        [min]="minDateTSFI"
                                        placeholder="{{
                                            'DATE_PLACEHOLDER_FIRSTOFMONTH'
                                                | translate
                                        }}"
                                        required
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="ts_start"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #ts_start></mat-datepicker>
                                    <dv-error-messages
                                        input-id="ts_start_ID"
                                        [errorObject]="
                                            form.controls.ts_start?.errors
                                        "
                                    >
                                    </dv-error-messages>
                                </div>

                                <mat-checkbox
                                    id="besondereVolksschule"
                                    name="besondereVolksschule"
                                    [(ngModel)]="gemeinde.besondereVolksschule"
                                    (ngModelChange)="
                                        handleIsBesondereVolksschuleChange(
                                            $event
                                        )
                                    "
                                >
                                    {{
                                        'GEMEINDE_ANGEBOT_BESONDERE_VOLKSSCHULE'
                                            | translate
                                    }}</mat-checkbox
                                >

                                <div
                                    class="form-group margin-top-10"
                                    *ngIf="gemeinde.besondereVolksschule"
                                >
                                    <label
                                        class="md-no-float"
                                        translate
                                        for="besondere_volksschule_name"
                                        >NAME</label
                                    >
                                    <input
                                        type="text"
                                        name="besondere_volksschule_name"
                                        id="besondere_volksschule_name"
                                        [(ngModel)]="gemeinde.name"
                                        required
                                    />
                                    <dv-error-messages
                                        input-id="besondere_volksschule_name"
                                        [errorObject]="
                                            form.controls
                                                .besondere_volksschule_name
                                                ?.errors
                                        "
                                    >
                                    </dv-error-messages>
                                </div>

                                <mat-checkbox
                                    id="nurLats"
                                    name="nurLats"
                                    [(ngModel)]="gemeinde.nurLats"
                                >
                                    {{
                                        'GEMEINDE_ANGEBOT_NUR_LATS' | translate
                                    }}</mat-checkbox
                                >
                            </div>

                            <mat-checkbox
                                id="angebotFI"
                                name="angebotFI"
                                [(ngModel)]="gemeinde.angebotFI"
                                *ngIf="tageschuleEnabledForMandant"
                            >
                                {{
                                    'GEMEINDE_ANGEBOT_FERIENINSEL' | translate
                                }}</mat-checkbox
                            >

                            <div
                                class="form-group inner-option"
                                *ngIf="gemeinde.angebotFI"
                            >
                                <label
                                    class="md-no-float"
                                    translate
                                    for="fi_start_ID"
                                    >FI_START</label
                                >
                                <input
                                    class="dv-mat-datepicker"
                                    type="text"
                                    name="fi_start"
                                    id="fi_start_ID"
                                    [(ngModel)]="
                                        gemeinde.ferieninselanmeldungenStartdatum
                                    "
                                    [matDatepicker]="fi_start"
                                    [min]="minDateTSFI"
                                    placeholder="{{
                                        'DATE_PLACEHOLDER_FIRSTOFMONTH'
                                            | translate
                                    }}"
                                    required
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="fi_start"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #fi_start></mat-datepicker>
                                <dv-error-messages
                                    input-id="fi_start_ID"
                                    [errorObject]="
                                        form.controls.fi_start?.errors
                                    "
                                >
                                </dv-error-messages>
                            </div>

                            <div
                                class="dv-error-messages"
                                *ngIf="showMessageKeinAngebotSelected"
                            >
                                <span>{{
                                    'GEMEINDE_ANGEBOT_EMPTY_SELECTION'
                                        | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label translate for="admin_mail_ID"
                                >ADMIN_MAIL</label
                            >
                            <input
                                id="admin_mail_ID"
                                type="email"
                                name="admin_mail"
                                [(ngModel)]="adminMail"
                                email
                                data-test="admin-mail"
                                [maxlength]="255"
                                class="form-control"
                                required
                            />
                            <dv-error-messages
                                [errorObject]="form.controls.admin_mail?.errors"
                                input-id="admin_mail_ID"
                            >
                            </dv-error-messages>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- Buttons -->
        <div class="dv-footer">
            <dv-loading-button
                [type]="'submit'"
                data-test="container.einladung-senden"
            >
                <span translate>EINLADUNG_SENDEN</span>
            </dv-loading-button>

            <button
                type="button"
                class="dv-btn cancel-button"
                (click)="cancel()"
                [textContent]="'CANCEL' | translate"
            ></button>
        </div>
    </form>
</dv-full-height-container>
