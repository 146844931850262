<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->
<div class="gesuch-modul">
    <h1 class="access-for-all-title" translate>AFA_GESUCHSFORMULAR</h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                <span translate>FINANZIELLE_VERHAELTNISSE_BERECHNUNG</span>
            </h2>
            <hr class="header" />
        </div>
    </div>
    <div
        class="well well-status-warten"
        *ngIf="showWarningAngabenVervollstaendigen()"
    >
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span translate>APPENZELL_FIN_SIT_FORMULAR_VERVOLLSTAENDIGEN</span>
    </div>
    <div
        class="row margin-bottom-50"
        *ngIf="!showWarningAngabenVervollstaendigen()"
    >
        <div class="col-md-12">
            <p class="inline-hint">
                {{ 'APPENZELL_FIN_SIT_EINLEITUNG' | translate }}
            </p>
        </div>
    </div>
    <div *ngIf="showAuszahlungAusserhalbKibonCheckbox()">
        <dv-checkbox-x
            [model]="model.familienSituation.auszahlungAusserhalbVonKibon"
            (modelChange)="auszahlungAusserhalbKibonChanged($event)"
            [label]="'AUSZAHLUNG_AUSSERHALB_KIBON' | translate"
            [readonly]="isGesuchReadonly()"
            [displayBisher]="false"
        ></dv-checkbox-x>
    </div>
    <div *ngIf="showAuszahlungsdaten()">
        <form>
            <dv-auszahlungsdaten
                [auszahlungsdatenGS]="model.zahlungsinformationen"
                [(auszahlungsdaten)]="model.zahlungsinformationen"
            ></dv-auszahlungsdaten>
        </form>
    </div>
    <div class="row" *ngIf="!showWarningAngabenVervollstaendigen()">
        <div class="col-md-12">
            <div class="form-group">
                <form role="form" #form="ngForm" novalidate>
                    <div
                        class="row"
                        *ngIf="showQuestionGemeinsameSteuererklaerung()"
                    >
                        <div class="col-xs-12">
                            <dv-steuerveranlagung-gemeinsam
                                [model]="model"
                                (gemeinsamChanged)="
                                    this.gemeinsamChanged($event)
                                "
                            ></dv-steuerveranlagung-gemeinsam>
                        </div>
                    </div>

                    <h2>{{ getFinSitTitle() }}</h2>

                    <mat-divider class="margin-top-20"></mat-divider>

                    <dv-fin-sit-felder-appenzell
                        [readOnly]="isGesuchReadonly()"
                        [finanzModel]="model"
                        [antragstellerNumber]="getAntragstellerNumber()"
                        [finSitZusatzangabenAppenzell]="
                            getFinSitZusatzangabenAppenzellToWorkWith()
                        "
                        [deklaration]="
                            getFinSitZusatzangabenAppenzellGSToWorkWith()
                        "
                        [showBisher]="
                            showBisher(getModel().finanzielleSituationJA)
                        "
                        (valueChanges)="calculateResults()"
                    ></dv-fin-sit-felder-appenzell>

                    <mat-divider class="margin-bottom-20"></mat-divider>

                    <dv-massgebendes-einkommen
                        [massgebendesEinkommen$]="getMassgebendesEinkommen$()"
                        [isGemeinsam]="isGemeinsam()"
                        [antragstellerNummer]="getAntragstellerNumber()"
                        [nameGS1]="extractFullNameGS1()"
                        [nameGS2]="extractFullNameGS2()"
                    ></dv-massgebendes-einkommen>
                    <dv-navigation-x
                        (dvSave)="prepareSave($event.onResult)"
                        [dvNext]="true"
                        [dvPrevious]="true"
                        [dvSubStep]="getSubStepIndex()"
                        [dvSubStepName]="getSubStepName()"
                        [dvNextDisabled]="showWarningAngabenVervollstaendigen()"
                    ></dv-navigation-x>
                </form>
            </div>
        </div>
    </div>
</div>
