<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<dv-full-height-container>
    <div class="dv-content">
        <div class="view-title margin-top-10">
            <h2 translate>DEBUGGING_SENTRY</h2>
        </div>

        <div class="row sentrytest margin-top-10">
            <div class="col-md-12">
                <button
                    onclick="throw 'Deliberate Error! inlined throw';"
                    class="dv-btn"
                >
                    inline js error
                </button>
                <button (click)="doUndefined()" class="dv-btn">
                    undefined access
                </button>
                <button (click)="doThrowError()" class="dv-btn">
                    controll-ererror
                </button>
                <button (click)="doShowDialog()" class="dv-btn">
                    show Sentry Dialog
                </button>
            </div>
        </div>

        <div class="view-title margin-top-10">
            <h2 translate>DEBUGGING_UI_ROUTER</h2>
        </div>

        <form #traceForm="ngForm">
            <div class="row">
                <div class="col-md-3">
                    <label for="routerTraceCategories">
                        {{ 'DEBUGGING_TRACE_TRANSITIONS' | translate }}
                    </label>
                    <select
                        [(ngModel)]="routerTraceCategories"
                        multiple
                        [size]="TRACE_CATEGORY_KEYS.length"
                        name="routerTraceCategories"
                        class="form-control"
                        id="routerTraceCategories"
                    >
                        <option
                            *ngFor="let key of TRACE_CATEGORY_KEYS"
                            [ngValue]="TRACE_CATEGORY[key]"
                        >
                            {{ key }}
                            <ng-container
                                *ngIf="
                                    routerTraceCategories.includes(
                                        findCategory(key)
                                    )
                                "
                                >{{ '&#xf00c;' }}
                            </ng-container>
                        </option>
                    </select>
                </div>

                <div class="col-md-3 dv-input-container-question">
                    <button
                        (click)="onActivateVisualizer()"
                        class="dv-btn"
                        translate
                        [disabled]="hasVisualizer"
                    >
                        DEBUGGING_UI_ROUTER_VISUALIZER
                    </button>
                </div>
            </div>
        </form>

        <div class="view-title margin-top-40">
            <h2>Neue BG-Berechung für bereits verfügte Gesuche simulieren</h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p>
                    Hier kann für bereits Gesuche eine Neuberechnung der
                    Betreuungsgutscheine simuliert werden. Falls es Abweichungen
                    zwischen den Betreuungsgutscheinen des verfügten Gesuchs und
                    der Simulation gibt, werden die unten angezeigt (falls das
                    Resultat identisch ist, wird nichts angezeigt).
                    <br />Mehrere GesuchIds auf unterschiedlichen Zeilen
                    eingeben
                </p>
            </div>
        </div>
        <div>
            <div class="row margin-top-10">
                <div class="col-md-6">
                    <textarea
                        [(ngModel)]="gesuchIdsStr"
                        class="form-control"
                        id="gesuchIds"
                    >
                    </textarea>
                </div>
                <div class="col-md-3">
                    <button (click)="startSimulation()" class="dv-btn">
                        Simulation starten
                    </button>
                </div>
            </div>
            <div class="row margin-top-10" *ngIf="simulationsFinished > 0">
                <div class="col-md-12">
                    <p>
                        Simulation {{ simulationsFinished }} von
                        {{ gesuchIds.length }} fertig
                    </p>
                </div>
                <div class="col-md-12" id="simulationResult">
                    <p>{{ simulationResult }}</p>
                </div>
            </div>
        </div>
    </div>
</dv-full-height-container>
