<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->
<form
    role="form"
    #form="ngForm"
    [ngClass]="{'ng-submitted': form.submitted}"
    (ngSubmit)="save()"
    novalidate
>
    <mat-dialog-content
        [ngClass]="{
            'modul-tagesschule-dialog': isModulErfassungDynamisch(),
            'modul-tagesschule-dialog-scolaris': !isModulErfassungDynamisch()
        }"
    >
        <div *ngIf="modulTagesschuleGroup">
            <div class="col-md-12 popup-error-message">
                <div class="dv-error-messages" *ngIf="noDaySelected">
                    <div class="error">
                        <li translate>ERROR_NO_MODUL_SELECTED</li>
                        <br />
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="!isModulErfassungDynamisch()">
                <div class="form-group">
                    <label translate>MODUL_NAME</label>
                    {{ modulTagesschuleGroup.modulTagesschuleName }}
                </div>
            </div>
            <div class="dv-content">
                <div class="col-md-3">
                    <div class="form-group">
                        <label translate for="bezeichnungDe" class="required"
                            >MODUL_BEZEICHNUNG_DE</label
                        >
                        <input
                            id="bezeichnungDe"
                            type="text"
                            name="bezeichnungDe"
                            #bezeichnungDe="ngModel"
                            [(ngModel)]="
                                modulTagesschuleGroup.bezeichnung.textDeutsch
                            "
                            class="form-control"
                            data-test="institution.tageschule.modul.bezeichnungDe"
                            required
                        />
                        <dv-error-messages
                            [errorObject]="form.controls.bezeichnungDe?.errors"
                            inputId="bezeichnungDe"
                        ></dv-error-messages>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label translate for="bezeichnungFr" class="required"
                            >MODUL_BEZEICHNUNG_FR</label
                        >
                        <input
                            id="bezeichnungFr"
                            type="text"
                            name="bezeichnungFr"
                            #bezeichnungFr="ngModel"
                            [(ngModel)]="
                                modulTagesschuleGroup.bezeichnung
                                    .textFranzoesisch
                            "
                            class="form-control"
                            data-test="institution.tageschule.modul.bezeichnungFr"
                            required
                        />
                        <dv-error-messages
                            [errorObject]="form.controls.bezeichnungFr?.errors"
                            inputId="bezeichnungFr"
                        ></dv-error-messages>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label translate for="zeitVon" class="required"
                            >MODUL_VON</label
                        >
                        <input
                            id="zeitVon"
                            type="text"
                            name="zeitVon"
                            #zeitVon="ngModel"
                            [(ngModel)]="modulTagesschuleGroup.zeitVon"
                            class="form-control"
                            data-test="institution.tageschule.modul.zeitVon"
                            required
                            placeholder="HH:mm"
                            [pattern]="CONSTANTS.PATTERN_HHHMM"
                        />
                        <dv-error-messages
                            [errorObject]="form.controls.zeitVon?.errors"
                            inputId="zeitVon"
                        ></dv-error-messages>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label translate for="zeitBis" class="required"
                            >MODUL_BIS</label
                        >
                        <input
                            id="zeitBis"
                            type="text"
                            name="zeitBis"
                            #zeitBis="ngModel"
                            [(ngModel)]="modulTagesschuleGroup.zeitBis"
                            class="form-control"
                            data-test="institution.tageschule.modul.zeitBis"
                            required
                            placeholder="HH:mm"
                            [pattern]="CONSTANTS.PATTERN_HHHMM"
                        />
                        <dv-error-messages
                            [errorObject]="form.controls.zeitBis?.errors"
                            inputId="zeitBis"
                        ></dv-error-messages>
                    </div>
                </div>
            </div>

            <div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label translate for="verpflegungskosten"
                            >MODUL_VERPFLEGUNG</label
                        >
                        <input
                            id="verpflegungskosten"
                            type="text"
                            name="verpflegungskosten"
                            #verpflegungskosten="ngModel"
                            [(ngModel)]="
                                modulTagesschuleGroup.verpflegungskosten
                            "
                            placeholder="{{ 'IN_CHF_PLACEHOLDER' | translate }}"
                            pattern="^[0-9]{1,}(\.|)[0-9]{0,2}$"
                            data-test="institution.tageschule.modul.verpflegungskosten"
                        />
                        <dv-error-messages
                            [errorObject]="verpflegungskosten.errors"
                            inputId="verpflegungskosten"
                        ></dv-error-messages>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-group" id="verfuegbar">
                            <div class="row">
                                <div class="col-xs-1">
                                    <label for="montag" translate
                                        >MONDAY_SHORT</label
                                    >
                                </div>
                                <div class="col-xs-1">
                                    <label for="dienstag" translate
                                        >TUESDAY_SHORT</label
                                    >
                                </div>
                                <div class="col-xs-1">
                                    <label for="mittwoch" translate
                                        >WEDNESDAY_SHORT</label
                                    >
                                </div>
                                <div class="col-xs-1">
                                    <label for="donnerstag" translate
                                        >THURSDAY_SHORT</label
                                    >
                                </div>
                                <div class="col-xs-1">
                                    <label for="freitag" translate
                                        >FRIDAY_SHORT</label
                                    >
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-1">
                                    <mat-checkbox
                                        id="montag"
                                        name="montag"
                                        [(ngModel)]="
                                            modulTagesschuleGroup
                                                .tempModulMonday.angeboten
                                        "
                                        data-test="institution.tageschule.modul.montag"
                                    ></mat-checkbox>
                                </div>
                                <div class="col-xs-1">
                                    <mat-checkbox
                                        id="dienstag"
                                        name="dienstag"
                                        [(ngModel)]="
                                            modulTagesschuleGroup
                                                .tempModulTuesday.angeboten
                                        "
                                        data-test="institution.tageschule.modul.dienstag"
                                    ></mat-checkbox>
                                </div>
                                <div class="col-xs-1">
                                    <mat-checkbox
                                        id="mittwoch"
                                        name="mittwoch"
                                        [(ngModel)]="
                                            modulTagesschuleGroup
                                                .tempModulWednesday.angeboten
                                        "
                                        data-test="institution.tageschule.modul.mittwoch"
                                    ></mat-checkbox>
                                </div>
                                <div class="col-xs-1">
                                    <mat-checkbox
                                        id="donnerstag"
                                        name="donnerstag"
                                        [(ngModel)]="
                                            modulTagesschuleGroup
                                                .tempModulThursday.angeboten
                                        "
                                        data-test="institution.tageschule.modul.donnerstag"
                                    ></mat-checkbox>
                                </div>
                                <div class="col-xs-1">
                                    <mat-checkbox
                                        id="freitag"
                                        name="freitag"
                                        [(ngModel)]="
                                            modulTagesschuleGroup
                                                .tempModulFriday.angeboten
                                        "
                                        data-test="institution.tageschule.modul.freitag"
                                    ></mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="row">&nbsp;</div>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-checkbox
                                    id="wirdPaedagogischBetreut"
                                    name="wirdPaedagogischBetreut"
                                    [(ngModel)]="
                                        modulTagesschuleGroup.wirdPaedagogischBetreut
                                    "
                                    data-test="institution.tageschule.modul.wirdPaedagogischBetreut"
                                >
                                    {{
                                        'MODUL_PAEDAGOGISCH_BETREUT' | translate
                                    }}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label translate for="intervall" class="required"
                            >MODUL_INTERVALL</label
                        >
                        <mat-select
                            [(ngModel)]="modulTagesschuleGroup.intervall"
                            #intervall="ngModel"
                            id="intervall"
                            name="intervall"
                            class="form-control"
                            data-test="institution.tageschule.modul.intervall"
                            required
                        >
                            <mat-option
                                *ngFor="
                                    let option of getModulTagesschuleIntervallOptions()
                                "
                                [value]="option"
                                [attr.data-test]="
                                    'institution.tageschule.modul.intervall.' +
                                    option
                                "
                                >{{ option | translate }}
                            </mat-option>
                        </mat-select>
                        <dv-error-messages
                            [errorObject]="form.controls.intervall?.errors"
                            inputId="intervall"
                        ></dv-error-messages>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="dv-dialog-actions">
            <button
                type="submit"
                class="dv-btn"
                [textContent]="'OK' | translate"
                data-test="institution.tageschule.modul.ok"
            ></button>
            <button
                type="button"
                class="dv-btn cancel-button"
                (click)="close()"
                [textContent]="'CANCEL' | translate"
            ></button>
        </div>
    </mat-dialog-actions>
</form>
