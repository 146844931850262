<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

@if (stammdaten) {
    <div class="dv-content">
        @if (editMode) {
            <fieldset ngModelGroup="dv-edit-gemeinde-institution">
                <legend></legend>
                <div class="row">
                    <div class="col-sm-12">
                        <h3 translate>WELCHE_INSTITUTIONEN_ZUGELASSEN</h3>
                    </div>
                    @if (showInfomaFields()) {
                        <dv-edit-gemeinde-institution-table
                            [editMode]="editMode"
                            [displayedColumns]="displayedColumnsInfoma"
                            [dataSource]="dataSource"
                            [stammdaten]="stammdaten"
                        ></dv-edit-gemeinde-institution-table>
                    } @else {
                        <dv-edit-gemeinde-institution-table
                            [editMode]="editMode"
                            [displayedColumns]="displayedColumns"
                            [dataSource]="dataSource"
                            [stammdaten]="stammdaten"
                        ></dv-edit-gemeinde-institution-table>
                    }
                </div>
            </fieldset>
        } @else {
            <fieldset>
                <legend></legend>
                <div class="row">
                    <div class="col-sm-12">
                        <h3 translate>WELCHE_INSTITUTIONEN_ZUGELASSEN</h3>
                    </div>
                    @if (showInfomaFields()) {
                        <dv-edit-gemeinde-institution-table
                            [editMode]="editMode"
                            [displayedColumns]="displayedColumnsInfoma"
                            [dataSource]="dataSource"
                            [stammdaten]="stammdaten"
                        ></dv-edit-gemeinde-institution-table>
                    } @else {
                        <dv-edit-gemeinde-institution-table
                            [editMode]="editMode"
                            [displayedColumns]="displayedColumns"
                            [dataSource]="dataSource"
                            [stammdaten]="stammdaten"
                        ></dv-edit-gemeinde-institution-table>
                    }
                </div>
            </fieldset>
        }
    </div>
}
