<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div [ngClass]="containerClass">
    <dv-loading-button
        input-id="buttonSave"
        data-test="container.navigation-save"
        class="dv-navigation-item"
        *ngIf="dvNext"
        (buttonClick)="nextStep()"
        [buttonClass]="'next uppercase'"
        type="submit"
        [buttonDisabled]="isNextButtonDisabled()"
        title="{{ getTooltip() }}"
        [ariaLabel]="getNextButtonName()"
    >
        <div *ngIf="!doesdvTranslateNextExist()">
            <span
                [textContent]="getNextButtonName()"
                *dvIfViewportSize="'screen'"
            ></span>
            <span
                class="fa fa-angle-right"
                style="font-size: 2rem; height: unset; line-height: 4rem"
                *dvIfViewportSize="'mobile'"
            ></span>
        </div>
        <span
            *ngIf="doesdvTranslateNextExist()"
            [textContent]="getNextButtonName()"
        ></span>
    </dv-loading-button>
    <dv-loading-button
        input-id="buttonPrevious"
        data-test="container.navigation-previous"
        class="dv-navigation-item"
        *ngIf="dvPrevious"
        (buttonClick)="previousStep()"
        [buttonClass]="'previous uppercase'"
        type="submit"
        [ariaLabel]="getPreviousButtonName()"
    >
        <span
            [textContent]="getPreviousButtonName()"
            *dvIfViewportSize="'screen'"
        ></span>
        <span
            class="fa fa-angle-left"
            style="font-size: 2rem; height: unset; line-height: 4rem"
            *dvIfViewportSize="'mobile'"
        ></span>
    </dv-loading-button>
    <dv-loading-button
        input-id="buttonCancel"
        data-test="container.navigation-cancel"
        class="dv-navigation-item"
        *ngIf="doesCancelExist()"
        (buttonClick)="cancel()"
        [buttonClass]="'cancel-button uppercase'"
        type="reset"
        [ariaLabel]="'CANCEL' | translate"
    >
        <span translate>ABBRECHEN</span>
    </dv-loading-button>
</div>
