/*
 * Copyright (C) 2018 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {TSInstitutionStatus} from '@kibon/shared/model/enums';
import {TSAbstractMutableEntity} from './TSAbstractMutableEntity';
import {TSMandant} from './TSMandant';
import {TSTraegerschaft} from './TSTraegerschaft';

export class TSInstitution extends TSAbstractMutableEntity {
    public name: string;
    public traegerschaft: TSTraegerschaft;
    public mandant: TSMandant;
    public status: TSInstitutionStatus;
    public stammdatenCheckRequired: boolean = false;

    public constructor(
        name?: string,
        tragerschaft?: TSTraegerschaft,
        mandant?: TSMandant,
        status?: TSInstitutionStatus
    ) {
        super();
        this.name = name;
        this.traegerschaft = tragerschaft;
        this.mandant = mandant;
        this.status = status;
        this.stammdatenCheckRequired = false;
    }
}
