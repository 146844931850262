<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2018 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->

<div class="local-login row">
    <h3 class="col-md-5 col-md-offset-3">
        Wählen Sie bitte eine Benutzerin oder einen Benutzer aus.
    </h3>

    <div class="col-md-12 col-md-offset-1">
        <table class="ebegu-table">
            <thead>
                <tr>
                    <th colspan="3">
                        <h2 class="local-login-h2">Allgemeine Rollen</h2>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr [hidden]="!devMode">
                    <td>Superadmin</td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                superadmin?.vorname +
                                '-' +
                                superadmin?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                superadmin?.vorname +
                                ' ' +
                                superadmin?.nachname
                            "
                            (click)="logIn(superadmin)"
                        ></div>
                    </td>
                </tr>
                <!-- Mandant Kanton Bern -->
                <tr>
                    <td>
                        Administrator/in {{ localLoginDaten?.mandant.name }}
                    </td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorKantonBern?.vorname +
                                '-' +
                                administratorKantonBern?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorKantonBern?.vorname +
                                ' ' +
                                administratorKantonBern?.nachname
                            "
                            (click)="logIn(administratorKantonBern)"
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        Sachbearbeiter/in {{ localLoginDaten?.mandant.name }}
                    </td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterKantonBern?.vorname +
                                '-' +
                                sachbearbeiterKantonBern?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterKantonBern?.vorname +
                                ' ' +
                                sachbearbeiterKantonBern?.nachname
                            "
                            (click)="logIn(sachbearbeiterKantonBern)"
                        ></div>
                    </td>
                </tr>
                <!-- Institutionen / Traegerschaften -->
                <tr>
                    <td>Administrator/in Institution: Kita Brünnen</td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorInstitutionKitaBruennen?.vorname +
                                '-' +
                                administratorInstitutionKitaBruennen?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorInstitutionKitaBruennen?.vorname +
                                ' ' +
                                administratorInstitutionKitaBruennen?.nachname
                            "
                            (click)="
                                logIn(administratorInstitutionKitaBruennen)
                            "
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>Sachbearbeiter/in Institution: Kita Brünnen</td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterInstitutionKitaBruennen?.vorname +
                                '-' +
                                sachbearbeiterInstitutionKitaBruennen?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterInstitutionKitaBruennen?.vorname +
                                ' ' +
                                sachbearbeiterInstitutionKitaBruennen?.nachname
                            "
                            (click)="
                                logIn(sachbearbeiterInstitutionKitaBruennen)
                            "
                        ></div>
                    </td>
                </tr>
                <tr *ngIf="defaultGemeinde?.angebotTS">
                    <td>
                        Administrator/in Tagesschule:
                        {{ localLoginDaten?.tagesschule.name }}
                    </td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorInstitutionTagesschuleParis?.vorname +
                                '-' +
                                administratorInstitutionTagesschuleParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorInstitutionTagesschuleParis?.vorname +
                                ' ' +
                                administratorInstitutionTagesschuleParis?.nachname
                            "
                            (click)="
                                logIn(administratorInstitutionTagesschuleParis)
                            "
                        ></div>
                    </td>
                </tr>
                <tr *ngIf="defaultGemeinde?.angebotTS">
                    <td>
                        Sachbearbeiter/in Tagesschule:
                        {{ localLoginDaten?.tagesschule.name }}
                    </td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterInstitutionTagesschuleParis?.vorname +
                                '-' +
                                sachbearbeiterInstitutionTagesschuleParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterInstitutionTagesschuleParis?.vorname +
                                ' ' +
                                sachbearbeiterInstitutionTagesschuleParis?.nachname
                            "
                            (click)="
                                logIn(sachbearbeiterInstitutionTagesschuleParis)
                            "
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        Administrator/in Trägerschaft:
                        {{ localLoginDaten?.traegerschaft.name }}
                    </td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorTraegerschaftStadtBern?.vorname +
                                '-' +
                                administratorTraegerschaftStadtBern?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorTraegerschaftStadtBern?.vorname +
                                ' ' +
                                administratorTraegerschaftStadtBern?.nachname
                            "
                            (click)="logIn(administratorTraegerschaftStadtBern)"
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        Sachbearbeiter/in Trägerschaft:
                        {{ localLoginDaten?.traegerschaft.name }}
                    </td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterTraegerschaftStadtBern?.vorname +
                                '-' +
                                sachbearbeiterTraegerschaftStadtBern?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterTraegerschaftStadtBern?.vorname +
                                ' ' +
                                sachbearbeiterTraegerschaftStadtBern?.nachname
                            "
                            (click)="
                                logIn(sachbearbeiterTraegerschaftStadtBern)
                            "
                        ></div>
                    </td>
                </tr>
                <!-- Sozialdienst -->
                <tr *ngIf="localLoginDaten?.sozialdienst">
                    <td>
                        Administrator Unterstützungsdienst:
                        {{ localLoginDaten.sozialdienst.name }}
                    </td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorSozialdienst?.vorname +
                                '-' +
                                administratorSozialdienst?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorSozialdienst?.vorname +
                                ' ' +
                                administratorSozialdienst?.nachname
                            "
                            (click)="logIn(administratorSozialdienst)"
                        ></div>
                    </td>
                </tr>
                <tr *ngIf="localLoginDaten?.sozialdienst">
                    <td>
                        Sachbearbeiter Unterstützungsdienst:
                        {{ localLoginDaten.sozialdienst.name }}
                    </td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterSozialdienst?.vorname +
                                '-' +
                                sachbearbeiterSozialdienst?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterSozialdienst?.vorname +
                                ' ' +
                                sachbearbeiterSozialdienst?.nachname
                            "
                            (click)="logIn(sachbearbeiterSozialdienst)"
                        ></div>
                    </td>
                </tr>

                <!-- Gesuchsteller -->
                <tr>
                    <td>Gesuchsteller/in</td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                gesuchstellerEmmaGerber?.vorname +
                                '-' +
                                gesuchstellerEmmaGerber?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                gesuchstellerEmmaGerber?.vorname +
                                ' ' +
                                gesuchstellerEmmaGerber?.nachname
                            "
                            (click)="logIn(gesuchstellerEmmaGerber)"
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>Gesuchsteller/in</td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                gesuchstellerHeinrichMueller?.vorname +
                                '-' +
                                gesuchstellerHeinrichMueller?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                gesuchstellerHeinrichMueller?.vorname +
                                ' ' +
                                gesuchstellerHeinrichMueller?.nachname
                            "
                            (click)="logIn(gesuchstellerHeinrichMueller)"
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>Gesuchsteller/in</td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                gesuchstellerMichaelBerger?.vorname +
                                '-' +
                                gesuchstellerMichaelBerger?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                gesuchstellerMichaelBerger?.vorname +
                                ' ' +
                                gesuchstellerMichaelBerger?.nachname
                            "
                            (click)="logIn(gesuchstellerMichaelBerger)"
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>Gesuchsteller/in</td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                gesuchstellerHansZimmermann?.vorname +
                                '-' +
                                gesuchstellerHansZimmermann?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                gesuchstellerHansZimmermann?.vorname +
                                ' ' +
                                gesuchstellerHansZimmermann?.nachname
                            "
                            (click)="logIn(gesuchstellerHansZimmermann)"
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>Gesuchsteller/in</td>
                    <td></td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                gesuchstellerJeanChambre?.vorname +
                                '-' +
                                gesuchstellerJeanChambre?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                gesuchstellerJeanChambre?.vorname +
                                ' ' +
                                gesuchstellerJeanChambre?.nachname
                            "
                            (click)="logIn(gesuchstellerJeanChambre)"
                        ></div>
                    </td>
                </tr>

                <th><h2 class="local-login-h2">Gemeinde:</h2></th>
                <th>
                    <h2 class="local-login-h2">
                        {{ localLoginDaten?.default_gemeinde.name }}
                    </h2>
                </th>
                <th>
                    <h2 class="local-login-h2" *ngIf="hasSecondGemeinde">
                        {{ localLoginDaten?.second_gemeinde?.name }}
                    </h2>
                </th>
                <th>
                    <h2 class="local-login-h2" *ngIf="hasSecondGemeinde">
                        {{ localLoginDaten?.default_gemeinde.name }} &
                        {{ localLoginDaten?.second_gemeinde.name }}
                    </h2>
                </th>

                <!-- Admin BG -->
                <tr>
                    <td>Administrator/in BG</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorBGParis?.vorname +
                                '-' +
                                administratorBGParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorBGParis?.vorname +
                                ' ' +
                                administratorBGParis?.nachname
                            "
                            (click)="logIn(administratorBGParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorBGLondon?.vorname +
                                '-' +
                                administratorBGLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorBGLondon?.vorname +
                                ' ' +
                                administratorBGLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(administratorBGLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorBGParisLondon?.vorname +
                                '-' +
                                administratorBGParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorBGParisLondon?.vorname +
                                ' ' +
                                administratorBGParisLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(administratorBGParisLondon)"
                        ></div>
                    </td>
                </tr>
                <!-- Sachbearbeiter BG -->
                <tr>
                    <td>Sachbearbeiter/in BG</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterBGParis?.vorname +
                                '-' +
                                sachbearbeiterBGParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterBGParis?.vorname +
                                ' ' +
                                sachbearbeiterBGParis?.nachname
                            "
                            (click)="logIn(sachbearbeiterBGParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterBGLondon?.vorname +
                                '-' +
                                sachbearbeiterBGLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterBGLondon?.vorname +
                                ' ' +
                                sachbearbeiterBGLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(sachbearbeiterBGLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterBGParisLondon?.vorname +
                                '-' +
                                sachbearbeiterBGParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterBGParisLondon?.vorname +
                                ' ' +
                                sachbearbeiterBGParisLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(sachbearbeiterBGParisLondon)"
                        ></div>
                    </td>
                </tr>
                <!-- Admin TS -->
                <tr *ngIf="hasAnyAngebotTS">
                    <td>Administrator/in TS</td>
                    <td>
                        <div
                            *ngIf="defaultGemeinde?.angebotTS"
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorTSParis?.vorname +
                                '-' +
                                administratorTSParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorTSParis?.vorname +
                                ' ' +
                                administratorTSParis?.nachname
                            "
                            (click)="logIn(administratorTSParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            *ngIf="
                                hasSecondGemeinde && secondGemeinde?.angebotTS
                            "
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorTSLondon?.vorname +
                                '-' +
                                administratorTSLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorTSLondon?.vorname +
                                ' ' +
                                administratorTSLondon?.nachname
                            "
                            (click)="logIn(administratorTSLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            *ngIf="
                                hasSecondGemeinde &&
                                (defaultGemeinde?.angebotTS ||
                                    secondGemeinde?.angebotTS)
                            "
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorTSParisLondon?.vorname +
                                '-' +
                                administratorTSParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorTSParisLondon?.vorname +
                                ' ' +
                                administratorTSParisLondon?.nachname
                            "
                            (click)="logIn(administratorTSParisLondon)"
                        ></div>
                    </td>
                </tr>
                <!-- Sachbearbeiter TS -->
                <tr *ngIf="hasAnyAngebotTS">
                    <td>Sachbearbeiter/in TS</td>
                    <td>
                        <div
                            *ngIf="defaultGemeinde?.angebotTS"
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterTSParis?.vorname +
                                '-' +
                                sachbearbeiterTSParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterTSParis?.vorname +
                                ' ' +
                                sachbearbeiterTSParis?.nachname
                            "
                            (click)="logIn(sachbearbeiterTSParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            *ngIf="
                                hasSecondGemeinde && secondGemeinde?.angebotTS
                            "
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterTSLondon?.vorname +
                                '-' +
                                sachbearbeiterTSLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterTSLondon?.vorname +
                                ' ' +
                                sachbearbeiterTSLondon?.nachname
                            "
                            (click)="logIn(sachbearbeiterTSLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            *ngIf="
                                hasSecondGemeinde &&
                                (defaultGemeinde?.angebotTS ||
                                    secondGemeinde?.angebotTS)
                            "
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterTSParisLondon?.vorname +
                                '-' +
                                sachbearbeiterTSParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterTSParisLondon?.vorname +
                                ' ' +
                                sachbearbeiterTSParisLondon?.nachname
                            "
                            (click)="logIn(sachbearbeiterTSParisLondon)"
                        ></div>
                    </td>
                </tr>
                <!-- Admin GEMEINDE -->
                <tr>
                    <td>Administrator/in Gemeinde</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorGemeindeParis?.vorname +
                                '-' +
                                administratorGemeindeParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorGemeindeParis?.vorname +
                                ' ' +
                                administratorGemeindeParis?.nachname
                            "
                            (click)="logIn(administratorGemeindeParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorGemeindeLondon?.vorname +
                                '-' +
                                administratorGemeindeLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorGemeindeLondon?.vorname +
                                ' ' +
                                administratorGemeindeLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(administratorGemeindeLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                administratorGemeindeParisLondon?.vorname +
                                '-' +
                                administratorGemeindeParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                administratorGemeindeParisLondon?.vorname +
                                ' ' +
                                administratorGemeindeParisLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(administratorGemeindeParisLondon)"
                        ></div>
                    </td>
                </tr>
                <!-- Sachbearbeiter GEMEINDE -->
                <tr>
                    <td>Sachbearbeiter/in Gemeinde</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterGemeindeParis?.vorname +
                                '-' +
                                sachbearbeiterGemeindeParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterGemeindeParis?.vorname +
                                ' ' +
                                sachbearbeiterGemeindeParis?.nachname
                            "
                            (click)="logIn(sachbearbeiterGemeindeParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterGemeindeLondon?.vorname +
                                '-' +
                                sachbearbeiterGemeindeLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterGemeindeLondon?.vorname +
                                ' ' +
                                sachbearbeiterGemeindeLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(sachbearbeiterGemeindeLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterGemeindeParisLondon?.vorname +
                                '-' +
                                sachbearbeiterGemeindeParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterGemeindeParisLondon?.vorname +
                                ' ' +
                                sachbearbeiterGemeindeParisLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(sachbearbeiterGemeindeParisLondon)"
                        ></div>
                    </td>
                </tr>
                <!-- Sachbearbeiter Ferienbetreuung GEMEINDE -->
                <tr *ngIf="ferienbetreuungAktiv">
                    <td>Sachbearbeiter/in Ferienbetreuung Gemeinde</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterinFerienbetreuungGemeindeParis?.vorname +
                                '-' +
                                sachbearbeiterinFerienbetreuungGemeindeParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterinFerienbetreuungGemeindeParis?.vorname +
                                ' ' +
                                sachbearbeiterinFerienbetreuungGemeindeParis?.nachname
                            "
                            (click)="
                                logIn(
                                    sachbearbeiterinFerienbetreuungGemeindeParis
                                )
                            "
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterinFerienbetreuungGemeindeLondon?.vorname +
                                '-' +
                                sachbearbeiterinFerienbetreuungGemeindeLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterinFerienbetreuungGemeindeLondon?.vorname +
                                ' ' +
                                sachbearbeiterinFerienbetreuungGemeindeLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="
                                logIn(
                                    sachbearbeiterinFerienbetreuungGemeindeLondon
                                )
                            "
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                sachbearbeiterinFerienbetreuungGemeindeParisundLondon?.vorname +
                                '-' +
                                sachbearbeiterinFerienbetreuungGemeindeParisundLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                sachbearbeiterinFerienbetreuungGemeindeParisundLondon?.vorname +
                                ' ' +
                                sachbearbeiterinFerienbetreuungGemeindeParisundLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="
                                logIn(
                                    sachbearbeiterinFerienbetreuungGemeindeParisundLondon
                                )
                            "
                        ></div>
                    </td>
                </tr>

                <!-- Admin Ferienbetreuung GEMEINDE -->
                <tr *ngIf="ferienbetreuungAktiv">
                    <td>Administrator/in Ferienbetreuung Gemeinde</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                adminFerienbetreuungGemeindeParis?.vorname +
                                '-' +
                                adminFerienbetreuungGemeindeParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                adminFerienbetreuungGemeindeParis?.vorname +
                                ' ' +
                                adminFerienbetreuungGemeindeParis?.nachname
                            "
                            (click)="logIn(adminFerienbetreuungGemeindeParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                adminFerienbetreuungGemeindeLondon?.vorname +
                                '-' +
                                adminFerienbetreuungGemeindeLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                adminFerienbetreuungGemeindeLondon?.vorname +
                                ' ' +
                                adminFerienbetreuungGemeindeLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(adminFerienbetreuungGemeindeLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                adminFerienbetreuungGemeindeParisundLondon?.vorname +
                                '-' +
                                adminFerienbetreuungGemeindeParisundLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                adminFerienbetreuungGemeindeParisundLondon?.vorname +
                                ' ' +
                                adminFerienbetreuungGemeindeParisundLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="
                                logIn(
                                    adminFerienbetreuungGemeindeParisundLondon
                                )
                            "
                        ></div>
                    </td>
                </tr>

                <!-- Diverse -->
                <tr>
                    <td>Steueramt</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                steueramtParis?.vorname +
                                '-' +
                                steueramtParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                steueramtParis?.vorname +
                                ' ' +
                                steueramtParis?.nachname
                            "
                            (click)="logIn(steueramtParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                steueramtLondon?.vorname +
                                '-' +
                                steueramtLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                steueramtLondon?.vorname +
                                ' ' +
                                steueramtLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(steueramtLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                steueramtParisLondon?.vorname +
                                '-' +
                                steueramtParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                steueramtParisLondon?.vorname +
                                ' ' +
                                steueramtParisLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(steueramtParisLondon)"
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>Revisor/in</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                revisorParis?.vorname +
                                '-' +
                                revisorParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                revisorParis?.vorname +
                                ' ' +
                                revisorParis?.nachname
                            "
                            (click)="logIn(revisorParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                revisorLondon?.vorname +
                                '-' +
                                revisorLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                revisorLondon?.vorname +
                                ' ' +
                                revisorLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(revisorLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                revisorParisLondon?.vorname +
                                '-' +
                                revisorParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                revisorParisLondon?.vorname +
                                ' ' +
                                revisorParisLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(revisorParisLondon)"
                        ></div>
                    </td>
                </tr>
                <tr>
                    <td>Jurist/in</td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                juristParis?.vorname +
                                '-' +
                                juristParis?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                juristParis?.vorname +
                                ' ' +
                                juristParis?.nachname
                            "
                            (click)="logIn(juristParis)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                juristLondon?.vorname +
                                '-' +
                                juristLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                juristLondon?.vorname +
                                ' ' +
                                juristLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(juristLondon)"
                        ></div>
                    </td>
                    <td>
                        <div
                            class="first-row panel panel-default mark-hover panel-body"
                            [attr.data-test]="
                                'test-user-' +
                                juristParisLondon?.vorname +
                                '-' +
                                juristParisLondon?.nachname
                            "
                            [textContent]="
                                'Einloggen als ' +
                                juristParisLondon?.vorname +
                                ' ' +
                                juristParisLondon?.nachname
                            "
                            *ngIf="hasSecondGemeinde"
                            (click)="logIn(juristParisLondon)"
                        ></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
