<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="gemeinde-angaben">
    <h1 class="access-for-all-title" translate>LATS_GEMEINDE_ANGABEN</h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2 class="uppercase" translate>LATS_GEMEINDE_ANGABEN</h2>
            <hr class="header" />
        </div>
    </div>

    <form
        *ngIf="lATSAngabenGemeindeContainer"
        [formGroup]="formularInitForm"
        (ngSubmit)="onInitFormSubmit()"
        class="margin-bottom-20"
    >
        <div class="max-width-container-750">
            <div class="row">
                <div class="col-xs-12">
                    <dv-input-container-x>
                        <label required id="alle-angaben-radio-group">
                            {{
                                'LATS_ANGABEN_GEMEINDE_FRAGE_1'
                                    | translate
                                        : {
                                              periode:
                                                  lATSAngabenGemeindeContainer
                                                      ?.gesuchsperiode
                                                      ?.gesuchsperiodeString
                                          }
                            }}
                        </label>
                        <dv-radio-container-x>
                            <mat-radio-group
                                aria-labelledby="alle-angaben-radio-group"
                                class=""
                                name="alleAngaben"
                                horizontal
                                formControlName="alleAngabenInKibonErfasst"
                            >
                                <mat-radio-button [value]="true">{{
                                    'LABEL_JA' | translate
                                }}</mat-radio-button>
                                <mat-radio-button [value]="false">{{
                                    'LABEL_NEIN' | translate
                                }}</mat-radio-button>
                            </mat-radio-group>
                        </dv-radio-container-x>
                    </dv-input-container-x>
                </div>
            </div>

            <div class="actions">
                <dv-loading-button
                    *ngIf="showAntragErstellen()"
                    type="button"
                    [buttonDisabled]="!formularInitForm?.valid"
                    (buttonClick)="onInitFormSubmit()"
                >
                    <span translate>LATS_ANTRAG_ERSTELLEN</span>
                </dv-loading-button>
            </div>
        </div>
    </form>

    <mat-divider *ngIf="lATSAngabenGemeindeContainer"></mat-divider>

    <form
        *ngIf="lATSAngabenGemeindeContainer && initFormAnswered()"
        class="gemeinde-angaben-wrapper"
        [formGroup]="angabenForm"
        (ngSubmit)="onAngabenFormSubmit()"
    >
        <div class="max-width-container-750">
            <h3 translate class="uppercase">
                LATS_ALLGEMEINE_ANGABEN_GEMEINDE
            </h3>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls.bedarfBeiElternAbgeklaert
                                .errors
                    }"
                    id="bedarfBeiElternAbgeklaert-radio-group"
                >
                    {{ 'LATS_BEDARF_BEI_ELTERN_ABGEKLAERT' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="bedarfBeiElternAbgeklaert-radio-group"
                        class=""
                        name="bedarfBeiElternAbgeklaert"
                        horizontal
                        [disabled]="
                            angabenForm.controls.bedarfBeiElternAbgeklaert
                                .disabled
                        "
                        formControlName="bedarfBeiElternAbgeklaert"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.bedarfBeiElternAbgeklaert
                        "
                        [korrektur]="
                            angabenForm.value.bedarfBeiElternAbgeklaert
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .angebotFuerFerienbetreuungVorhanden.errors
                    }"
                    id="angebotFuerFerienbetreuungVorhanden-radio-group"
                >
                    {{
                        'LATS_ANGEBOT_FERIENBETREUUNG_VORHANDEN'
                            | translate
                                : {
                                      jahr:
                                          lATSAngabenGemeindeContainer.gesuchsperiode
                                          | nextPeriodeStr
                                  }
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="angebotFuerFerienbetreuungVorhanden-radio-group"
                        class=""
                        name="angebotFuerFerienbetreuungVorhanden"
                        horizontal
                        [disabled]="
                            angabenForm.controls
                                .angebotFuerFerienbetreuungVorhanden.disabled
                        "
                        formControlName="angebotFuerFerienbetreuungVorhanden"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.angebotFuerFerienbetreuungVorhanden
                        "
                        [korrektur]="
                            angabenForm.value
                                .angebotFuerFerienbetreuungVorhanden
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .angebotVerfuegbarFuerAlleSchulstufen.errors
                    }"
                    id="angebotVerfuegbarFuerAlleSchulstufen-radio-group"
                >
                    {{ 'LATS_ANGEBOT_VERFUEGBAR_ALLE_SCHULSTUFEN' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="angebotVerfuegbarFuerAlleSchulstufen-radio-group"
                        class=""
                        name="angebotVerfuegbarFuerAlleSchulstufen"
                        horizontal
                        [disabled]="
                            angabenForm.controls
                                .angebotVerfuegbarFuerAlleSchulstufen.disabled
                        "
                        formControlName="angebotVerfuegbarFuerAlleSchulstufen"
                        (change)="deleteBegruendung($event)"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.angebotVerfuegbarFuerAlleSchulstufen
                        "
                        [korrektur]="
                            angabenForm.value
                                .angebotVerfuegbarFuerAlleSchulstufen
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <div
                *ngIf="
                    angabenForm.value.angebotVerfuegbarFuerAlleSchulstufen ===
                    false
                "
            >
                <dv-input-container-x>
                    <mat-form-field
                        floatLabel="always"
                        class="flex-grow no-margin-left"
                        required
                    >
                        <label
                            required
                            [ngClass]="{
                                'label-required-error':
                                    abschliessenValidationActive &&
                                    angabenForm.value
                                        .angebotVerfuegbarFuerAlleSchulstufen ===
                                        false &&
                                    angabenForm.controls
                                        .begruendungWennAngebotNichtVerfuegbarFuerAlleSchulstufen
                                        .errors
                            }"
                        >
                            {{
                                'LATS_ANGEBOT_NICHT_VERFUEGBAR_ALLE_SCHULSTUFEN_BEGRUENDUNG'
                                    | translate
                            }}
                        </label>
                        <textarea
                            matInput
                            formControlName="begruendungWennAngebotNichtVerfuegbarFuerAlleSchulstufen"
                            class="form-control dv-input"
                        ></textarea>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.begruendungWennAngebotNichtVerfuegbarFuerAlleSchulstufen
                            "
                            [korrektur]="
                                angabenForm.value
                                    .begruendungWennAngebotNichtVerfuegbarFuerAlleSchulstufen
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="form-with-controlling">
            <div class="max-width-container-750">
                <h3 class="uppercase">{{ 'LATS_ABRECHNUNG' | translate }}</h3>
                <p class="italic margin-bottom-20">
                    {{ 'LATS_EXPLANATION_SUB_HEADER_ABRECHNUNG' | translate }}
                </p>
                <dv-input-container-x>
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenOhneBesondereBeduerfnisse
                                    .errors
                        }"
                    >
                        <span
                            [innerHTML]="
                                'LATS_GELEISTETE_BETREUUNGSSTUNDEN_SCHULJAHR'
                                    | translate
                                        : {
                                              jahr: lATSAngabenGemeindeContainer
                                                  .gesuchsperiode
                                                  ?.gesuchsperiodeString
                                          }
                            "
                        ></span>
                        <dv-tooltip
                            [text]="
                                'LATS_GELEISTETE_BETREUUNGSSTUNDEN_SCHULJAHR_INFO'
                                    | translate
                            "
                        ></dv-tooltip>
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="geleisteteBetreuungsstundenOhneBesondereBeduerfnisse"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenOhneBesondereBeduerfnisse
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenOhneBesondereBeduerfnisse
                                    .errors?.isNotPositiveInteger
                            "
                            translate
                            >ERROR_POSITIVE_NUMBER
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.geleisteteBetreuungsstundenOhneBesondereBeduerfnisse
                            "
                            [korrektur]="
                                angabenForm.value
                                    .geleisteteBetreuungsstundenOhneBesondereBeduerfnisse
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenBesondereBeduerfnisse
                                    .errors
                        }"
                    >
                        <span
                            [innerHtml]="
                                'LATS_BETREUUNGSSTUNDEN_SCHULJAHR_BESONDERE_ANFORDERUNGEN'
                                    | translate
                                        : {
                                              jahr: lATSAngabenGemeindeContainer
                                                  .gesuchsperiode
                                                  ?.gesuchsperiodeString
                                          }
                            "
                        ></span>
                        <dv-tooltip
                            [text]="
                                'LATS_BETREUUNGSSTUNDEN_SCHULJAHR_BESONDERE_ANFORDERUNGEN_INFO'
                                    | translate
                            "
                        ></dv-tooltip>
                    </label>

                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="geleisteteBetreuungsstundenBesondereBeduerfnisse"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenBesondereBeduerfnisse
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenBesondereBeduerfnisse
                                    .errors?.isNotPositiveInteger
                            "
                            translate
                            >ERROR_POSITIVE_NUMBER
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.geleisteteBetreuungsstundenBesondereBeduerfnisse
                            "
                            [korrektur]="
                                angabenForm.value
                                    .geleisteteBetreuungsstundenBesondereBeduerfnisse
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenBesondereVolksschulangebot
                                    .errors
                        }"
                    >
                        <span
                            [innerHtml]="
                                'LATS_BETREUUNGSSTUNDEN_BESONDERE_VOLKSSCHULANGEBOT'
                                    | translate
                                        : {
                                              jahr: lATSAngabenGemeindeContainer
                                                  .gesuchsperiode
                                                  ?.gesuchsperiodeString
                                          }
                            "
                        ></span>
                        <dv-tooltip
                            [text]="
                                'LATS_BETREUUNGSSTUNDEN_BESONDERE_VOLKSSCHULANGEBOT_INFO'
                                    | translate
                            "
                        ></dv-tooltip>
                    </label>

                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="geleisteteBetreuungsstundenBesondereVolksschulangebot"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenBesondereVolksschulangebot
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .geleisteteBetreuungsstundenBesondereVolksschulangebot
                                    .errors?.isNotPositiveInteger
                            "
                            translate
                            >ERROR_POSITIVE_NUMBER
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.geleisteteBetreuungsstundenBesondereVolksschulangebot
                            "
                            [korrektur]="
                                angabenForm.value
                                    .geleisteteBetreuungsstundenBesondereVolksschulangebot
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .lastenausgleichberechtigteBetreuungsstunden
                                    .errors
                        }"
                    >
                        {{
                            'LATS_LASTENAUSGLEICHBERECHTIGTE_BETREUUNGSSTUNDEN'
                                | translate
                        }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="lastenausgleichberechtigteBetreuungsstunden"
                            [readonly]="true"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <div class="custom-errors">
                    <div
                        *ngIf="
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .lastenausgleichberechtigteBetreuungsstunden
                                .errors?.notAllInstitutionsGeprueft
                        "
                    >
                        <i
                            class="fa fa-exclamation-triangle"
                            aria-hidden="true"
                        ></i>
                        <span translate
                            >LATS_NICHT_ALLE_INSTITUTIONEN_ABGESCHLOSSEN</span
                        >
                    </div>
                    <div
                        *ngIf="
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .lastenausgleichberechtigteBetreuungsstunden
                                .errors
                                ?.plausibilisierungTagesschulenStundenError &&
                            !angabenForm.controls
                                .lastenausgleichberechtigteBetreuungsstunden
                                .errors?.notAllInstitutionsGeprueft
                        "
                    >
                        <i
                            class="fa fa-exclamation-triangle"
                            aria-hidden="true"
                        ></i>
                        <span translate
                            >ERROR_LATS_PLAUSIBLISIERUNG_TAGESSCHULEN</span
                        >
                    </div>
                    <div
                        *ngIf="
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .davonStundenZuNormlohnMehrAls50ProzentAusgebildete
                                .errors?.plausibilisierungAdditionError
                        "
                    >
                        <i
                            class="fa fa-exclamation-triangle"
                            aria-hidden="true"
                        ></i>
                        <span>{{
                            'ERROR_LATS_PLAUSIBLISIERUNG_ADDITION'
                                | translate
                                    : {
                                          normlohnkostenHoch:
                                              (
                                                  lohnnormkostenSettingMoreThanFifty$
                                                  | async
                                              )?.value | number: '1.2-2',
                                          normlohnkostenTief:
                                              (
                                                  lohnnormkostenSettingLessThanFifty$
                                                  | async
                                              )?.value | number: '1.2-2'
                                      }
                        }}</span>
                    </div>
                    <div
                        *ngIf="
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .davonStundenZuNormlohnWenigerAls50ProzentAusgebildete
                                .errors
                                ?.plausibilisierungTagesschulenStundenError
                        "
                    >
                        <i
                            class="fa fa-exclamation-triangle"
                            aria-hidden="true"
                        ></i>
                        <span translate
                            >ERROR_LATS_PLAUSIBLISIERUNG_TAGESSCHULEN_STUNDEN</span
                        >
                    </div>
                </div>

                <dv-input-container-x>
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .davonStundenZuNormlohnMehrAls50ProzentAusgebildete
                                    .errors
                        }"
                    >
                        {{
                            'LATS_DAVON_STUNDEN_NORM_MEHR_ALS_50'
                                | translate
                                    : {
                                          lohnkosten:
                                              (
                                                  lohnnormkostenSettingMoreThanFifty$
                                                  | async
                                              )?.value | number: '1.2-2'
                                      }
                        }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="davonStundenZuNormlohnMehrAls50ProzentAusgebildete"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .davonStundenZuNormlohnMehrAls50ProzentAusgebildete
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .davonStundenZuNormlohnMehrAls50ProzentAusgebildete
                                    .errors?.isNotPositiveInteger
                            "
                            translate
                            >ERROR_POSITIVE_NUMBER
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.davonStundenZuNormlohnMehrAls50ProzentAusgebildete
                            "
                            [korrektur]="
                                angabenForm.value
                                    .davonStundenZuNormlohnMehrAls50ProzentAusgebildete
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="davonStundenZuNormlohnMehrAls50ProzentAusgebildeteBerechnet"
                            [readonly]="true"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .davonStundenZuNormlohnWenigerAls50ProzentAusgebildete
                                    .errors
                        }"
                    >
                        {{
                            'LATS_DAVON_STUNDEN_NORM_WENIGER_ALS_50'
                                | translate
                                    : {
                                          lohnkosten:
                                              (
                                                  lohnnormkostenSettingLessThanFifty$
                                                  | async
                                              )?.value | number: '1.2-2'
                                      }
                        }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="davonStundenZuNormlohnWenigerAls50ProzentAusgebildete"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .davonStundenZuNormlohnWenigerAls50ProzentAusgebildete
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .davonStundenZuNormlohnWenigerAls50ProzentAusgebildete
                                    .errors?.isNotPositiveInteger
                            "
                            translate
                            >ERROR_POSITIVE_NUMBER
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.davonStundenZuNormlohnWenigerAls50ProzentAusgebildete
                            "
                            [korrektur]="
                                angabenForm.value
                                    .davonStundenZuNormlohnWenigerAls50ProzentAusgebildete
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="davonStundenZuNormlohnWenigerAls50ProzentAusgebildeteBerechnet"
                            [readonly]="true"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .normlohnkostenBetreuungBerechnet.errors
                        }"
                    >
                        {{ 'LATS_NORMLOHNKOSTEN_BETREUUNG' | translate }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="normlohnkostenBetreuungBerechnet"
                            [readonly]="true"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls.einnahmenElterngebuehren
                                    .errors
                        }"
                    >
                        {{
                            'LATS_TATSACHLICHE_EINNAHMEN_ELTERNGEBUHREN'
                                | translate
                                    : {
                                          jahr: lATSAngabenGemeindeContainer
                                              .gesuchsperiode
                                              ?.gesuchsperiodeString
                                      }
                        }}
                        <dv-tooltip
                            [text]="
                                'LATS_TATSACHLICHE_EINNAHMEN_ELTERNGEBUHREN_INFO'
                                    | translate
                            "
                        ></dv-tooltip>
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="einnahmenElterngebuehren"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls.einnahmenElterngebuehren
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls.einnahmenElterngebuehren
                                    .errors?.noNumberError
                            "
                            translate
                            >ERROR_NUMBER
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls.einnahmenElterngebuehren
                                    .errors?.pattern &&
                                !angabenForm.controls.einnahmenElterngebuehren
                                    .errors?.noNumberError
                            "
                        >
                            {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.einnahmenElterngebuehren
                            "
                            [korrektur]="
                                angabenForm.value.einnahmenElterngebuehren
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .einnahmenElterngebuehrenVolksschulangebot
                                    .errors
                        }"
                    >
                        {{
                            'LATS_EINNAHMEN_ELTERNGEBUEHREN_VOLKSSCHALANGEBOT'
                                | translate
                                    : {
                                          jahr: lATSAngabenGemeindeContainer
                                              .gesuchsperiode
                                              ?.gesuchsperiodeString
                                      }
                        }}
                        <dv-tooltip
                            [text]="
                                'LATS_EINNAHMEN_ELTERNGEBUEHREN_VOLKSSCHALANGEBOT_INFO'
                                    | translate
                            "
                        ></dv-tooltip>
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="einnahmenElterngebuehrenVolksschulangebot"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .einnahmenElterngebuehrenVolksschulangebot
                                    .errors?.noNumberError
                            "
                            translate
                            >ERROR_NUMBER
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .einnahmenElterngebuehrenVolksschulangebot
                                    .errors?.pattern &&
                                !angabenForm.controls
                                    .einnahmenElterngebuehrenVolksschulangebot
                                    .errors?.noNumberError
                            "
                        >
                            {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.einnahmenElterngebuehrenVolksschulangebot
                            "
                            [korrektur]="
                                angabenForm.value
                                    .einnahmenElterngebuehrenVolksschulangebot
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x *ngIf="showCornonaFrage()">
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .tagesschuleTeilweiseGeschlossen.errors
                        }"
                    >
                        {{
                            'LATS_TAGESSCHULE_GESCHLOSSEN'
                                | translate
                                    : {
                                          periode:
                                              lATSAngabenGemeindeContainer
                                                  ?.gesuchsperiode
                                                  ?.gesuchsperiodeString
                                      }
                        }}
                        <dv-tooltip
                            [text]="
                                'LATS_TAGESSCHULE_GESCHLOSSEN_INFO' | translate
                            "
                        ></dv-tooltip>
                    </label>
                    <dv-radio-container-x>
                        <mat-radio-group
                            aria-labelledby="tagesschuleTeilweiseGeschlossen-radio-group"
                            class=""
                            name="tagesschuleTeilweiseGeschlossen"
                            horizontal
                            [disabled]="
                                angabenForm.controls
                                    .tagesschuleTeilweiseGeschlossen.disabled
                            "
                            formControlName="tagesschuleTeilweiseGeschlossen"
                        >
                            <mat-radio-button [value]="true">{{
                                'LABEL_JA' | translate
                            }}</mat-radio-button>
                            <mat-radio-button [value]="false">{{
                                'LABEL_NEIN' | translate
                            }}</mat-radio-button>
                        </mat-radio-group>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.tagesschuleTeilweiseGeschlossen
                            "
                            [korrektur]="
                                angabenForm.value
                                    .tagesschuleTeilweiseGeschlossen
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </dv-radio-container-x>
                </dv-input-container-x>

                <dv-input-container-x
                    *ngIf="angabenForm.value.tagesschuleTeilweiseGeschlossen"
                >
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .rueckerstattungenElterngebuehrenSchliessung
                                    .errors
                        }"
                    >
                        {{
                            'LATS_RUECKERSTATTUNG_TS_GESCHLOSSEN'
                                | translate
                                    : {
                                          jahr: lATSAngabenGemeindeContainer
                                              .gesuchsperiode
                                              ?.gesuchsperiodeString
                                      }
                        }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="rueckerstattungenElterngebuehrenSchliessung"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .rueckerstattungenElterngebuehrenSchliessung
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .rueckerstattungenElterngebuehrenSchliessung
                                    .errors?.noNumberError
                            "
                            translate
                            >ERROR_NUMBER
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .rueckerstattungenElterngebuehrenSchliessung
                                    .errors?.pattern &&
                                !angabenForm.controls
                                    .rueckerstattungenElterngebuehrenSchliessung
                                    .errors?.noNumberError
                            "
                        >
                            {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.rueckerstattungenElterngebuehrenSchliessung
                            "
                            [korrektur]="
                                angabenForm.value
                                    .rueckerstattungenElterngebuehrenSchliessung
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>
            </div>
            <div class="controlling-box" *ngIf="controllingActive()">
                <table class="besondere-beduerfnisse-controlling">
                    <tr>
                        <td>
                            {{
                                'LATS_CONTROLLING_ANTEIL_BETRSONDERE_BEDUERFNISSE'
                                    | translate
                            }}
                            {{
                                lATSAngabenGemeindeContainer?.gesuchsperiode
                                    .gesuchsperiodeString
                            }}
                        </td>
                        <td>
                            {{
                                controllingCalculator?.anteilStundenBesondereBeduerfnisseCurrentPeriode$
                                    | async
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{
                                'LATS_CONTROLLING_ANTEIL_BETRSONDERE_BEDUERFNISSE'
                                    | translate
                            }}
                            {{
                                lATSAngabenGemeindeContainer?.gesuchsperiode
                                    | previousPeriodeStr
                            }}
                        </td>
                        <td>
                            {{
                                controllingCalculator?.anteilStundenBesondereBeduerfnissePreviousPeriode$
                                    | async
                            }}
                        </td>
                    </tr>
                </table>
                <table class="betreuungsstunden-controlling">
                    <tr>
                        <td>
                            {{
                                'LATS_CONTROLLING_ERWARTETE_BETREUUNGSSTUNDEN'
                                    | translate
                            }}
                            {{
                                lATSAngabenGemeindeContainer?.gesuchsperiode
                                    .gesuchsperiodeString
                            }}
                        </td>
                        <td>{{ erwarteteBetreuungsstunden }}</td>
                    </tr>
                    <tr>
                        <td>
                            {{
                                'LATS_CONTROLLING_BETREUUNGSSTUNDEN_SCHULJAHR'
                                    | translate
                            }}
                            {{
                                lATSAngabenGemeindeContainer?.gesuchsperiode
                                    | previousPeriodeStr
                            }}
                        </td>
                        <td
                            *ngIf="
                                previousAntrag?.angabenKorrektur
                                    ?.lastenausgleichberechtigteBetreuungsstunden
                            "
                        >
                            {{
                                previousAntrag?.angabenKorrektur
                                    ?.lastenausgleichberechtigteBetreuungsstunden
                            }}
                        </td>
                        <td
                            *ngIf="
                                !previousAntrag?.angabenKorrektur
                                    ?.lastenausgleichberechtigteBetreuungsstunden
                            "
                        >
                            ?
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{
                                'LATS_CONTROLLING_VERAENDERUNG_BETREUUNGSSTUNDEN'
                                    | translate
                            }}
                        </td>
                        <td>
                            {{
                                controllingCalculator?.veraenderungBetreuungsstunden$
                                    | async
                            }}
                        </td>
                    </tr>
                </table>
                <table class="elternbeitrag-controlling">
                    <tr>
                        <td>
                            {{
                                'LATS_CONTROLLING_ANTEIL_ELTERNBEITRAEGE'
                                    | translate
                            }}
                            {{
                                lATSAngabenGemeindeContainer?.gesuchsperiode
                                    .gesuchsperiodeString
                            }}
                        </td>
                        <td>
                            {{
                                controllingCalculator?.anteilElternbeitraegeCurrentPeriode$
                                    | async
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{
                                'LATS_CONTROLLING_ANTEIL_ELTERNBEITRAEGE'
                                    | translate
                            }}
                            {{
                                lATSAngabenGemeindeContainer?.gesuchsperiode
                                    | previousPeriodeStr
                            }}
                        </td>
                        <td>
                            {{
                                controllingCalculator?.anteilElternbeitraegePreviousPeriode$
                                    | async
                            }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <mat-divider class="margin-bottom-20"></mat-divider>

        <div class="max-width-container-750">
            <dv-input-container-x>
                <label
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .lastenausgleichsberechtigerBetrag.errors
                    }"
                    class="bold-label"
                >
                    {{
                        'LATS_LASTENAUSGLEICHSBERECHTIGTER_BETRAG_SCHULJAHR'
                            | translate
                                : {
                                      jahr: lATSAngabenGemeindeContainer
                                          .gesuchsperiode?.gesuchsperiodeString
                                  }
                    }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="lastenausgleichsberechtigerBetrag"
                        class="form-control dv-input"
                        [readonly]="true"
                    />
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls.ersteRateAusbezahlt.errors
                    }"
                >
                    {{
                        'LATS_ERSTE_RATE_AUSBEZAHLT'
                            | translate: {year: getLastYear()}
                    }}
                    <dv-tooltip
                        [text]="
                            'LATS_ERSTE_RATE_AUSBEZAHLT_INFO'
                                | translate
                                    : {
                                          periode:
                                              lATSAngabenGemeindeContainer
                                                  ?.gesuchsperiode
                                                  ?.gesuchsperiodeString,
                                          previousYear: getLastYear()
                                      }
                        "
                    ></dv-tooltip>
                    <span>&nbsp;*</span>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="ersteRateAusbezahlt"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            abschliessenValidationActive &&
                            angabenForm.controls.ersteRateAusbezahlt.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            angabenForm.controls.ersteRateAusbezahlt.errors
                                ?.isNotPositiveInteger
                        "
                        translate
                        >ERROR_POSITIVE_NUMBER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.ersteRateAusbezahlt
                        "
                        [korrektur]="angabenForm.value.ersteRateAusbezahlt"
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label class="bold-label">
                    {{
                        'LATS_SCHLUSSZAHLUNG' | translate: {year: getNextYear()}
                    }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        formControlName="schlusszahlung"
                        class="form-control dv-input"
                        [readonly]="true"
                    />
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider class="margin-bottom-20"></mat-divider>

        <div class="form-with-controlling">
            <div class="max-width-container-750">
                <h3 class="uppercase">
                    {{ 'LATS_KOSTENBETEILIGUNG_GEMEINDE' | translate }}
                </h3>
                <p class="sub-header-explanation">
                    {{
                        'LATS_EXPLANATION_SUB_HEADER_KOSTENBETEILIGUNG_GEMEINDE'
                            | translate
                    }}
                </p>

                <dv-input-container-x>
                    <label
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls.gesamtKostenTagesschule
                                    .errors
                        }"
                    >
                        {{
                            'LATS_GESAMTKOSTEN_TAGESSCHULE'
                                | translate
                                    : {
                                          jahr: lATSAngabenGemeindeContainer
                                              .gesuchsperiode
                                              ?.gesuchsperiodeString
                                      }
                        }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="gesamtKostenTagesschule"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls.gesamtKostenTagesschule
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls.gesamtKostenTagesschule
                                    .errors?.noNumberError
                            "
                            translate
                            >ERROR_NUMBER
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls.gesamtKostenTagesschule
                                    .errors?.pattern &&
                                !angabenForm.controls.gesamtKostenTagesschule
                                    .errors?.noNumberError
                            "
                        >
                            {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.gesamtKostenTagesschule
                            "
                            [korrektur]="
                                angabenForm.value.gesamtKostenTagesschule
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label for="lastenausgleichsberechtigerBetragRO">
                        {{
                            'LATS_EINNAHMEN_LASTENAUSGLEICH' | translate
                        }}</label
                    >
                    <div class="flex-whitespace-item"></div>
                    <mat-form-field floatLabel="always">
                        <input
                            id="lastenausgleichsberechtigerBetragRO"
                            matInput
                            [readonly]="true"
                            formControlName="lastenausgleichsberechtigerBetragRO"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label for="einnahmenElterngebuehrenRO">
                        {{ 'LATS_EINNAHMEN_ELTERNGEBUEHREN' | translate }}
                    </label>
                    <div class="flex-whitespace-item"></div>
                    <mat-form-field floatLabel="always">
                        <input
                            id="einnahmenElterngebuehrenRO"
                            matInput
                            formControlName="einnahmenElterngebuehrenRO"
                            [readonly]="true"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        for="einnnahmenVerpflegung"
                        required
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls.einnnahmenVerpflegung
                                    .errors
                        }"
                    >
                        {{ 'LATS_EINNAHMEN_VERPFLEGUNG' | translate }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            id="einnnahmenVerpflegung"
                            matInput
                            formControlName="einnnahmenVerpflegung"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls.einnnahmenVerpflegung
                                    .errors?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls.einnnahmenVerpflegung
                                    .errors?.noNumberError
                            "
                            translate
                            >ERROR_NUMBER
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls.einnnahmenVerpflegung
                                    .errors?.pattern &&
                                !angabenForm.controls.einnnahmenVerpflegung
                                    .errors?.noNumberError
                            "
                        >
                            {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.einnnahmenVerpflegung
                            "
                            [korrektur]="
                                angabenForm.value.einnnahmenVerpflegung
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .einnahmenSubventionenDritter.errors
                        }"
                    >
                        {{ 'LATS_EINNAHMEN_SUBVENTIONEN_DRITTER' | translate }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="einnahmenSubventionenDritter"
                            class="form-control dv-input"
                        />
                        <mat-error
                            *ngIf="
                                abschliessenValidationActive &&
                                angabenForm.controls
                                    .einnahmenSubventionenDritter.errors
                                    ?.required
                            "
                            translate
                            >ERROR_REQUIRED
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .einnahmenSubventionenDritter.errors
                                    ?.noNumberError
                            "
                            translate
                            >ERROR_NUMBER
                        </mat-error>
                        <mat-error
                            *ngIf="
                                angabenForm.controls
                                    .einnahmenSubventionenDritter.errors
                                    ?.pattern &&
                                !angabenForm.controls
                                    .einnahmenSubventionenDritter.errors
                                    ?.noNumberError
                            "
                        >
                            {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                        </mat-error>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.einnahmenSubventionenDritter
                            "
                            [korrektur]="
                                angabenForm.value.einnahmenSubventionenDritter
                            "
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label>
                        {{ 'LATS_KOSTENBEITRAG_GEMEINDE' | translate }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="kostenbeitragGemeinde"
                            [readonly]="true"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label>
                        {{ 'LATS_KOSTENUEBERSCHUSS_GEMEINDE' | translate }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="kostenueberschussGemeinde"
                            [readonly]="true"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label>
                        {{
                            'LATS_ERWARTETER_KOSTENBEITRAG_GEMEINDE' | translate
                        }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            matInput
                            formControlName="erwarteterKostenbeitragGemeinde"
                            [readonly]="true"
                            class="form-control dv-input"
                        />
                    </mat-form-field>
                </dv-input-container-x>

                <dv-input-container-x>
                    <label
                        [ngClass]="{
                            'label-required-error':
                                abschliessenValidationActive &&
                                angabenForm.controls.ueberschussErzielt.errors
                        }"
                    >
                        {{
                            'LATS_UEBERSCHUSS_ERZIELT'
                                | translate
                                    : {
                                          periode:
                                              lATSAngabenGemeindeContainer?.gesuchsperiode
                                              | previousPeriodeStr
                                      }
                        }}
                        <span>&nbsp;*</span>
                    </label>
                    <dv-radio-container-x>
                        <mat-radio-group
                            aria-labelledby="ueberschussErzielt-radio-group"
                            class=""
                            name="ueberschussErzielt"
                            horizontal
                            [disabled]="
                                angabenForm.controls.ueberschussErzielt.disabled
                            "
                            formControlName="ueberschussErzielt"
                        >
                            <mat-radio-button [value]="true">{{
                                'LABEL_JA' | translate
                            }}</mat-radio-button>
                            <mat-radio-button [value]="false">{{
                                'LABEL_NEIN' | translate
                            }}</mat-radio-button>
                        </mat-radio-group>
                        <dv-bisher-x
                            [deklaration]="
                                lATSAngabenGemeindeContainer?.angabenDeklaration
                                    ?.ueberschussErzielt
                            "
                            [korrektur]="angabenForm.value.ueberschussErzielt"
                            [showBisher]="
                                lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </dv-radio-container-x>
                </dv-input-container-x>

                <div *ngIf="angabenForm.value.ueberschussErzielt === true">
                    <dv-input-container-x>
                        <mat-form-field
                            floatLabel="always"
                            class="flex-grow no-margin-left"
                            required
                        >
                            <label
                                required
                                [ngClass]="{
                                    'label-required-error':
                                        abschliessenValidationActive &&
                                        angabenForm.controls
                                            .ueberschussVerwendung.errors
                                }"
                            >
                                {{
                                    'LATS_UEBERSCHUSS_VERWENDUNG'
                                        | translate
                                            : {
                                                  jahr: lATSAngabenGemeindeContainer
                                                      .gesuchsperiode
                                                      ?.gesuchsperiodeString
                                              }
                                }}
                            </label>
                            <textarea
                                matInput
                                formControlName="ueberschussVerwendung"
                                class="form-control dv-input"
                            ></textarea>
                            <mat-error
                                *ngIf="
                                    abschliessenValidationActive &&
                                    angabenForm.controls.ueberschussVerwendung
                                        .errors?.required
                                "
                                translate
                                >ERROR_REQUIRED
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    angabenForm.controls.ueberschussVerwendung
                                        .errors?.noNumberError
                                "
                                translate
                                >ERROR_NUMBER
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    angabenForm.controls.ueberschussVerwendung
                                        .errors?.pattern &&
                                    !angabenForm.controls.ueberschussVerwendung
                                        .errors?.noNumberError
                                "
                            >
                                {{ 'ERROR_STEP' | translate: {value: 0.01} }}
                            </mat-error>
                            <dv-bisher-x
                                [deklaration]="
                                    lATSAngabenGemeindeContainer
                                        ?.angabenDeklaration
                                        ?.ueberschussVerwendung
                                "
                                [korrektur]="
                                    angabenForm.value.ueberschussVerwendung
                                "
                                [showBisher]="
                                    lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                                "
                                [specificBisherLabel]="
                                    'LATS_BISHER_LABEL' | translate
                                "
                            >
                            </dv-bisher-x>
                        </mat-form-field>
                    </dv-input-container-x>
                </div>
            </div>
            <div class="controlling-box" *ngIf="controllingActive()">
                <table class="kostenbeitrag-controlling">
                    <tr>
                        <td>
                            {{ 'LATS_CONTROLLING_KOSTENANTEIL' | translate }}
                        </td>
                        <td>
                            {{
                                this.controllingCalculator
                                    ?.kostenanteilGemeindeGesamtkosten$ | async
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{
                                'LATS_CONTROLLING_UEBERSCHUSSANTEIL' | translate
                            }}
                        </td>
                        <td>
                            {{
                                this.controllingCalculator
                                    ?.erstragsanteilGemeindeGesamtkosten$
                                    | async
                            }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 class="uppercase">
                {{ 'LATS_ANGABEN_WEITERE_KOSTEN_ERTRAEGE' | translate }}
            </h3>

            <dv-input-container-x [verticalAlign]="true">
                <mat-form-field floatLabel="always">
                    <label>
                        {{
                            'LATS_BEMERKUNG_WIETER_KOSTEN_ERTRAEGE' | translate
                        }}
                    </label>
                    <textarea
                        matInput
                        formControlName="bemerkungenWeitereKostenUndErtraege"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.bemerkungenWeitereKostenUndErtraege
                        "
                        [korrektur]="
                            angabenForm.value
                                .bemerkungenWeitereKostenUndErtraege
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 class="uppercase" translate>
                LATS_KONTROLLFRAGEN_GEMEINDE_ANGABEN
            </h3>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .betreuungsstundenDokumentiertUndUeberprueft
                                .errors
                    }"
                    id="betreuungsstundenDokumentiertUndUeberprueft-radio-group"
                >
                    {{
                        'LATS_BETREUUNGSSTUNDEN_DOKUMENTIERT_UEBERPRUEFT'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="betreuungsstundenDokumentiertUndUeberprueft-radio-group"
                        class=""
                        name="betreuungsstundenDokumentiertUndUeberprueft"
                        horizontal
                        (change)="
                            clearFormFieldOnChangeToTrue(
                                $event,
                                'betreuungsstundenDokumentiertUndUeberprueftBemerkung'
                            )
                        "
                        [disabled]="
                            angabenForm.controls
                                .betreuungsstundenDokumentiertUndUeberprueft
                                .disabled
                        "
                        formControlName="betreuungsstundenDokumentiertUndUeberprueft"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.betreuungsstundenDokumentiertUndUeberprueft
                        "
                        [korrektur]="
                            angabenForm.value
                                .betreuungsstundenDokumentiertUndUeberprueft
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x
                [verticalAlign]="true"
                *ngIf="
                    angabenForm.value
                        .betreuungsstundenDokumentiertUndUeberprueft === false
                "
            >
                <mat-form-field floatLabel="always">
                    <label required>{{
                        'LATS_ANGABEN_GEMEINDE_BEMERKUNG' | translate
                    }}</label>
                    <textarea
                        matInput
                        formControlName="betreuungsstundenDokumentiertUndUeberprueftBemerkung"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.betreuungsstundenDokumentiertUndUeberprueftBemerkung
                        "
                        [korrektur]="
                            angabenForm.value
                                .betreuungsstundenDokumentiertUndUeberprueftBemerkung
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .elterngebuehrenGemaessVerordnungBerechnet
                                .errors
                    }"
                    id="elterngebuehrenGemaessVerordnungBerechnet-radio-group"
                >
                    {{
                        'LATS_ELTERNGEBUEHREN_VERORDNUNG_BERECHNET' | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="elterngebuehrenGemaessVerordnungBerechnet-radio-group"
                        class=""
                        name="elterngebuehrenGemaessVerordnungBerechnet"
                        horizontal
                        (change)="
                            clearFormFieldOnChangeToTrue(
                                $event,
                                'elterngebuehrenGemaessVerordnungBerechnetBemerkung'
                            )
                        "
                        [disabled]="
                            angabenForm.controls
                                .elterngebuehrenGemaessVerordnungBerechnet
                                .disabled
                        "
                        formControlName="elterngebuehrenGemaessVerordnungBerechnet"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.elterngebuehrenGemaessVerordnungBerechnet
                        "
                        [korrektur]="
                            angabenForm.value
                                .elterngebuehrenGemaessVerordnungBerechnet
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x
                [verticalAlign]="true"
                *ngIf="
                    angabenForm.value
                        .elterngebuehrenGemaessVerordnungBerechnet === false
                "
            >
                <mat-form-field floatLabel="always">
                    <label required>{{
                        'LATS_ANGABEN_GEMEINDE_BEMERKUNG' | translate
                    }}</label>
                    <textarea
                        matInput
                        formControlName="elterngebuehrenGemaessVerordnungBerechnetBemerkung"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.elterngebuehrenGemaessVerordnungBerechnetBemerkung
                        "
                        [korrektur]="
                            angabenForm.value
                                .elterngebuehrenGemaessVerordnungBerechnetBemerkung
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider class="margin-bottom-20"></mat-divider>

        <div class="max-width-container-750">
            <dv-input-container-x>
                <label translate>LATS_EINKOMMEN_ELTERN_EINLEITUNG</label>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls.einkommenElternBelegt.errors
                    }"
                    id="einkommenElternBelegt-radio-group"
                >
                    {{ 'LATS_EINKOMMEN_ELTERN_BELEGT' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="einkommenElternBelegt-radio-group"
                        class=""
                        name="einkommenElternBelegt"
                        horizontal
                        (change)="
                            clearFormFieldOnChangeToTrue(
                                $event,
                                'einkommenElternBelegtBemerkung'
                            )
                        "
                        [disabled]="
                            angabenForm.controls.einkommenElternBelegt.disabled
                        "
                        formControlName="einkommenElternBelegt"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.einkommenElternBelegt
                        "
                        [korrektur]="angabenForm.value.einkommenElternBelegt"
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x
                [verticalAlign]="true"
                *ngIf="angabenForm.value.einkommenElternBelegt === false"
            >
                <mat-form-field floatLabel="always">
                    <label required>{{
                        'LATS_ANGABEN_GEMEINDE_BEMERKUNG' | translate
                    }}</label>
                    <textarea
                        matInput
                        formControlName="einkommenElternBelegtBemerkung"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.einkommenElternBelegtBemerkung
                        "
                        [korrektur]="
                            angabenForm.value.einkommenElternBelegtBemerkung
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label id="maximalTarif-radio-group">
                    {{ 'LATS_EINKOMMEN_ELTERN_MAXIMALTARIF' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="maximalTarif-radio-group"
                        class=""
                        name="maximalTarif"
                        horizontal
                        (change)="
                            clearFormFieldOnChangeToTrue(
                                $event,
                                'maximalTarifBemerkung'
                            )
                        "
                        [disabled]="angabenForm.controls.maximalTarif.disabled"
                        formControlName="maximalTarif"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.maximalTarif
                        "
                        [korrektur]="angabenForm.value.maximalTarif"
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x
                [verticalAlign]="true"
                *ngIf="angabenForm.value.maximalTarif === false"
            >
                <mat-form-field floatLabel="always">
                    <label required>{{
                        'LATS_ANGABEN_GEMEINDE_BEMERKUNG' | translate
                    }}</label>
                    <textarea
                        matInput
                        formControlName="maximalTarifBemerkung"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.maximalTarifBemerkung
                        "
                        [korrektur]="angabenForm.value.maximalTarifBemerkung"
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider class="margin-bottom-20"></mat-divider>

        <div class="max-width-container-750">
            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls
                                .mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal
                                .errors
                    }"
                    id="mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal-radio-group"
                >
                    {{
                        'LATS_MNIDESTENS50_BETREUUNGSZEIT_AUSGEBILDETES_PERSONAL'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal-radio-group"
                        class=""
                        name="mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal"
                        horizontal
                        (change)="
                            clearFormFieldOnChangeToTrue(
                                $event,
                                'mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonalBemerkung'
                            )
                        "
                        [disabled]="
                            angabenForm.controls
                                .mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal
                                .disabled
                        "
                        formControlName="mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal
                        "
                        [korrektur]="
                            angabenForm.value
                                .mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x
                [verticalAlign]="true"
                *ngIf="
                    angabenForm.value
                        .mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonal ===
                    false
                "
            >
                <mat-form-field floatLabel="always">
                    <label>{{
                        'LATS_ANGABEN_GEMEINDE_BEMERKUNG' | translate
                    }}</label>
                    <textarea
                        matInput
                        formControlName="mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonalBemerkung"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonalBemerkung
                        "
                        [korrektur]="
                            angabenForm.value
                                .mindestens50ProzentBetreuungszeitDurchAusgebildetesPersonalBemerkung
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            abschliessenValidationActive &&
                            angabenForm.controls.ausbildungenMitarbeitendeBelegt
                                .errors
                    }"
                    id="ausbildungenMitarbeitendeBelegt-radio-group"
                >
                    {{ 'LATS_AUSBILDUNG_MITARBEITENDE_BELEGT' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="ausbildungenMitarbeitendeBelegt-radio-group"
                        class=""
                        name="ausbildungenMitarbeitendeBelegt"
                        horizontal
                        (change)="
                            clearFormFieldOnChangeToTrue(
                                $event,
                                'ausbildungenMitarbeitendeBelegtBemerkung'
                            )
                        "
                        [disabled]="
                            angabenForm.controls.ausbildungenMitarbeitendeBelegt
                                .disabled
                        "
                        formControlName="ausbildungenMitarbeitendeBelegt"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.ausbildungenMitarbeitendeBelegt
                        "
                        [korrektur]="
                            angabenForm.value.ausbildungenMitarbeitendeBelegt
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x
                [verticalAlign]="true"
                *ngIf="
                    angabenForm.value.ausbildungenMitarbeitendeBelegt === false
                "
            >
                <mat-form-field floatLabel="always">
                    <label required>{{
                        'LATS_ANGABEN_GEMEINDE_BEMERKUNG' | translate
                    }}</label>
                    <textarea
                        matInput
                        formControlName="ausbildungenMitarbeitendeBelegtBemerkung"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.ausbildungenMitarbeitendeBelegtBemerkung
                        "
                        [korrektur]="
                            angabenForm.value
                                .ausbildungenMitarbeitendeBelegtBemerkung
                        "
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 class="uppercase">
                {{ 'LATS_ANGABEN_GEMEINDE_BEMERKUNGEN' | translate }}
            </h3>

            <dv-input-container-x [verticalAlign]="true">
                <mat-form-field floatLabel="always">
                    <textarea
                        matInput
                        formControlName="bemerkungen"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            lATSAngabenGemeindeContainer?.angabenDeklaration
                                ?.bemerkungen
                        "
                        [korrektur]="angabenForm.value.bemerkungen"
                        [showBisher]="
                            lATSAngabenGemeindeContainer.isAtLeastInBearbeitungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <div *ngIf="hasStarkeVeraenderung">
            <div class="well well-status-warten">
                <i class="fa fa-exclamation" aria-hidden="true"></i>
                <span>{{
                    'LATS_WARNUNG_STARKE_VERAENDERUNG'
                        | translate
                            : {
                                  prozent:
                                      controllingCalculator?.veraenderungBetreuungsstunden$
                                      | async
                              }
                }}</span>
            </div>

            <h3>{{ 'LATS_BEGRUENDUNG_STARKE_VERAENDERUNG' | translate }}</h3>

            <div class="max-width-container-750">
                <dv-input-container-x [verticalAlign]="true">
                    <mat-form-field floatLabel="always">
                        <textarea
                            matInput
                            required
                            formControlName="bemerkungStarkeVeraenderung"
                            class="form-control dv-input"
                        ></textarea>
                    </mat-form-field>
                </dv-input-container-x>
            </div>
        </div>

        <div
            class="well well-status-warten"
            *ngIf="
                !lATSAngabenGemeindeContainer?.allAngabenInstitutionContainersGeprueft()
            "
        >
            <i class="fa fa-exclamation" aria-hidden="true"></i>
            <span>
                <span translate>LATS_ABSCHLIESSEN_NOCH_NICHT_MOEGLICH</span>
            </span>
        </div>

        <div class="actions">
            <button
                class="dv-btn cancel-button uppercase"
                type="button"
                *ngIf="falscheAngabenVisible | async"
                (click)="onFalscheAngaben()"
            >
                <span translate>FERIENBETREUUNG_ANGABEN_KORRIGIEREN</span>
            </button>
            <dv-loading-button
                class="uppercase"
                type="button"
                *ngIf="saveVisible | async"
                [ngClass]="{secondary: !formularNotEditable()}"
                [buttonDisabled]="formularNotEditable()"
                (buttonClick)="onAngabenFormSubmit()"
            >
                <span translate>SAVE</span>
            </dv-loading-button>
            <dv-loading-button
                class="uppercase"
                type="button"
                *ngIf="abschliessenVisible | async"
                [buttonDisabled]="formularNotEditable()"
                (buttonClick)="onAbschliessen()"
            >
                <span
                    *ngIf="
                        lATSAngabenGemeindeContainer?.isInBearbeitungGemeinde()
                    "
                    translate
                    >ABSCHLIESSEN</span
                >
                <span
                    *ngIf="
                        lATSAngabenGemeindeContainer?.isInBearbeitungKanton()
                    "
                    translate
                    >GEPRUEFT</span
                >
            </dv-loading-button>
        </div>
    </form>
</div>
