<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="institutionen-list admin-modul" flex="auto">
    <div class="col-md-8 col-md-offset-2">
        <h1 class="access-for-all-title" translate>
            AFA_INSTITUTIONENVERWALTUNG
        </h1>
        <div class="view-title">
            <h2 translate>INSTITUTIONEN</h2>
            <hr class="header" />
        </div>

        <dv-search-list
            [hiddenColumns]="hiddenDVTableColumns"
            [data$]="antragList$"
            (openEvent)="openInstitution($event)"
            (removeEvent)="removeInstitution($event)"
            [statusPrefix]="'INSTITUTION_STATUS'"
            [columnName]="'INSTITUTION'"
        ></dv-search-list>
    </div>
    <div class="col-md-offset-2 dv-navigation-mobileless">
        <button
            *ngIf="isCreateBGAllowed()"
            (click)="createInstitutionBG()"
            class="dv-btn next-button"
        >
            <span translate>INSTITUTION_HINZUFUEGEN</span>
        </button>

        <button
            *ngIf="isCreateTSAllowed() && !isAdminBG()"
            (click)="createInstitutionTS()"
            class="dv-btn next-button"
            data-test="institution.create-tagesschule"
        >
            <span translate>TAGESSCHULE_HINZUFUEGEN</span>
        </button>

        <button
            *ngIf="isCreateLATSTSAllowed() && !isBGRole()"
            (click)="createLATSInstitutionTS()"
            class="dv-btn next-button"
        >
            <span translate>LATS_TAGESSCHULE_HINZUFUEGEN</span>
        </button>

        <button
            *ngIf="isCreateFIAllowed() && !isAdminBG()"
            (click)="createInstitutionFI()"
            class="dv-btn next-button"
        >
            <span translate>FERIENINSEL_HINZUFUEGEN</span>
        </button>
    </div>
</div>
