<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<h1 class="access-for-all-title" translate>FERIENBETREUUNG_DOKUMENT_TITLE</h1>
<div class="row view-title">
    <div class="col-md-12">
        <h2>
            <span translate>FERIENBETREUUNG_DOKUMENT_TITLE</span>
            <dv-tooltip
                [text]="'FERIENBETREUUNG_DOKUMENT_TITLE_HINT' | translate"
            ></dv-tooltip>
        </h2>
        <hr class="header" />
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <dv-multiple-file-upload
            (download)="download($event[0], $event[1])"
            [title]="'FERIENBETREUUNG_DOKUMENT_UPLOAD_TITLE'"
            (delete)="onDelete($event)"
            (uploadFile)="onUpload($event)"
            [files]="dokumente"
            [readOnly]="isReadonly()"
            [readOnlyDelete]="isReadonly()"
        >
        </dv-multiple-file-upload>
        <div class="dv-error-messages" *ngFor="let file of filesTooBig">
            <span>
                {{
                    'FEHLER_BEIM_HOCHLADEN_VON_DOKUMENT'
                        | translate: {dokumentName: file.name}
                }}
                {{ 'ERROR_FILE_TOO_LARGE' | translate }}
            </span>
        </div>
    </div>
</div>
