<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<dv-full-height-container>
    <div class="dv-content">
        <div class="row view-title">
            <div class="col-md-12">
                <h2 translate>ONBOARDING_BG_LOGIN_REQUIRED</h2>
                <hr class="header" />
            </div>
        </div>

        <p class="margin-bottom-50" translate>
            ONBOARDING_BE_LOGIN_DESCRIPTION
        </p>

        <div class="row be-login-actions">
            <!-- Note: we navigate to the desired target state. The authentication hooks will automatically redirect to the login page -->
            <a class="dv-btn" href="" (click)="goToLoginWithReturnToState()">
                {{ 'ONBOARDING_BE_LOGIN_ANMELDEN' | translate }}
                <i class="fa fa-external-link"></i>
            </a>
        </div>
    </div>
</dv-full-height-container>
