<div class="gesuch-modul">
    <h1 class="access-for-all-title" translate>AFA_GESUCHSFORMULAR</h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                <span
                    translate
                    [translateParams]="{antragstellende: getGSName()}"
                    >EKVI_EINKOMMENSVERSCHLECHTERUNG_FUER_BASISJAHR</span
                >
            </h2>
            <hr class="header" />
        </div>
    </div>

    <ng-container
        *ngIf="
            isFinSitVollstaendigAusgefuellt;
            then finSitAusgefuellt;
            else finSitNichtAusgefuellt
        "
    ></ng-container>

    <ng-template #finSitNichtAusgefuellt>
        <div
            data-test="warnung-ekv-fin-sit-ausfuellen"
            class="well well-status-warten"
        >
            <span translate>WARNUNG_FIN_SIT_AUSFUELLEN</span>
        </div>
    </ng-template>
    <ng-template #finSitAusgefuellt>
        <form #form="ngForm">
            <ng-container
                *ngIf="
                    model.getFiSiConToWorkWith().finanzielleSituationJA
                        .quellenbesteuert;
                    then bruttolohnFragen;
                    else steuerveranlagFragen
                "
            ></ng-container>
            <ng-template #steuerveranlagFragen>
                <dv-steuerveranlagt-schwyz
                    [finSitJA]="model.getEkvToWorkWith()"
                    [finSitGS]="model.getEkvToWorkWith_GS()"
                    [readonly]="gesuchmodelManager.isGesuchReadonly()"
                    [showHeader]="false"
                    (valueChanged)="recalculatedMassgebendesEinkommen()"
                >
                </dv-steuerveranlagt-schwyz>
            </ng-template>
            <ng-template #bruttolohnFragen>
                <dv-bruttolohn-schwyz
                    [finSitJA]="model.getEkvToWorkWith()"
                    [finSitGS]="model.getEkvToWorkWith_GS()"
                    [readonly]="gesuchmodelManager.isGesuchReadonly()"
                    (valueChanged)="recalculatedMassgebendesEinkommen()"
                >
                </dv-bruttolohn-schwyz>
            </ng-template>
        </form>

        <dv-navigation-x
            (dvSave)="prepareSave($event.onResult)"
            [dvNext]="true"
            [dvPrevious]="true"
            [dvSubStep]="3"
        ></dv-navigation-x>
    </ng-template>
</div>
