<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<dv-full-height-container>
    <form
        #form="ngForm"
        [ngClass]="{'ng-submitted': form.submitted}"
        novalidate
    >
        <div class="dv-content">
            <div class="row view-title">
                <div class="col-md-12">
                    <h1 translate>EINLADUNG_ABSCHLIESSEN</h1>
                    <hr class="header" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h2 translate>KIBON_DATEN</h2>
                    <hr class="header" />
                    <fieldset>
                        <div class="margin-top-20">
                            <dv-berechtigung
                                displayClass="col-sm-12"
                                [disabled]="true"
                                [useInputComponents]="false"
                                [berechtigung]="principal.currentBerechtigung"
                            >
                            </dv-berechtigung>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-6">
                    <h2 translate>BE_LOGIN_DATEN</h2>
                    <hr class="header" />
                    <fieldset>
                        <div class="margin-top-20">
                            <table class="einladung-abschliessen">
                                <tbody>
                                    <tr>
                                        <td class="bold" translate>VORNAME</td>
                                        <td>{{ principal.vorname }}</td>
                                    </tr>
                                    <tr>
                                        <td class="bold" translate>NAME</td>
                                        <td>{{ principal.nachname }}</td>
                                    </tr>
                                    <tr>
                                        <td class="bold" translate>EMAIL</td>
                                        <td>{{ principal.email }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>

        <div class="dv-footer">
            <dv-loading-button (buttonClick)="next()">
                <span translate>EINLADUNG_ABSCHLIESSEN</span>
            </dv-loading-button>
        </div>
    </form>
</dv-full-height-container>
