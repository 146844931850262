<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="gesuch-modul">
    <h1 class="access-for-all-title" translate>AFA_GESUCHSFORMULAR</h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                <span
                    translate
                    [translateParams]="{
                        gesuchsteller: getAntragstellerNameForCurrentStep(),
                        basisjahr: getYearForDeklaration()
                    }"
                    >FINANZIELLE_SITUATION</span
                >
            </h2>
            <hr class="header" />
        </div>
    </div>
    <form #form="ngForm" role="form">
        <dv-steuerveranlagung-erhalten
            *ngIf="!isSteuerveranlagungGemeinsam()"
            [model]="getModel()"
            (steuerveranlagungErhaltenChange)="
                steuerveranlagungErhaltenChange($event)
            "
        ></dv-steuerveranlagung-erhalten>
        <div *ngIf="isSelbststaendigErwerbendAnswered()">
            <dv-veranlagung-solothurn
                *ngIf="hasSteuerveranlagungErhalten()"
                [model]="getModel()"
                [dvValueChange]="onValueChangeFunction"
                [readOnly]="gesuchModelManager.isGesuchReadonly()"
            ></dv-veranlagung-solothurn>
            <dv-bruttolohn
                *ngIf="!hasSteuerveranlagungErhalten()"
                [model]="getModel()"
                [dvValueChange]="onValueChangeFunction"
            ></dv-bruttolohn>
            <dv-massgebendes-einkommen
                [massgebendesEinkommen$]="getMassgebendesEinkommen$()"
                [isGemeinsam]="isGemeinsam()"
                [antragstellerNummer]="getAntragstellerNummer()"
                [nameGS1]="extractFullNameGS1()"
                [nameGS2]="extractFullNameGS2()"
            ></dv-massgebendes-einkommen>
        </div>

        <dv-navigation-x
            (dvSave)="prepareSave($event.onResult)"
            [dvNext]="true"
            [dvPrevious]="true"
            [dvSubStep]="getSubStepIndex()"
            [dvSubStepName]="getSubStepName()"
        ></dv-navigation-x>
    </form>
</div>
