import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import {CONSTANTS} from '@kibon/shared/model/constants';
@Pipe({
    name: 'ebeguDate'
})
export class EbeguDatePipe implements PipeTransform {
    public transform(date: moment.Moment): string {
        if (date?.isValid()) {
            return date.format(CONSTANTS.DATE_FORMAT);
        }
        return '';
    }
}
