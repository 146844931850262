<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<dv-full-height-container>
    <form
        role="form"
        #form="ngForm"
        [ngClass]="{'ng-submitted': form.submitted}"
        (ngSubmit)="traegerschaftEinladen()"
        novalidate
    >
        <div class="dv-content">
            <h1 class="access-for-all-title" translate>
                AFA_TRAEGERSCHAFTVERWALTUNG
            </h1>
            <div class="view-title">
                <h2 translate>NEUE_TRAEGERSCHAFT</h2>
                <hr class="header" />
            </div>

            <fieldset>
                <div class="row">
                    <!-- Name -->
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label translate for="name_id">NAME</label>
                            <input
                                id="name_id"
                                type="text"
                                name="name_id"
                                #name="ngModel"
                                [(ngModel)]="traegerschaft.name"
                                [maxlength]="255"
                                class="form-control"
                                required
                            />
                            <dv-error-messages
                                [errorObject]="form.controls.name_id?.errors"
                                inputId="name_id"
                            ></dv-error-messages>
                        </div>
                    </div>
                    <!-- E-Mail -->
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label translate for="email_id">EMAIL</label>
                            <input
                                id="email_id"
                                type="email"
                                name="email_id"
                                #name="ngModel"
                                [(ngModel)]="traegerschaft.email"
                                email
                                [maxlength]="255"
                                class="form-control"
                                required
                            />
                            <dv-error-messages
                                [errorObject]="form.controls.email_id?.errors"
                                inputId="email_id"
                            ></dv-error-messages>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- Buttons -->
        <div class="dv-footer">
            <dv-loading-button [type]="'submit'">
                <span translate>EINLADUNG_SENDEN</span>
            </dv-loading-button>

            <button
                type="button"
                class="dv-btn cancel-button"
                (click)="cancel()"
                [textContent]="'CANCEL' | translate"
            ></button>
        </div>
    </form>
</dv-full-height-container>
