/*
 * Copyright (C) 2021 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpPendingInterceptor} from '../../shared/interceptors/http-pending/http-pending.interceptor';
import {HttpErrorInterceptorX} from '../errors/service/HttpErrorInterceptorX';
import {HttpI18nInterceptorX} from './http-i18n-Interceptor-X';
import {HttpAuthInterceptorX} from './HttpAuthInterceptorX';
import {HttpResponseInterceptorX} from './HttpResponseInterceptorX';
import {HttpVersionInterceptorX} from './HttpVersionInterceptorX';
import {XsrfInterceptor} from './XsrfInterceptor';

export const HTTP_INTERCEPTOR_PROVIDERS = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpVersionInterceptorX,
        multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpI18nInterceptorX, multi: true},
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpResponseInterceptorX,
        multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptorX, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorX, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpPendingInterceptor, multi: true}
];
