<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div
    *ngIf="currentTourStep && selectedElementRect && isOrbShowing"
    (mouseenter)="handleOrb()"
    class="tour-orb tour-{{ currentTourStep.orientation }}"
    [style.top.px]="orbTopPosition"
    [style.left.px]="orbLeftPosition"
    [style.transform]="orbTransform"
>
    <div class="tour-orb-ring"></div>
</div>
<div *ngIf="currentTourStep && !isOrbShowing">
    <div
        class="guided-tour-user-input-mask"
        (click)="backdropClick($event)"
    ></div>
    <div
        class="guided-tour-spotlight-overlay"
        [style.top.px]="overlayTop"
        [style.left.px]="overlayLeft"
        [style.height.px]="overlayHeight"
        [style.width.px]="overlayWidth"
    ></div>
</div>
<div *ngIf="currentTourStep && !isOrbShowing">
    <div
        #tourStep
        *ngIf="currentTourStep"
        class="tour-step tour-{{ currentTourStep.orientation }}"
        [ngClass]="{
            'page-tour-step': !currentTourStep.selector
        }"
        [style.top.px]="
            currentTourStep.selector && selectedElementRect ? topPosition : null
        "
        [style.left.px]="
            currentTourStep.selector && selectedElementRect
                ? leftPosition
                : null
        "
        [style.width.px]="
            currentTourStep.selector && selectedElementRect
                ? tourStepWidth
                : null
        "
        [style.transform]="
            currentTourStep.selector && selectedElementRect ? transform : null
        "
    >
        <div *ngIf="currentTourStep.selector" class="tour-arrow"></div>
        <div class="tour-block">
            <h3
                class="tour-title"
                *ngIf="currentTourStep.title && currentTourStep.selector"
            >
                {{ currentTourStep.title }}
            </h3>
            <h2
                class="tour-title"
                *ngIf="currentTourStep.title && !currentTourStep.selector"
            >
                {{ currentTourStep.title }}
            </h2>
            <div
                class="tour-content"
                [innerHTML]="currentTourStep.content"
            ></div>
            <div class="tour-buttons">
                <button
                    *ngIf="!guidedTourService.onResizeMessage"
                    (click)="guidedTourService.skipTour()"
                    class="skip-button link-button"
                >
                    {{ 'BEENDEN' | translate }}
                </button>
                <button
                    *ngIf="
                        !guidedTourService.onLastStep &&
                        !guidedTourService.onResizeMessage
                    "
                    class="next-button"
                    (click)="guidedTourService.nextStep()"
                >
                    {{ 'WEITER_ONLY' | translate }}&nbsp;&nbsp;{{
                        guidedTourService.currentTourStepDisplay
                    }}
                    /{{ guidedTourService.currentTourStepCount }}
                </button>
                <button
                    *ngIf="guidedTourService.onLastStep"
                    class="next-button"
                    (click)="guidedTourService.nextStep()"
                >
                    {{ 'FERTIG' | translate }}
                </button>
                <button
                    *ngIf="guidedTourService.onResizeMessage"
                    class="next-button"
                    (click)="guidedTourService.resetTour()"
                >
                    {{ 'ABBRECHEN' | translate }}
                </button>
                <button
                    *ngIf="
                        !guidedTourService.onFirstStep &&
                        !guidedTourService.onResizeMessage
                    "
                    class="back-button link-button"
                    (click)="guidedTourService.backStep()"
                >
                    {{ 'ZURUECK_ONLY' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
