<dv-simple-table
    [data]="history"
    [columns]="columns"
    [config]="tableConfig"
></dv-simple-table>
<div id="back-button-container">
    <button
        (click)="navigateBack()"
        type="button"
        class="dv-btn cancel-button"
        translate
    >
        ZURUECK_ONLY
    </button>
</div>
