<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div
    class="dv-switch-container"
    [ngClass]="{'dv-switch-container--disabled': disabled}"
>
    <div class="dv-switch-background-container">
        <div class="dv-switch-background">
            <p class="dv-switch-background-label">
                {{ getSwitchLabelLeft() | translate }}
            </p>
        </div>
        <div class="dv-switch-background">
            <p class="dv-switch-background-label">
                {{ getSwitchLabelRight() | translate }}
            </p>
        </div>
    </div>
    <div class="dv-switch-animation-container">
        <div
            class="dv-switch-animation"
            [@switchValue]="switchValue === switchOptionLeft ? '0' : '1'"
        >
            <p class="dv-switch-animation-label">
                {{ getActiveSwitchLabel(switchValue) | translate }}
            </p>
        </div>
    </div>
</div>
