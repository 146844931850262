import {TSRole} from '@kibon/shared/model/enums';
export enum PermissionKind {
    GUELTIGKEIT_TERMINIEREN_READ = 'GUELTIGKEIT_TERMINIEREN_READ',
    GUELTIGKEIT_TERMINIEREN_WRITE = 'GUELTIGKEIT_TERMINIEREN_WRITE'
}

export const PERMISSIONS_KIND: {[k in PermissionKind]: Array<TSRole>} = {
    GUELTIGKEIT_TERMINIEREN_WRITE: [TSRole.SUPER_ADMIN],
    GUELTIGKEIT_TERMINIEREN_READ: [
        TSRole.SUPER_ADMIN,
        TSRole.ADMIN_MANDANT,
        TSRole.SACHBEARBEITER_MANDANT,
        TSRole.ADMIN_GEMEINDE,
        TSRole.SACHBEARBEITER_GEMEINDE,
        TSRole.SACHBEARBEITER_BG,
        TSRole.ADMIN_BG,
        TSRole.ADMIN_TS,
        TSRole.SACHBEARBEITER_TS,
        TSRole.ADMIN_INSTITUTION,
        TSRole.SACHBEARBEITER_INSTITUTION,
        TSRole.ADMIN_TRAEGERSCHAFT,
        TSRole.SACHBEARBEITER_TRAEGERSCHAFT
    ]
};
