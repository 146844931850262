<!--
  ~ Copyright (C) 2020 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->
<div>
    <input
        class="dv-mat-datepicker"
        type="text"
        [name]="inputId"
        [id]="inputId"
        [matDatepicker]="dp"
        [required]="required"
        placeholder="mm.jjjj"
        (ngModelChange)="onChange()"
        [(ngModel)]="date"
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)"
        panelClass="example-month-picker"
    >
    </mat-datepicker>
    <dv-error-messages
        [inputId]="inputId"
        [errorObject]="form.controls[inputId]?.errors"
    >
    </dv-error-messages>
</div>
