<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<mat-dialog-content class="dv-dialog-content">
    <p
        class="dv-dialog-compact-text"
        id="dv-dialog-text"
        [innerHTML]="question | translate"
    ></p>
    <mat-checkbox
        id="confirmGesuchDelete"
        name="confirmGesuchDelete"
        [(ngModel)]="confirmGesuchDelete"
    >
        {{ confirmationText }}</mat-checkbox
    >
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dv-dialog-actions">
        <button
            class="ng-scope dv-btn cancel-button uppercase"
            type="button"
            (click)="close()"
            [textContent]="'CANCEL' | translate"
        ></button>
        <button
            class="dv-btn"
            ng-if="selected > 0"
            (click)="save()"
            [disabled]="!confirmGesuchDelete"
            [textContent]="'BESTAETIGEN' | translate"
        ></button>
    </div>
</mat-dialog-actions>
