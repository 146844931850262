<!--
  ~ Copyright (C) 2024 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  ~
  -->

<div class="gesuch-modul">
    <h1 class="access-for-all-title">
        {{ 'AFA_GESUCHSFORMULAR' | translate }}
    </h1>
    <div class="row col-md-12 view-title">
        <h2>{{ 'ONLINEFREIGABE_TITLE' | translate }}</h2>
        <hr class="header" />
    </div>
    <article class="row col-md-12">
        <h3>{{ 'ONLINEFREIGABE_EINWILLIGUNG_TITLE' | translate }}</h3>
        <section
            class="test"
            [innerHTML]="'ONLINEFREIGABE_EINWILLIGUNG_TEXT' | translate"
        ></section>
    </article>

    <div class="row col-md-12 margin-bottom-30">
        <section *ngIf="showReason()" class="well well-status-warten">
            {{ getReason() | translate }}
        </section>
        <form data-test="gesuchfreigeben">
            <dv-checkbox-x
                [readonly]="checkboxDisabled()"
                [displayBisher]="false"
                [(model)]="model.userConfirmedCorrectness"
                [label]="'ONLINEFREIGABE_CONFIRMATION_LABEL'"
            />
            <dv-loading-button
                type="button"
                [buttonDisabled]="freigebenButtonDisabled()"
                (buttonClick)="freigeben()"
            >
                {{ 'ONLINEFREIGABE_FREIGABE_BUTTON' | translate }}
            </dv-loading-button>
        </form>
    </div>

    <div class="row col-md-12">
        <dv-navigation-x [dvPrevious]="true" [dvNext]="true"></dv-navigation-x>
    </div>
</div>
