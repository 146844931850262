<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="form-group">
    <label class="md-no-float {{ getRequiredCssClass() }}">
        <span>{{ labelMessageKey | translate: labelMessageKeyArgs }}</span>
        <dv-tooltip
            *ngIf="tooltipMessageKey"
            [text]="tooltipMessageKey | translate: tooltipMessageKeyArgs"
        >
        </dv-tooltip>
    </label>
    <div class="{{ getWitdhCssClass() }}">
        <dv-valueinput-x
            [inputId]="inputId"
            [model]="model"
            [name]="name"
            [attr.data-test]="name"
            (modelChange)="valueHasChange($event)"
            [required]="inputRequired"
            [disabled]="inputDisabled"
            [dvOnBlur]="dvOnBlur"
            [neuerWert]="neuerWert"
            [vergleichswert]="vergleichswert"
            [vergleichswertLabel]="vergleichwertLabel"
            [showBisher]="showBisher"
            [deklaration]="deklaration"
            [korrektur]="korrektur"
            [allowNegative]="allowNegative"
        >
        </dv-valueinput-x>
    </div>
</div>
