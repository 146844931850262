<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<fieldset>
    <!-- Gemeinde -->
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group" *ngIf="editMode">
                <h3 translate for="gemeinde">GEMEINDE</h3>
                <mat-select
                    [value]="
                        stammdaten.institutionStammdatenFerieninsel.gemeinde
                    "
                    [compareWith]="compareGemeinde"
                    id="gemeinde"
                    name="gemeinde"
                    class="form-control"
                    disabled
                >
                    <mat-option
                        *ngFor="let gemeinde of gemeindeList"
                        [value]="gemeinde"
                        >{{ gemeinde.name }}</mat-option
                    >
                </mat-select>
            </div>
            <div class="form-group" *ngIf="!editMode">
                <h3 translate for="gemeinde">GEMEINDE</h3>
                <p>
                    {{
                        stammdaten.institutionStammdatenFerieninsel.gemeinde
                            .name
                    }}
                </p>
            </div>
        </div>
    </div>

    <dv-accordion
        class="gesuchsperioden-list"
        allow-multiple-sections="true"
        id="moduleListProEinstellungen"
    >
        <ng-container
            *ngFor="
                let einstellungGP of stammdaten.institutionStammdatenFerieninsel
                    .einstellungenFerieninsel;
                trackBy: trackById;
                let i = index
            "
        >
            <dv-accordion-tab
                [tabid]="i"
                *ngIf="
                    showGesuchsperiode(einstellungGP.gesuchsperiode.gueltigkeit)
                "
            >
                <tab-title>
                    <div class="message-header">
                        <p class="fa fa-angle-right"></p>
                        {{ einstellungGP.gesuchsperiode.gesuchsperiodeString }}
                    </div>
                </tab-title>
                <tab-body>
                    <div class="title row">
                        <h3 translate>INSTITUTION_ST_AUSWEICHSTANDORT</h3>
                    </div>

                    <fieldset>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="md-no-float"
                                        translate="HERBSTFERIEN"
                                        for="ausweichstandortHerbstferien-{{
                                            einstellungGP?.id
                                        }}"
                                    >
                                    </label>
                                    <input
                                        id="ausweichstandortHerbstferien-{{
                                            einstellungGP?.id
                                        }}"
                                        type="text"
                                        name="ausweichstandortHerbstferien-{{
                                            einstellungGP?.id
                                        }}"
                                        [(ngModel)]="
                                            einstellungGP.ausweichstandortHerbstferien
                                        "
                                        [maxlength]="255"
                                        [disabled]="!editMode"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="md-no-float"
                                        translate="SPORTFERIEN"
                                        for="ausweichstandortSportferien-{{
                                            einstellungGP?.id
                                        }}"
                                    >
                                    </label>
                                    <input
                                        id="ausweichstandortSportferien-{{
                                            einstellungGP?.id
                                        }}"
                                        type="text"
                                        name="ausweichstandortSportferien-{{
                                            einstellungGP?.id
                                        }}"
                                        [(ngModel)]="
                                            einstellungGP.ausweichstandortSportferien
                                        "
                                        [maxlength]="255"
                                        [disabled]="!editMode"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="md-no-float"
                                        translate="FRUEHLINGSFERIEN"
                                        for="ausweichstandortFruehlingsferien-{{
                                            einstellungGP?.id
                                        }}"
                                    >
                                    </label>
                                    <input
                                        id="ausweichstandortFruehlingsferien-{{
                                            einstellungGP?.id
                                        }}"
                                        type="text"
                                        name="ausweichstandortFruehlingsferien-{{
                                            einstellungGP?.id
                                        }}"
                                        [(ngModel)]="
                                            einstellungGP.ausweichstandortFruehlingsferien
                                        "
                                        [maxlength]="255"
                                        [disabled]="!editMode"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="md-no-float"
                                        translate="SOMMERFERIEN"
                                        for="ausweichstandortSommerferien-{{
                                            einstellungGP?.id
                                        }}"
                                    >
                                    </label>
                                    <input
                                        id="ausweichstandortSommerferien-{{
                                            einstellungGP?.id
                                        }}"
                                        type="text"
                                        name="ausweichstandortSommerferien-{{
                                            einstellungGP?.id
                                        }}"
                                        [(ngModel)]="
                                            einstellungGP.ausweichstandortSommerferien
                                        "
                                        [maxlength]="255"
                                        [disabled]="!editMode"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </tab-body>
            </dv-accordion-tab>
        </ng-container>
    </dv-accordion>
</fieldset>
