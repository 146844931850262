/*
 * Copyright (C) 2018 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

/* eslint-disable max-len */
export enum TSEinstellungKey {
    GEMEINDE_KONTINGENTIERUNG_ENABLED = 'GEMEINDE_KONTINGENTIERUNG_ENABLED',
    GEMEINDE_BG_BIS_UND_MIT_SCHULSTUFE = 'GEMEINDE_BG_BIS_UND_MIT_SCHULSTUFE',
    ANGEBOT_SCHULSTUFE = 'ANGEBOT_SCHULSTUFE',
    GEMEINDE_FERIENINSEL_ANMELDUNGEN_DATUM_AB = 'GEMEINDE_FERIENINSEL_ANMELDUNGEN_DATUM_AB',
    GEMEINDE_TAGESSCHULE_ANMELDUNGEN_DATUM_AB = 'GEMEINDE_TAGESSCHULE_ANMELDUNGEN_DATUM_AB',
    GEMEINDE_TAGESSCHULE_ERSTER_SCHULTAG = 'GEMEINDE_TAGESSCHULE_ERSTER_SCHULTAG',
    GEMEINDE_TAGESSCHULE_TAGIS_ENABLED = 'GEMEINDE_TAGESSCHULE_TAGIS_ENABLED',
    GEMEINDE_TAGESSCHULE_ZUSAETZLICHE_ANGABEN_ZUR_ANMELDUNG = 'GEMEINDE_TAGESSCHULE_ZUSAETZLICHE_ANGABEN_ZUR_ANMELDUNG',
    GEMEINDE_ZUSAETZLICHER_BABYBEITRAG_ENABLED = 'GEMEINDE_ZUSAETZLICHER_BABYBEITRAG_ENABLED',
    GEMEINDE_ZUSAETZLICHER_BABYBEITRAG_BETRAG_KITA = 'GEMEINDE_ZUSAETZLICHER_BABYBEITRAG_BETRAG_KITA',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_BIS_UND_MIT_SCHULSTUFE_KITA = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_BIS_UND_MIT_SCHULSTUFE_KITA',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_BIS_UND_MIT_SCHULSTUFE_TFO = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_BIS_UND_MIT_SCHULSTUFE_TFO',
    GEMEINDE_ZUSAETZLICHER_ANSPRUCH_FREIWILLIGENARBEIT_ENABLED = 'GEMEINDE_ZUSAETZLICHER_ANSPRUCH_FREIWILLIGENARBEIT_ENABLED',
    GEMEINDE_ZUSAETZLICHER_BABYBEITRAG_BETRAG_TFO = 'GEMEINDE_ZUSAETZLICHER_BABYBEITRAG_BETRAG_TFO',
    GEMEINDE_ZUSAETZLICHER_ANSPRUCH_FREIWILLIGENARBEIT_MAXPROZENT = 'GEMEINDE_ZUSAETZLICHER_ANSPRUCH_FREIWILLIGENARBEIT_MAXPROZENT',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_ENABLED = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_ENABLED',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_TYP = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_TYP',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_BETRAG_KITA = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_BETRAG_KITA',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_BETRAG_TFO = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_BETRAG_TFO',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_LINEAR_KITA_MAX = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_LINEAR_KITA_MAX',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_LINEAR_TFO_MAX = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_LINEAR_TFO_MAX',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_MIN_MASSGEBENDES_EINKOMMEN = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_MIN_MASSGEBENDES_EINKOMMEN',
    GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_MAX_MASSGEBENDES_EINKOMMEN = 'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_MAX_MASSGEBENDES_EINKOMMEN',
    GEMEINDE_MAHLZEITENVERGUENSTIGUNG_ENABLED = 'GEMEINDE_MAHLZEITENVERGUENSTIGUNG_ENABLED',
    GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_1_VERGUENSTIGUNG_MAHLZEIT = 'GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_1_VERGUENSTIGUNG_MAHLZEIT',
    GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_1_MAX_EINKOMMEN = 'GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_1_MAX_EINKOMMEN',
    GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_2_VERGUENSTIGUNG_MAHLZEIT = 'GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_2_VERGUENSTIGUNG_MAHLZEIT',
    GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_2_MAX_EINKOMMEN = 'GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_2_MAX_EINKOMMEN',
    GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_3_VERGUENSTIGUNG_MAHLZEIT = 'GEMEINDE_MAHLZEITENVERGUENSTIGUNG_EINKOMMENSSTUFE_3_VERGUENSTIGUNG_MAHLZEIT',
    GEMEINDE_MAHLZEITENVERGUENSTIGUNG_FUER_SOZIALHILFEBEZUEGER_ENABLED = 'GEMEINDE_MAHLZEITENVERGUENSTIGUNG_FUER_SOZIALHILFEBEZUEGER_ENABLED',
    GEMEINDE_MAHLZEITENVERGUENSTIGUNG_MINIMALER_ELTERNBEITRAG_MAHLZEIT = 'GEMEINDE_MAHLZEITENVERGUENSTIGUNG_MINIMALER_ELTERNBEITRAG_MAHLZEIT',
    GEMEINDE_MIN_ERWERBSPENSUM_NICHT_EINGESCHULT = 'GEMEINDE_MIN_ERWERBSPENSUM_NICHT_EINGESCHULT',
    GEMEINDE_MIN_ERWERBSPENSUM_EINGESCHULT = 'GEMEINDE_MIN_ERWERBSPENSUM_EINGESCHULT',
    GEMEINDE_SCHNITTSTELLE_KITAX_ENABLED = 'GEMEINDE_SCHNITTSTELLE_KITAX_ENABLED',
    GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_AKTIVIERT = 'GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_AKTIVIERT',
    GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_BETRAG_KITA = 'GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_BETRAG_KITA',
    GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_BETRAG_TFO = 'GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_BETRAG_TFO',
    GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_BETRAG_TFO_AB_PRIMARSCHULE = 'GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_BETRAG_TFO_AB_PRIMARSCHULE',
    GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_MAX_MASSGEBENDEN_EINKOMMEN_FUER_BERECHNUNG = 'GEMEINDE_PAUSCHALBETRAG_HOHE_EINKOMMENSKLASSEN_MAX_MASSGEBENDEN_EINKOMMEN_FUER_BERECHNUNG',
    GEMEINDE_KEIN_GUTSCHEIN_FUER_SOZIALHILFE_EMPFAENGER = 'GEMEINDE_KEIN_GUTSCHEIN_FUER_SOZIALHILFE_EMPFAENGER',
    MAX_VERGUENSTIGUNG_VORSCHULE_BABY_PRO_TG = 'MAX_VERGUENSTIGUNG_VORSCHULE_BABY_PRO_TG',
    MAX_VERGUENSTIGUNG_VORSCHULE_KIND_PRO_TG = 'MAX_VERGUENSTIGUNG_VORSCHULE_KIND_PRO_TG',
    MAX_VERGUENSTIGUNG_KINDERGARTEN_PRO_TG = 'MAX_VERGUENSTIGUNG_KINDERGARTEN_PRO_TG',
    MAX_VERGUENSTIGUNG_VORSCHULE_BABY_PRO_STD = 'MAX_VERGUENSTIGUNG_VORSCHULE_BABY_PRO_STD',
    MAX_VERGUENSTIGUNG_VORSCHULE_KIND_PRO_STD = 'MAX_VERGUENSTIGUNG_VORSCHULE_KIND_PRO_STD',
    MAX_VERGUENSTIGUNG_KINDERGARTEN_PRO_STD = 'MAX_VERGUENSTIGUNG_KINDERGARTEN_PRO_STD',
    MAX_VERGUENSTIGUNG_PRIMAR_PRO_STD = 'MAX_VERGUENSTIGUNG_PRIMAR_PRO_STD',
    MIN_MASSGEBENDES_EINKOMMEN = 'MIN_MASSGEBENDES_EINKOMMEN',
    MAX_MASSGEBENDES_EINKOMMEN = 'MAX_MASSGEBENDES_EINKOMMEN',
    OEFFNUNGSTAGE_KITA = 'OEFFNUNGSTAGE_KITA',
    OEFFNUNGSTAGE_TFO = 'OEFFNUNGSTAGE_TFO',
    OEFFNUNGSSTUNDEN_TFO = 'OEFFNUNGSSTUNDEN_TFO',
    ZUSCHLAG_BEHINDERUNG_PRO_TG = 'ZUSCHLAG_BEHINDERUNG_PRO_TG',
    ZUSCHLAG_BEHINDERUNG_PRO_STD = 'ZUSCHLAG_BEHINDERUNG_PRO_STD',
    MIN_VERGUENSTIGUNG_PRO_TG = 'MIN_VERGUENSTIGUNG_PRO_TG',
    MIN_VERGUENSTIGUNG_PRO_STD = 'MIN_VERGUENSTIGUNG_PRO_STD',
    PARAM_PENSUM_KITA_MIN = 'PARAM_PENSUM_KITA_MIN',
    PARAM_PENSUM_TAGESELTERN_MIN = 'PARAM_PENSUM_TAGESELTERN_MIN',
    PARAM_PAUSCHALABZUG_PRO_PERSON_FAMILIENGROESSE_3 = 'PARAM_PAUSCHALABZUG_PRO_PERSON_FAMILIENGROESSE_3',
    PARAM_PAUSCHALABZUG_PRO_PERSON_FAMILIENGROESSE_4 = 'PARAM_PAUSCHALABZUG_PRO_PERSON_FAMILIENGROESSE_4',
    PARAM_PAUSCHALABZUG_PRO_PERSON_FAMILIENGROESSE_5 = 'PARAM_PAUSCHALABZUG_PRO_PERSON_FAMILIENGROESSE_5',
    PARAM_PAUSCHALABZUG_PRO_PERSON_FAMILIENGROESSE_6 = 'PARAM_PAUSCHALABZUG_PRO_PERSON_FAMILIENGROESSE_6',
    PARAM_MAX_TAGE_ABWESENHEIT = 'PARAM_MAX_TAGE_ABWESENHEIT',
    FACHSTELLE_MIN_PENSUM_SOZIALE_INTEGRATION = 'FACHSTELLE_MIN_PENSUM_SOZIALE_INTEGRATION',
    FACHSTELLE_MAX_PENSUM_SOZIALE_INTEGRATION = 'FACHSTELLE_MAX_PENSUM_SOZIALE_INTEGRATION',
    FACHSTELLE_MIN_PENSUM_SPRACHLICHE_INTEGRATION = 'FACHSTELLE_MIN_PENSUM_SPRACHLICHE_INTEGRATION',
    FACHSTELLE_MAX_PENSUM_SPRACHLICHE_INTEGRATION = 'FACHSTELLE_MAX_PENSUM_SPRACHLICHE_INTEGRATION',
    ERWERBSPENSUM_ZUSCHLAG = 'ERWERBSPENSUM_ZUSCHLAG',
    LATS_LOHNNORMKOSTEN = 'LATS_LOHNNORMKOSTEN',
    LATS_LOHNNORMKOSTEN_LESS_THAN_50 = 'LATS_LOHNNORMKOSTEN_LESS_THAN_50',
    LATS_STICHTAG = 'LATS_STICHTAG',
    EINGEWOEHNUNG_TYP = 'EINGEWOEHNUNG_TYP',
    SCHNITTSTELLE_STEUERN_AKTIV = 'SCHNITTSTELLE_STEUERN_AKTIV',
    FKJV_EINKOMMENSVERSCHLECHTERUNG_BIS_CHF = 'FKJV_EINKOMMENSVERSCHLECHTERUNG_BIS_CHF',
    FERIENBETREUUNG_CHF_PAUSCHALBETRAG = 'FERIENBETREUUNG_CHF_PAUSCHALBETRAG',
    FERIENBETREUUNG_CHF_PAUSCHALBETRAG_SONDERSCHUELER = 'FERIENBETREUUNG_CHF_PAUSCHALBETRAG_SONDERSCHUELER',
    FINANZIELLE_SITUATION_TYP = 'FINANZIELLE_SITUATION_TYP',
    KITAPLUS_ZUSCHLAG_AKTIVIERT = 'KITAPLUS_ZUSCHLAG_AKTIVIERT',
    ABHAENGIGKEIT_ANSPRUCH_BESCHAEFTIGUNGPENSUM = 'ABHAENGIGKEIT_ANSPRUCH_BESCHAEFTIGUNGPENSUM',
    FKJV_FAMILIENSITUATION_NEU = 'FKJV_FAMILIENSITUATION_NEU',
    MINIMALDAUER_KONKUBINAT = 'MINIMALDAUER_KONKUBINAT',
    GEMEINDESPEZIFISCHE_BG_KONFIGURATIONEN = 'GEMEINDESPEZIFISCHE_BG_KONFIGURATIONEN',
    KINDERABZUG_TYP = 'KINDERABZUG_TYP',
    KESB_PLATZIERUNG_DEAKTIVIEREN = 'KESB_PLATZIERUNG_DEAKTIVIEREN',
    BESONDERE_BEDUERFNISSE_LUZERN = 'BESONDERE_BEDUERFNISSE_LUZERN',
    FKJV_MAX_PENSUM_AUSSERORDENTLICHER_ANSPRUCH = 'FKJV_MAX_PENSUM_AUSSERORDENTLICHER_ANSPRUCH',
    AUSSERORDENTLICHER_ANSPRUCH_RULE = 'AUSSERORDENTLICHER_ANSPRUCH_RULE',
    DAUER_BABYTARIF = 'DAUER_BABYTARIF',
    FKJV_TEXTE = 'FKJV_TEXTE',
    DIPLOMATENSTATUS_DEAKTIVIERT = 'DIPLOMATENSTATUS_DEAKTIVIERT',
    SPRACHE_AMTSPRACHE_DISABLED = 'SPRACHE_AMTSPRACHE_DISABLED',
    ZEMIS_DISABLED = 'ZEMIS_DISABLED',
    FREIGABE_QUITTUNG_EINLESEN_REQUIRED = 'FREIGABE_QUITTUNG_EINLESEN_REQUIRED',
    UNBEZAHLTER_URLAUB_AKTIV = 'UNBEZAHLTER_URLAUB_AKTIV',
    FACHSTELLEN_TYP = 'FACHSTELLEN_TYP',
    AUSWEIS_NACHWEIS_REQUIRED = 'AUSWEIS_NACHWEIS_REQUIRED',
    PENSUM_ANZEIGE_TYP = 'PENSUM_ANZEIGE_TYP',
    VERFUEGUNG_EINGESCHRIEBEN_VERSENDEN_AKTIVIERT = 'VERFUEGUNG_EINGESCHRIEBEN_VERSENDEN_AKTIVIERT',
    ABWESENHEIT_AKTIV = 'ABWESENHEIT_AKTIV',
    BEGRUENDUNG_MUTATION_AKTIVIERT = 'BEGRUENDUNG_MUTATION_AKTIVIERT',
    VERFUEGUNG_EXPORT_ENABLED = 'VERFUEGUNG_EXPORT_ENABLED',
    ZAHLUNGSANGABEN_ANTRAGSTELLER_REQUIRED = 'ZAHLUNGSANGABEN_ANTRAGSTELLER_REQUIRED',
    VERANLAGUNG_MIN_UNTERSCHIED_MASSGEBENDESEINK = 'VERANLAGUNG_MIN_UNTERSCHIED_MASSGEBENDESEINK',
    ANSPRUCH_AB_X_MONATEN = 'ANSPRUCH_AB_X_MONATEN',
    PARAM_GRENZWERT_EINKOMMENSVERSCHLECHTERUNG = 'PARAM_GRENZWERT_EINKOMMENSVERSCHLECHTERUNG',
    KITA_STUNDEN_PRO_TAG = 'KITA_STUNDEN_PRO_TAG',
    FKJV_SOZIALE_INTEGRATION_BIS_SCHULSTUFE = 'FKJV_SOZIALE_INTEGRATION_BIS_SCHULSTUFE',
    SPRACHLICHE_INTEGRATION_BIS_SCHULSTUFE = 'SPRACHLICHE_INTEGRATION_BIS_SCHULSTUFE',
    ZUSATZLICHE_FELDER_ERSATZEINKOMMEN = 'ZUSATZLICHE_FELDER_ERSATZEINKOMMEN',
    SPRACHFOERDERUNG_BESTAETIGEN = 'SPRACHFOERDERUNG_BESTAETIGEN',
    GESUCH_BEENDEN_BEI_TAUSCH_GS2 = 'GESUCH_BEENDEN_BEI_TAUSCH_GS2',
    SCHULERGAENZENDE_BETREUUNGEN = 'SCHULERGAENZENDE_BETREUUNGEN',
    GESUCHFREIGABE_ONLINE = 'GESUCHFREIGABE_ONLINE',
    ANWESENHEITSTAGE_PRO_MONAT_AKTIVIERT = 'ANWESENHEITSTAGE_PRO_MONAT_AKTIVIERT',
    WEGZEIT_ERWERBSPENSUM = 'WEGZEIT_ERWERBSPENSUM',
    ERWEITERTE_BEDUERFNISSE_AKTIV = 'ERWEITERTE_BEDUERFNISSE_AKTIV',
    SOZIALVERSICHERUNGSNUMMER_PERIODE = 'SOZIALVERSICHERUNGSNUMMER_PERIODE',
    HOEHERE_BEITRAEGE_BEEINTRAECHTIGUNG_AKTIVIERT = 'HOEHERE_BEITRAEGE_BEEINTRAECHTIGUNG_AKTIVIERT',
    ANSPRUCH_MONATSWEISE = 'ANSPRUCH_MONATSWEISE',
    FKJV_MAX_DIFFERENZ_BESCHAEFTIGUNGSPENSUM = 'FKJV_MAX_DIFFERENZ_BESCHAEFTIGUNGSPENSUM',
    FKJV_PAUSCHALE_BEI_ANSPRUCH = 'FKJV_PAUSCHALE_BEI_ANSPRUCH',
    GESCHWISTERNBONUS_TYP = 'GESCHWISTERNBONUS_TYP',
    MAX_TARIF_MIT_PAEDAGOGISCHER_BETREUUNG = 'MAX_TARIF_MIT_PAEDAGOGISCHER_BETREUUNG',
    MAX_TARIF_OHNE_PAEDAGOGISCHER_BETREUUNG = 'MAX_TARIF_OHNE_PAEDAGOGISCHER_BETREUUNG',
    PARAM_PENSUM_TAGESSCHULE_MIN = 'PARAM_PENSUM_TAGESSCHULE_MIN',
    MIN_TARIF = 'MIN_TARIF',
    MIN_ERWERBSPENSUM_EINGESCHULT = 'MIN_ERWERBSPENSUM_EINGESCHULT',
    MIN_ERWERBSPENSUM_NICHT_EINGESCHULT = 'MIN_ERWERBSPENSUM_NICHT_EINGESCHULT',
    FKJV_PAUSCHALE_RUECKWIRKEND = 'FKJV_PAUSCHALE_RUECKWIRKEND'
}

export function getGemeindspezifischeBGConfigKeys(): Array<TSEinstellungKey> {
    return [
        TSEinstellungKey.MIN_MASSGEBENDES_EINKOMMEN,
        TSEinstellungKey.MAX_MASSGEBENDES_EINKOMMEN,
        TSEinstellungKey.MAX_VERGUENSTIGUNG_VORSCHULE_BABY_PRO_TG,
        TSEinstellungKey.MAX_VERGUENSTIGUNG_VORSCHULE_BABY_PRO_STD,
        TSEinstellungKey.MAX_VERGUENSTIGUNG_VORSCHULE_KIND_PRO_TG,
        TSEinstellungKey.MAX_VERGUENSTIGUNG_VORSCHULE_KIND_PRO_STD,
        TSEinstellungKey.MAX_VERGUENSTIGUNG_KINDERGARTEN_PRO_TG,
        TSEinstellungKey.MAX_VERGUENSTIGUNG_KINDERGARTEN_PRO_STD,
        TSEinstellungKey.MAX_VERGUENSTIGUNG_PRIMAR_PRO_STD,
        TSEinstellungKey.ZUSCHLAG_BEHINDERUNG_PRO_TG,
        TSEinstellungKey.ZUSCHLAG_BEHINDERUNG_PRO_STD,
        TSEinstellungKey.MIN_VERGUENSTIGUNG_PRO_TG,
        TSEinstellungKey.MIN_VERGUENSTIGUNG_PRO_STD
    ];
}
