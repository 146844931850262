<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2017 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->

<div class="batchjob-trigger-view batchjobTriggerModul">
    <div class="row">
        <div class="col-md-8 col-md-offset-2">
            <!-- Batch Jobs -->
            <h2 class="margin-top-40">Batch Jobs manuell ausführen</h2>
            <div class="row">
                <div class="col-md-12 margin-top-20">
                    <div>
                        Clean Download Files ausführen:<br />
                        Löscht alle DownloadFiles, deren Tokenlifespan
                        abgelaufen ist. Ausserdem werden alle phyischen Files im
                        Reports-Folder gelöscht, deren Tokenlifespan abgelaufen
                        ist
                    </div>
                    <button
                        dvNgDebounceClick
                        (debounceClick)="runBatchCleanDownloadFiles()"
                        class="dv-btn margin-top-20"
                    >
                        Batchjob ausführen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 margin-top-20">
                    <div>Mahnung Prüfung ausführen</div>
                    <button
                        dvNgDebounceClick
                        (debounceClick)="runBatchMahnungFristablauf()"
                        class="dv-btn margin-top-20"
                    >
                        Batchjob ausführen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 margin-top-20">
                    <div>
                        Gemeinden für Institutionsstammdaten aktualisieren
                    </div>
                    <button
                        dvNgDebounceClick
                        (debounceClick)="
                            runBatchUpdateGemeindeForBGInstitutionen()
                        "
                        class="dv-btn margin-top-20"
                    >
                        Batchjob ausführen
                    </button>
                </div>
            </div>

            <!-- Manuelle Skripts ausfuehren -->
            <h2 class="margin-top-40">Manuelle Skripts ausführen</h2>
            <div class="row">
                <div class="col-md-12 margin-top-20">
                    <div>Noch kein Skript zur Auswahl</div>
                </div>
            </div>
        </div>
    </div>
</div>
