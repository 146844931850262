<!--
  ~ Copyright (C) 2020 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<mat-dialog-content class="dv-dialog-content">
    <div>
        <h2 [textContent]="'MITTEILUNG_WEITERLEITEN' | translate"></h2>
    </div>

    <form class="row">
        <mat-form-field class="col-md-12">
            <input
                type="text"
                placeholder="{{ 'AN_COLON' | translate }}"
                [attr.aria-label]="'GS_DOKUMENT_HOCHLADEN' | translate"
                matInput
                [formControl]="myControl"
                [matAutocomplete]="auto"
            />
            <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="getBenutzerFullName"
                (optionSelected)="updateMySelection($event.option.value)"
            >
                <mat-option
                    *ngFor="let benutzer of this.filteredBenutzerList$ | async"
                    [value]="benutzer"
                >
                    <dv-benutzer-entry
                        [benutzer]="benutzer"
                    ></dv-benutzer-entry>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dv-dialog-actions">
        <button
            class="dv-btn"
            (click)="close()"
            [textContent]="'ABBRECHEN' | translate"
        ></button>
        <button
            class="dv-btn"
            (click)="save()"
            [textContent]="'MITTEILUNG_WEITERLEITEN' | translate"
            [disabled]="selectedBenutzer === null"
        ></button>
    </div>
</mat-dialog-actions>
