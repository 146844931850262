<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<fieldset>
    <div class="form-group">
        <div class="row">
            <div class="col-sm-12 dv-input-container-medium">
                <!--                IBAN-->
                <div class="form-group">
                    <label class="required long-label" for="iban_id">
                        {{
                            'IBAN_FUER_AUSZAHLUNG_GUTSCHEINE' | translate
                        }}</label
                    >
                    <input
                        id="iban_id"
                        type="text"
                        name="iban"
                        #iban="ngModel"
                        [(ngModel)]="model.zahlungsinformationen.iban"
                        class="form-control"
                        isNotQrIbanN
                        [disabled]="readonly"
                        required
                        ngxIban
                        data-test="iban"
                    />
                    <dv-error-messages
                        [errorObject]="iban.errors"
                        inputId="iban_id"
                    >
                    </dv-error-messages>
                    <dv-bisher-x
                        [showBisher]="isKorrekturModusOrFreigegeben()"
                        [deklaration]="model.zahlungsinformationenGS?.iban"
                        [korrektur]="model.zahlungsinformationen.iban"
                    ></dv-bisher-x>
                </div>
            </div>
        </div>
        <div class="row">
            <!--        LAUTEND AUF-->
            <div class="col-sm-12 dv-input-container-medium">
                <div class="form-group">
                    <label class="required long-label" for="kontoinhaber_id">
                        {{
                            'MAHLZEITENVERGUENSTIGUNG_KONTOINHABER' | translate
                        }}</label
                    >
                    <input
                        id="kontoinhaber_id"
                        type="text"
                        name="kontoinhaber"
                        #kontoinhaber="ngModel"
                        [(ngModel)]="model.zahlungsinformationen.kontoinhaber"
                        class="form-control"
                        [disabled]="readonly"
                        required
                        data-test="kontoinhaber"
                    />
                    <dv-error-messages
                        [errorObject]="kontoinhaber.errors"
                        inputId="kontoinhaber_id"
                    >
                    </dv-error-messages>
                    <dv-bisher-x
                        [showBisher]="isKorrekturModusOrFreigegeben()"
                        [deklaration]="
                            model.zahlungsinformationenGS?.kontoinhaber
                        "
                        [korrektur]="model.zahlungsinformationen.kontoinhaber"
                    ></dv-bisher-x>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 dv-input-container-medium">
                <div class="checkbox">
                    <mat-checkbox
                        [(ngModel)]="
                            model.zahlungsinformationen
                                .abweichendeZahlungsadresse
                        "
                        name="abweichendeZahlungsadresse"
                        id="abweichendeZahlungsadresse"
                        [aria-label]="
                            'INFOMA_ABWEICHENDE_ZAHLUNGSADRESSE' | translate
                        "
                        (change)="abweichendeZahlungsadresseChanged()"
                    >
                        <span translate
                            >INFOMA_ABWEICHENDE_ZAHLUNGSADRESSE</span
                        >
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div *ngIf="model.zahlungsinformationen.abweichendeZahlungsadresse">
            <div class="dv-adresse row">
                <!--Strasse-->
                <div class="col-sm-12 dv-input-container-small">
                    <dv-input-container class="form-group">
                        <label
                            class="md-no-float required"
                            for="adresseStrasse"
                            >{{ 'ADRESSE_STRASSE' | translate }}</label
                        >
                        <input
                            id="adresseStrasse"
                            type="text"
                            name="adresseStrasse"
                            [(ngModel)]="
                                model.zahlungsinformationen.zahlungsadresse
                                    .strasse
                            "
                            dv-max-length
                            class="form-control"
                            required
                        />
                        <dv-bisher-x
                            [showBisher]="isKorrekturModusOrFreigegeben()"
                            [deklaration]="
                                model.zahlungsinformationenGS?.zahlungsadresse
                                    ?.strasse
                            "
                            [korrektur]="
                                model.zahlungsinformationen.zahlungsadresse
                                    .strasse
                            "
                        ></dv-bisher-x>
                        <dv-error-messages
                            for="adresseForm.adresseStrasse.$error"
                            class="error"
                        ></dv-error-messages>
                    </dv-input-container>
                </div>
                <div class="col-sm-12 dv-input-container-small">
                    <dv-input-container class="form-group secondColumn">
                        <label class="md-no-float" for="adresseHausnummer">{{
                            'ADRESSE_HAUSNUMMER' | translate
                        }}</label>
                        <input
                            id="adresseHausnummer"
                            type="text"
                            name="adresseHausnummer"
                            [(ngModel)]="
                                model.zahlungsinformationen.zahlungsadresse
                                    .hausnummer
                            "
                            dv-max-length
                            class="form-control"
                        />
                        <dv-bisher-x
                            [showBisher]="isKorrekturModusOrFreigegeben()"
                            [deklaration]="
                                model.zahlungsinformationenGS?.zahlungsadresse
                                    ?.hausnummer
                            "
                            [korrektur]="
                                model.zahlungsinformationen.zahlungsadresse
                                    .hausnummer
                            "
                        ></dv-bisher-x>
                        <dv-error-messages
                            for="adresseForm.adresseHausnummer.$error"
                            class="error"
                        ></dv-error-messages>
                    </dv-input-container>
                </div>
            </div>
            <!-- Adresse Zusatz-->
            <div class="row">
                <div class="col-sm-12 dv-input-container-medium">
                    <dv-input-container class="form-group">
                        <label class="md-no-float" for="adresseZusatzzeile">{{
                            'ADRESSE_ZUSATZZEILE' | translate
                        }}</label>
                        <input
                            id="adresseZusatzzeile"
                            type="text"
                            name="adresseZusatzzeile"
                            [(ngModel)]="
                                model.zahlungsinformationen.zahlungsadresse
                                    .zusatzzeile
                            "
                            dv-max-length
                            class="form-control"
                        />
                        <dv-bisher-x
                            [showBisher]="isKorrekturModusOrFreigegeben()"
                            [deklaration]="
                                model.zahlungsinformationenGS?.zahlungsadresse
                                    ?.zusatzzeile
                            "
                            [korrektur]="
                                model.zahlungsinformationen.zahlungsadresse
                                    .zusatzzeile
                            "
                        ></dv-bisher-x>
                        <dv-error-messages
                            for="adresseForm.adresseZusatzzeile.$error"
                            class="error"
                        ></dv-error-messages>
                    </dv-input-container>
                </div>
            </div>
            <!-- PLZ ORT-->
            <div class="row">
                <div class="col-sm-12 dv-input-container-small">
                    <dv-input-container class="form-group">
                        <label class="md-no-float required" for="adressePlz"
                            >{{ 'ADRESSE_PLZ' | translate }}
                        </label>
                        <input
                            id="adressePlz"
                            type="text"
                            name="adressePlz"
                            [(ngModel)]="
                                model.zahlungsinformationen.zahlungsadresse.plz
                            "
                            dv-max-length
                            class="form-control"
                            required
                        />
                        <dv-bisher-x
                            [showBisher]="isKorrekturModusOrFreigegeben()"
                            [deklaration]="
                                model.zahlungsinformationenGS?.zahlungsadresse
                                    ?.plz
                            "
                            [korrektur]="
                                model.zahlungsinformationen.zahlungsadresse.plz
                            "
                        ></dv-bisher-x>
                        <dv-error-messages
                            for="adresseForm.adressePlz.$error"
                            class="error"
                        ></dv-error-messages>
                    </dv-input-container>
                </div>
                <div class="col-sm-12 dv-input-container-small">
                    <dv-input-container class="form-group secondColumn">
                        <label class="md-no-float required" for="adresseOrt">{{
                            'ADRESSE_ORT' | translate
                        }}</label>
                        <input
                            id="adresseOrt"
                            type="text"
                            name="adresseOrt"
                            [(ngModel)]="
                                model.zahlungsinformationen.zahlungsadresse.ort
                            "
                            dv-max-length
                            class="form-control"
                            required
                        />
                        <dv-bisher-x
                            [showBisher]="isKorrekturModusOrFreigegeben()"
                            [deklaration]="
                                model.zahlungsinformationenGS?.zahlungsadresse
                                    ?.ort
                            "
                            [korrektur]="
                                model.zahlungsinformationen.zahlungsadresse.ort
                            "
                        ></dv-bisher-x>
                        <dv-error-messages
                            for="adresseForm.adresseOrt.$error"
                            class="error"
                        ></dv-error-messages>
                    </dv-input-container>
                </div>
            </div>

            <!-- Land-->
            <div class="row">
                <div class="col-sm-12 dv-input-container-small">
                    <div class="form-group">
                        <label class="md-no-float" for="adresseLand"
                            >{{ 'ADRESSE_LAND' | translate }}
                        </label>
                        <div class="dv-select-style">
                            <select
                                name="adresseLand"
                                id="adresseLand"
                                [(ngModel)]="
                                    model.zahlungsinformationen.zahlungsadresse
                                        .land
                                "
                                class="form-control"
                                required
                            >
                                <option
                                    *ngFor="let land of laenderList"
                                    [value]="land.code"
                                >
                                    {{ land.translationKey | translate }}
                                </option>
                            </select>
                            <dv-bisher-x
                                [showBisher]="isKorrekturModusOrFreigegeben()"
                                [deklaration]="
                                    model.zahlungsinformationenGS
                                        ?.zahlungsadresse?.land
                                "
                                [korrektur]="
                                    model.zahlungsinformationen.zahlungsadresse
                                        .land
                                "
                            ></dv-bisher-x>
                            <dv-error-messages
                                for="adresseForm.adresseLand.$error"
                                class="error"
                            ></dv-error-messages>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container
            *ngIf="
                isGemeindeOrMandant() && isAtLeastFreigegeben() && infomaAktiv
            "
        >
            <div class="row">
                <!--            INFOMA KREDITORENNUMMER-->
                <div class="col-sm-12 dv-input-container-medium">
                    <div class="form-group">
                        <label class="required long-label" for="infoma_kred_id">
                            {{ 'INFOMA_KREDITORENNUMMER' | translate }}</label
                        >
                        <input
                            id="infoma_kred_id"
                            type="text"
                            name="infoma_kred"
                            #infoma_kred="ngModel"
                            [(ngModel)]="
                                model.zahlungsinformationen
                                    .infomaKreditorennummer
                            "
                            class="form-control"
                            [disabled]="readonly"
                            required
                            data-test="infoma_kred"
                        />
                        <dv-error-messages
                            [errorObject]="infoma_kred.errors"
                            inputId="infoma_kred_id"
                        >
                        </dv-error-messages>
                    </div>
                </div>
            </div>
            <div class="row">
                <!--            INFOMA BANKCODE-->
                <div class="col-sm-12 dv-input-container-medium">
                    <div class="form-group">
                        <label
                            class="required long-label"
                            for="infoma_bankcode_id"
                        >
                            {{ 'INFOMA_BANKCODE' | translate }}</label
                        >
                        <input
                            id="infoma_bankcode_id"
                            type="text"
                            name="infoma_bankcode"
                            #infoma_bankcode="ngModel"
                            [(ngModel)]="
                                model.zahlungsinformationen.infomaBankcode
                            "
                            class="form-control"
                            [disabled]="readonly"
                            required
                            data-test="infoma_bankcode"
                        />
                        <dv-error-messages
                            [errorObject]="infoma_bankcode.errors"
                            inputId="infoma_bankcode_id"
                        >
                        </dv-error-messages>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</fieldset>
