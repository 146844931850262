<div
    class="dv-table dv-simple-table margin-bottom-20"
    *ngIf="datasource && internePendenzen?.length"
>
    <div class="mat-elevation-z0">
        <mat-table
            matSort
            [dataSource]="datasource"
            (matSortChange)="sortData($event)"
            [matSortActive]="initialSortColumn"
            [matSortDirection]="initialSortDirection"
        >
            <ng-container matColumnDef="termin">
                <mat-header-cell
                    mat-sort-header="termin"
                    *matHeaderCellDef
                    class="ellipsis"
                >
                    <span>{{ 'TERMIN' | translate }}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let pendenz" class="ellipsis">
                    <span [title]="pendenz.termin">{{
                        pendenz.termin | ebeguDate
                    }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="text">
                <mat-header-cell
                    mat-sort-header="text"
                    *matHeaderCellDef
                    class="ellipsis"
                >
                    <span>{{ 'PENDENZ' | translate }}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let pendenz" class="ellipsis">
                    <span [title]="pendenz.text">{{ pendenz.text }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="erledigt">
                <mat-header-cell
                    mat-sort-header="erledigt"
                    *matHeaderCellDef
                    class="ellipsis"
                >
                    <span>{{ 'ERLEDIGT' | translate }}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let pendenz" class="ellipsis center">
                    <span
                        *ngIf="pendenz.erledigt"
                        [title]="'PENDENZ_ERLEDIGT' | translate"
                    >
                        <i
                            class="fa fa-lg fa-check erledigt"
                            aria-hidden="true"
                        ></i>
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="delete">
                <mat-header-cell
                    *matHeaderCellDef
                    class="ellipsis"
                ></mat-header-cell>
                <mat-cell *matCellDef="let pendenz" class="ellipsis center">
                    <span
                        [title]="'DELETE' | translate"
                        (click)="delete(pendenz, $event)"
                    >
                        <i class="fa fa-lg fa-trash-o" aria-hidden="true"></i>
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="shownColumns"></mat-header-row>
            <mat-row
                *matRowDef="let pendenz; columns: shownColumns"
                (click)="onRowClicked(pendenz)"
            ></mat-row>
        </mat-table>
    </div>
</div>
