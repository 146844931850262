<!--
  ~ Copyright (C) 2022 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="gesuch-modul">
    <h1 class="access-for-all-title" translate>AFA_GESUCHSFORMULAR</h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                <span
                    translate
                    [translateParams]="{
                        gesuchsteller: getGesuchstellerName(),
                        basisjahr:
                            this.gesuchModelManager.getBasisjahrToWorkWith()
                    }"
                    >EKVI_EINKOMMENSVERSCHLECHTERUNG_FUER_BASISJAHR</span
                >
            </h2>
            <hr class="header" />
        </div>
    </div>
    <div class="row">
        <form role="form" #form="ngForm" novalidate>
            <div class="col-md-12">
                <dv-fin-sit-felder-appenzell
                    [readOnly]="isGesuchReadonly()"
                    [finanzModel]="model"
                    [showBisher]="showBisher(getEkvToWorkWith())"
                    [antragstellerNumber]="getAntragstellerNumber()"
                    [finSitZusatzangabenAppenzell]="
                        getZusatzangabenAppenzellToWorkWith()
                    "
                    [deklaration]="getZusatzangabenAppenzellGSToWorkWith()"
                    (valueChanges)="calculateResults()"
                ></dv-fin-sit-felder-appenzell>
                <dv-navigation-x
                    (dvSave)="save($event.onResult)"
                    [dvPrevious]="true"
                    [dvNext]="true"
                    [dvSubStep]="3"
                ></dv-navigation-x>
            </div>
        </form>
    </div>
</div>
