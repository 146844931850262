<!--
  ~ Copyright (C) 2020 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<h2 mat-dialog-title [textContent]="title | translate"></h2>

<mat-dialog-content *ngIf="text">
    <span [textContent]="text | translate"></span>
    <br />&nbsp;
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dv-dialog-actions">
        <button
            class="dv-btn-extended dv-btn"
            (click)="actionOne()"
            [textContent]="actionOneButtonLabel | translate"
        ></button>
        <button
            class="dv-btn-extended dv-btn"
            (click)="actionTwo()"
            [textContent]="actionTwoButtonLabel | translate"
        ></button>
        <button
            class="dv-btn-extended dv-btn cancel-button"
            (click)="cancel()"
            [textContent]="cancelButtonLabel | translate"
        ></button>
    </div>
</mat-dialog-actions>
