<div class="row">
    @if (!text) {
        <div class="col-sm-12 well well-status-warten" translate>
            <ng-content></ng-content>
        </div>
    } @else {
        <div
            class="col-sm-12 well well-status-warten"
            [innerHTML]="text | translate"
        ></div>
    }
</div>
