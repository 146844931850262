<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="form-group">
    <label [for]="inputId" translate>PERMITTED_EXTERNAL_CLIENTS</label>
    <mat-select
        *ngIf="externalClients"
        class="form-control"
        [(ngModel)]="externalClients.assignedClients"
        [name]="inputId"
        [id]="inputId"
        [multiple]="true"
    >
        <mat-option *ngFor="let item of options" [value]="item">
            {{ item.clientName }}
        </mat-option>
    </mat-select>
    <dv-error-messages
        [inputId]="inputId"
        [errorObject]="form.controls[inputId]?.errors"
    >
    </dv-error-messages>
</div>
