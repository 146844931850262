<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->
<div class="admin-modul" flex="auto">
    <div class="col-md-8 col-md-offset-2">
        <h1 class="access-for-all-title" translate>
            AFA_SOZIALDIENSTEVERWALTUNG
        </h1>
        <div class="view-title">
            <h2 translate>SOZIALDIENSTE_TITLE</h2>
            <hr class="header" />
        </div>
        <dv-search-list
            [hiddenColumns]="hiddenDVTableColumns"
            [data$]="antragList$"
            (openEvent)="open($event)"
            [statusPrefix]="'SOZIALDIENST_STATUS'"
            [columnName]="'SOZIALDIENST_TITLE'"
        ></dv-search-list>

        <!--Knopf unterhalb der Tabelle zum hinzufügen einer neuen Gemeinde-->
        <div *ngIf="hatBerechtigungHinzufuegen()" class="margin-top-50 row">
            <div class="col-md-3">
                <dv-loading-button (buttonClick)="addSozialdienst()">
                    <span translate>SOZIALDIENST_HINZUFUEGEN</span>
                </dv-loading-button>
            </div>
        </div>
    </div>
</div>
