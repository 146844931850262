<!--
  ~ Copyright (C) 2020 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<ng-container *ngIf="latsContainer">
    <h2 class="margin-bottom-50" translate>LATS_VERFUEGUNG</h2>

    <form role="form" #form="ngForm" name="form">
        <div class="flex-row-start-center">
            <div class="form-group">
                <label class="md-no-float" for="betreuungsstundenPrognoseId">
                    {{
                        'LATS_CONTROLLING_BETREUUNGSSTUNDEN_SCHULJAHR'
                            | translate
                    }}
                    {{ latsContainer?.gesuchsperiode | nextPeriodeStr }}
                </label>
                <input
                    type="number"
                    step="0.1"
                    [disabled]="latsContainer.isAbgeschlossen()"
                    name="betreuungsstundenPrognoseName"
                    required="true"
                    id="betreuungsstundenPrognoseId"
                    #betreuungsstundenPrognoseName
                    [(ngModel)]="latsContainer.betreuungsstundenPrognose"
                />
            </div>
            <p>
                {{ 'LATS_BERECHNUNG_AUS_KIBON' | translate }}:
                {{ betreuungsstundenPrognoseFromKiBon }}
            </p>
        </div>
        <div>
            <label
                class="md-no-float"
                for="betreuungsstundenPrognoseBemerkungenId"
                translate
                >LATS_ANGABEN_GEMEINDE_BEMERKUNGEN</label
            >
            <textarea
                [disabled]="latsContainer.isAbgeschlossen()"
                name="betreuungsstundenPrognoseBemerkungen"
                #betreuungsstundenPrognoseBemerkungen
                [required]="false"
                id="betreuungsstundenPrognoseBemerkungenId"
                [(ngModel)]="latsContainer.bemerkungenBetreuungsstundenPrognose"
            ></textarea>
        </div>
        <dv-loading-button
            type="button"
            [buttonDisabled]="
                !betreuungsstundenPrognoseName.checkValidity() ||
                latsContainer.isAbgeschlossen()
            "
            (buttonClick)="saveContainerWithPrognose()"
        >
            <span translate>SAVE</span>
        </dv-loading-button>
    </form>

    <button
        type="button"
        class="dv-btn btn-link link-underline"
        *ngIf="canViewDokumentErstellenButton | async"
        [disabled]="!hasSavedBetreuungsstundenPrognose"
        (click)="createLatsDocumentDe()"
    >
        <i
            *ngIf="(downloadingDeFile | async) === false"
            class="fa fa-file-text-o"
        ></i>
        <i
            *ngIf="downloadingDeFile | async"
            class="fa fa-circle-o-notch fa-spin fa-fw"
        ></i>
        &nbsp;
        <span translate>LATS_DOKUMENT_ERSTELLEN_DE</span>
    </button>
    <br />
    <button
        type="button"
        class="dv-btn btn-link link-underline"
        *ngIf="canViewDokumentErstellenButton | async"
        [disabled]="!hasSavedBetreuungsstundenPrognose"
        (click)="createLatsDocumentFr()"
    >
        <i
            *ngIf="(downloadingFrFile | async) === false"
            class="fa fa-file-text-o"
        ></i>
        <i
            *ngIf="downloadingFrFile | async"
            class="fa fa-circle-o-notch fa-spin fa-fw"
        ></i>
        &nbsp;
        <span translate>LATS_DOKUMENT_ERSTELLEN_FR</span>
    </button>

    <div class="actions">
        <dv-loading-button
            type="button"
            *ngIf="isAbschliessenVisible()"
            (buttonClick)="antragAbschliessen()"
        >
            <span translate>ABSCHLIESSEN</span>
        </dv-loading-button>
    </div>
    <p translate *ngIf="latsContainer?.isAbgeschlossen()">LATS_ABGESCHLOSSEN</p>
</ng-container>
