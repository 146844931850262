<div class="row">
    <div class="col-sm-6">
        <div class="form-group">
            <dv-input-label-field
                [name]="'bruttoLohn'"
                [(model)]="finSitJA.bruttoLohn"
                [labelMessageKey]="'FINANZIELLE_SITUATION_BRUTTOLOHN'"
                [inputDisabled]="readonly"
                [inputRequired]="true"
                [tooltipMessageKey]="'FINANZIELLE_SITUATION_BRUTTOLOHN_HINT'"
                [showBisher]="isNotNullOrUndefined(finSitGS)"
                [deklaration]="finSitGS?.bruttoLohn"
                [korrektur]="finSitJA.bruttoLohn"
                [dvOnBlur]="emitEvent"
                data-test="bruttoLohn"
            >
            </dv-input-label-field>
        </div>
    </div>
</div>
