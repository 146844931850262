<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->
<mat-dialog-content>
    <div *ngIf="modulTagesschuleGroup">
        <h2>{{ 'INFORMATIONEN_FUER_SCHNITTSTELLE' | translate }}</h2>
        <table class="infos margin-top-50 margin-bottom-50">
            <tr>
                <td>{{ 'INSTITUTIONSNAME' | translate }}</td>
                <td>{{ institution.name }}</td>
                <td></td>
            </tr>
            <tr>
                <td>{{ 'INSTITUTION_ID' | translate }}</td>
                <td>{{ institution.id }}</td>
                <td (click)="copyToClipboard(institution.id, 'institution')">
                    <i
                        *ngIf="!copied['institution']"
                        class="fa fa-clipboard fa-lg"
                        [title]="'COPY_TO_CLIPBOARD' | translate"
                    ></i>
                    <i
                        *ngIf="copied['institution']"
                        class="fa fa-check fa-lg"
                        [title]="'COPIED' | translate"
                    ></i>
                </td>
            </tr>
            <tr>
                <td>{{ 'MODUL_NAME' | translate }}</td>
                <td>
                    {{ modulTagesschuleGroup.bezeichnung.textDeutsch }} /
                    {{ modulTagesschuleGroup.bezeichnung.textFranzoesisch }}
                </td>
                <td></td>
            </tr>
            <tr>
                <td>{{ 'FREMD_ID' | translate }}</td>
                <td *ngIf="editMode">
                    <form role="form" #form="ngForm">
                        <input
                            id="fremdId"
                            type="text"
                            name="fremdId"
                            #fremdId="ngModel"
                            [(ngModel)]="modulTagesschuleGroup.fremdId"
                            class="form-control"
                            required
                        />
                    </form>
                </td>
                <td *ngIf="!editMode">{{ modulTagesschuleGroup.fremdId }}</td>
                <td
                    (click)="
                        copyToClipboard(modulTagesschuleGroup.fremdId, 'modul')
                    "
                >
                    <i
                        *ngIf="!copied['modul']"
                        class="fa fa-clipboard fa-lg"
                        [title]="'COPY_TO_CLIPBOARD' | translate"
                    ></i>
                    <i
                        *ngIf="copied['modul']"
                        class="fa fa-check fa-lg"
                        [title]="'COPIED' | translate"
                    ></i>
                </td>
            </tr>
        </table>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dv-dialog-actions">
        <button
            type="button"
            class="dv-btn cancel-button"
            (click)="close()"
            [textContent]="'SCHLIESSEN' | translate"
        ></button>
    </div>
</mat-dialog-actions>
