<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="ferienbetreuung-stammdaten">
    <h1 class="access-for-all-title" translate>FERIENBETREUUNG_ANGEBOT</h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                {{
                    'FERIENBETREUUNG_ANGEBOT_SCHULJAHR'
                        | translate
                            : {
                                  periode:
                                      container?.gesuchsperiode
                                          ?.gesuchsperiodeString,
                                  von: addOneMonthToMoment(
                                      container?.gesuchsperiode?.gueltigkeit
                                          .gueltigAb
                                  ),
                                  bis: addOneMonthToMoment(
                                      container?.gesuchsperiode?.gueltigkeit
                                          .gueltigBis
                                  )
                              }
                }}
            </h2>
            <hr class="header" />
        </div>
    </div>

    <form *ngIf="container" [formGroup]="form">
        <div class="max-width-container-750">
            <dv-input-container-x [verticalAlign]="true">
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            form && form.controls.angebot.errors
                    }"
                    for="angebot_id"
                >
                    {{ 'FERIENBETREUUNG_NAME_ANGEBOT' | translate }}
                    <dv-tooltip
                        inputId="angebot_id"
                        [text]="'FERIENBETREUUNG_NAME_ANGEBOT_HINT' | translate"
                    >
                    </dv-tooltip>
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        type="text"
                        matInput
                        formControlName="angebot"
                        id="angebot_id"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="form && form.controls.angebot.errors?.required"
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot?.angebot
                        "
                        [korrektur]="form.value.angebot"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 translate class="uppercase">FERIENBETREUUNG_KONTAKTPERSON</h3>

            <div formGroupName="angebotAdresse">
                <div class="row">
                    <dv-input-container-x
                        [verticalAlign]="true"
                        class="col-xs-6"
                    >
                        <label
                            required
                            for="angebotKontaktpersonVorname_id"
                            [ngClass]="{
                                'label-required-error':
                                    form &&
                                    form.controls.angebotAdresse.controls
                                        .kontaktpersonVorname.errors
                            }"
                        >
                            {{ 'VORNAME' | translate }}
                        </label>
                        <mat-form-field floatLabel="always">
                            <input
                                type="text"
                                matInput
                                id="angebotKontaktpersonVorname_id"
                                formControlName="kontaktpersonVorname"
                                class="form-control dv-input"
                            />
                            <mat-error
                                *ngIf="
                                    form &&
                                    form.controls.angebotAdresse.controls
                                        .kontaktpersonVorname.errors?.required
                                "
                                translate
                                >ERROR_REQUIRED
                            </mat-error>
                            <dv-bisher-x
                                [deklaration]="
                                    container?.angabenDeklaration?.angebot
                                        ?.angebotKontaktpersonVorname
                                "
                                [korrektur]="
                                    form.value.angebotAdresse
                                        ?.kontaktpersonVorname
                                "
                                [showBisher]="
                                    container.isAtLeastInPruefungKantonOrZurueckgegeben()
                                "
                                [specificBisherLabel]="
                                    'LATS_BISHER_LABEL' | translate
                                "
                            >
                            </dv-bisher-x>
                        </mat-form-field>
                    </dv-input-container-x>

                    <dv-input-container-x
                        [verticalAlign]="true"
                        class="col-xs-6"
                    >
                        <label
                            required
                            for="angebotKontaktpersonNachname_id"
                            [ngClass]="{
                                'label-required-error':
                                    form &&
                                    form.controls.angebotAdresse.controls
                                        .kontaktpersonNachname.errors
                            }"
                        >
                            {{ 'NAME' | translate }}
                        </label>
                        <mat-form-field floatLabel="always">
                            <input
                                type="text"
                                matInput
                                id="angebotKontaktpersonNachname_id"
                                formControlName="kontaktpersonNachname"
                                class="form-control dv-input"
                            />
                            <mat-error
                                *ngIf="
                                    form &&
                                    form.controls.angebotAdresse.controls
                                        .kontaktpersonNachname.errors?.required
                                "
                                translate
                                >ERROR_REQUIRED
                            </mat-error>
                            <dv-bisher-x
                                [deklaration]="
                                    container?.angabenDeklaration?.angebot
                                        ?.angebotKontaktpersonNachname
                                "
                                [korrektur]="
                                    form.value.angebotAdresse
                                        ?.kontaktpersonNachname
                                "
                                [showBisher]="
                                    container.isAtLeastInPruefungKantonOrZurueckgegeben()
                                "
                                [specificBisherLabel]="
                                    'LATS_BISHER_LABEL' | translate
                                "
                            >
                            </dv-bisher-x>
                        </mat-form-field>
                    </dv-input-container-x>
                </div>

                <div class="row">
                    <dv-input-container-x
                        [verticalAlign]="true"
                        class="col-xs-10"
                    >
                        <label
                            required
                            for="strasse_id"
                            [ngClass]="{
                                'label-required-error':
                                    form &&
                                    form.controls.angebotAdresse.controls
                                        .strasse.errors
                            }"
                        >
                            {{ 'ADRESSE_STRASSE' | translate }}
                        </label>
                        <mat-form-field floatLabel="always">
                            <input
                                type="text"
                                id="strasse_id"
                                matInput
                                formControlName="strasse"
                                class="form-control dv-input"
                            />
                            <mat-error
                                *ngIf="
                                    form &&
                                    form.controls.angebotAdresse.controls
                                        .strasse.errors?.required
                                "
                                translate
                                >ERROR_REQUIRED
                            </mat-error>
                            <dv-bisher-x
                                [deklaration]="
                                    container?.angabenDeklaration?.angebot
                                        ?.angebotAdresse?.strasse
                                "
                                [korrektur]="form.value.angebotAdresse?.strasse"
                                [showBisher]="
                                    container.isAtLeastInPruefungKantonOrZurueckgegeben()
                                "
                                [specificBisherLabel]="
                                    'LATS_BISHER_LABEL' | translate
                                "
                            >
                            </dv-bisher-x>
                        </mat-form-field>
                    </dv-input-container-x>

                    <dv-input-container-x
                        [verticalAlign]="true"
                        class="col-xs-2"
                    >
                        <label for="hausnummer_id">
                            {{ 'FERIENBETREUUNG_ADRESSE_NR' | translate }}
                        </label>
                        <mat-form-field floatLabel="always">
                            <input
                                type="text"
                                id="hausnummer_id"
                                matInput
                                formControlName="hausnummer"
                                class="form-control dv-input"
                            />
                        </mat-form-field>
                        <dv-bisher-x
                            [deklaration]="
                                container?.angabenDeklaration?.angebot
                                    ?.angebotAdresse?.hausnummer
                            "
                            [korrektur]="form.value.angebotAdresse?.hausnummer"
                            [showBisher]="
                                container.isAtLeastInPruefungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </dv-input-container-x>
                </div>

                <dv-input-container-x [verticalAlign]="true">
                    <label for="adresse_stammdaten_zusatz_id">
                        {{ 'ADRESSE_ZUSATZZEILE' | translate }}
                    </label>
                    <mat-form-field floatLabel="always">
                        <input
                            type="text"
                            id="adresse_stammdaten_zusatz_id"
                            matInput
                            formControlName="zusatzzeile"
                            class="form-control dv-input"
                        />
                        <dv-bisher-x
                            [deklaration]="
                                container?.angabenDeklaration?.angebot
                                    ?.angebotAdresse?.zusatzzeile
                            "
                            [korrektur]="form.value.angebotAdresse?.zusatzzeile"
                            [showBisher]="
                                container.isAtLeastInPruefungKantonOrZurueckgegeben()
                            "
                            [specificBisherLabel]="
                                'LATS_BISHER_LABEL' | translate
                            "
                        >
                        </dv-bisher-x>
                    </mat-form-field>
                </dv-input-container-x>

                <div class="row">
                    <dv-input-container-x
                        [verticalAlign]="true"
                        class="col-xs-2"
                    >
                        <label
                            required
                            for="plz_id"
                            [ngClass]="{
                                'label-required-error':
                                    form &&
                                    form.controls.angebotAdresse.controls.plz
                                        .errors
                            }"
                        >
                            {{ 'ADRESSE_PLZ' | translate }}
                        </label>
                        <mat-form-field floatLabel="always">
                            <input
                                type="text"
                                id="plz_id"
                                matInput
                                formControlName="plz"
                                class="form-control dv-input"
                            />
                            <mat-error
                                *ngIf="
                                    form &&
                                    form.controls.angebotAdresse.controls.plz
                                        .errors?.required
                                "
                                translate
                                >ERROR_REQUIRED
                            </mat-error>
                            <dv-bisher-x
                                [deklaration]="
                                    container?.angabenDeklaration?.angebot
                                        ?.angebotAdresse?.plz
                                "
                                [korrektur]="form.value.angebotAdresse?.plz"
                                [showBisher]="
                                    container.isAtLeastInPruefungKantonOrZurueckgegeben()
                                "
                                [specificBisherLabel]="
                                    'LATS_BISHER_LABEL' | translate
                                "
                            >
                            </dv-bisher-x>
                        </mat-form-field>
                    </dv-input-container-x>

                    <dv-input-container-x
                        [verticalAlign]="true"
                        class="col-xs-10"
                    >
                        <label
                            required
                            for="ort_id"
                            [ngClass]="{
                                'label-required-error':
                                    form &&
                                    form.controls.angebotAdresse.controls.ort
                                        .errors
                            }"
                        >
                            {{ 'ADRESSE_ORT' | translate }}
                        </label>
                        <mat-form-field floatLabel="always">
                            <input
                                type="text"
                                id="ort_id"
                                matInput
                                formControlName="ort"
                                class="form-control dv-input"
                            />
                            <mat-error
                                *ngIf="
                                    form &&
                                    form.controls.angebotAdresse.controls.ort
                                        .errors?.required
                                "
                                translate
                                >ERROR_REQUIRED
                            </mat-error>
                            <dv-bisher-x
                                [deklaration]="
                                    container?.angabenDeklaration?.angebot
                                        ?.angebotAdresse?.ort
                                "
                                [korrektur]="form.value.angebotAdresse?.ort"
                                [showBisher]="
                                    container.isAtLeastInPruefungKantonOrZurueckgegeben()
                                "
                                [specificBisherLabel]="
                                    'LATS_BISHER_LABEL' | translate
                                "
                            >
                            </dv-bisher-x>
                        </mat-form-field>
                    </dv-input-container-x>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <h3 class="uppercase max-width-container-750">
            {{ 'FERIENBETREUUNG_ANGEBOT' | translate }}
            <dv-tooltip
                [text]="'FERIENBETREUUNG_ANGEBOT_HINT' | translate"
            ></dv-tooltip>
        </h3>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    for="anzahl_ferienwoche_herbst_id"
                    [ngClass]="{
                        'label-required-error':
                            form &&
                            form.controls.anzahlFerienwochenHerbstferien.errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_ANZAHL_FERIENWOCHEN_HERBST' | translate
                    }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        placeholder=""
                        id="anzahl_ferienwoche_herbst_id"
                        formControlName="anzahlFerienwochenHerbstferien"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenHerbstferien.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenHerbstferien.errors
                                ?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.anzahlFerienwochenHerbstferien
                        "
                        [korrektur]="form.value.anzahlFerienwochenHerbstferien"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot
                        ?.anzahlFerienwochenHerbstferien
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    for="anzahl_ferienwoche_winter_id"
                    [ngClass]="{
                        'label-required-error':
                            form &&
                            form.controls.anzahlFerienwochenWinterferien.errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_ANZAHL_FERIENWOCHEN_WINTER' | translate
                    }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahl_ferienwoche_winter_id"
                        formControlName="anzahlFerienwochenWinterferien"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenWinterferien.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenWinterferien.errors
                                ?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.anzahlFerienwochenWinterferien
                        "
                        [korrektur]="form.value.anzahlFerienwochenWinterferien"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot
                        ?.anzahlFerienwochenWinterferien
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    for="anzahl_ferienwoche_sport_id"
                    [ngClass]="{
                        'label-required-error':
                            form &&
                            form.controls.anzahlFerienwochenSportferien.errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_ANZAHL_FERIENWOCHEN_SPORT' | translate
                    }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahl_ferienwoche_sport_id"
                        formControlName="anzahlFerienwochenSportferien"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenSportferien.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenSportferien.errors
                                ?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.anzahlFerienwochenSportferien
                        "
                        [korrektur]="form.value.anzahlFerienwochenSportferien"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot
                        ?.anzahlFerienwochenSportferien
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    for="anzahl_ferienwoche_fruehling_id"
                    [ngClass]="{
                        'label-required-error':
                            form &&
                            form.controls.anzahlFerienwochenFruehlingsferien
                                .errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_ANZAHL_FERIENWOCHEN_FRUEHLING'
                            | translate
                    }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahl_ferienwoche_fruehling_id"
                        formControlName="anzahlFerienwochenFruehlingsferien"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenFruehlingsferien
                                .errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenFruehlingsferien
                                .errors?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.anzahlFerienwochenFruehlingsferien
                        "
                        [korrektur]="
                            form.value.anzahlFerienwochenFruehlingsferien
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot
                        ?.anzahlFerienwochenFruehlingsferien
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    for="anzahl_ferienwoche_sommer_id"
                    [ngClass]="{
                        'label-required-error':
                            form &&
                            form.controls.anzahlFerienwochenSommerferien.errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_ANZAHL_FERIENWOCHEN_SOMMER' | translate
                    }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahl_ferienwoche_sommer_id"
                        formControlName="anzahlFerienwochenSommerferien"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenSommerferien.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlFerienwochenSommerferien.errors
                                ?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.anzahlFerienwochenSommerferien
                        "
                        [korrektur]="form.value.anzahlFerienwochenSommerferien"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot
                        ?.anzahlFerienwochenSommerferien
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    for="anzahl_tage_id"
                    [ngClass]="{
                        'label-required-error':
                            form && form.controls.anzahlTage.errors
                    }"
                >
                    {{ 'FERIENBETREUUNG_ANZAHL_TAGE' | translate }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahl_tage_id"
                        formControlName="anzahlTage"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form && form.controls.anzahlTage.errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlTage.errors?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot?.anzahlTage
                        "
                        [korrektur]="form.value.anzahlTage"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="vorgaenger.angabenKorrektur?.angebot?.anzahlTage"
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x
                class="max-width-container-750"
                [verticalAlign]="true"
            >
                <label for="bemerkungen_id">
                    {{ 'BEMERKUNGEN' | translate }}
                </label>
                <mat-form-field floatLabel="always" class="no-margin-left">
                    <textarea
                        matInput
                        id="bemerkungen_id"
                        formControlName="bemerkungenAnzahlFerienwochen"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.bemerkungenAnzahlFerienwochen
                        "
                        [korrektur]="form.value.bemerkungenAnzahlFerienwochen"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot
                        ?.bemerkungenAnzahlFerienwochen
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <mat-divider></mat-divider>

        <h3 class="uppercase">
            {{ 'FERIENBETREUUNG_OEFFNUNGSZEITEN' | translate }}
            <dv-tooltip
                [text]="'FERIENBETREUUNG_OEFFNUNGSZEITEN_HINT' | translate"
            ></dv-tooltip>
        </h3>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    for="anzahl_stunden_betreuungstag_id"
                    [ngClass]="{
                        'label-required-error':
                            form &&
                            form.controls.anzahlStundenProBetreuungstag.errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_ANZAHL_STUNDEN_BETREUUNGSTAG'
                            | translate
                    }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="anzahl_stunden_betreuungstag_id"
                        formControlName="anzahlStundenProBetreuungstag"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlStundenProBetreuungstag.errors
                                ?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.anzahlStundenProBetreuungstag.errors
                                ?.isNotHalf
                        "
                        translate
                        >ERROR_NOT_HALF
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.anzahlStundenProBetreuungstag
                        "
                        [korrektur]="form.value.anzahlStundenProBetreuungstag"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot
                        ?.anzahlStundenProBetreuungstag
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formValidationTriggered &&
                            form.controls.betreuungErfolgtTagsueber.errors
                    }"
                    id="betreuungErfolgtTagsueber_id"
                >
                    {{
                        'FERIENBETREUUNG_BETREUUNG_ERFOLGT_TAGSUEBER'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        aria-labelledby="betreuungErfolgtTagsueber_id"
                        class=""
                        name="betreuungErfolgtTagsueber"
                        horizontal
                        formControlName="betreuungErfolgtTagsueber"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.betreuungErfolgtTagsueber
                        "
                        [korrektur]="form.value.betreuungErfolgtTagsueber"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>
        </div>

        <div class="flex-row">
            <dv-input-container-x
                class="max-width-container-750"
                [verticalAlign]="true"
            >
                <label for="bemerkungen_oeffnungszeiten_id">
                    {{ 'BEMERKUNGEN' | translate }}
                </label>
                <mat-form-field floatLabel="always" class="no-margin-left">
                    <textarea
                        matInput
                        id="bemerkungen_oeffnungszeiten_id"
                        formControlName="bemerkungenOeffnungszeiten"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.bemerkungenOeffnungszeiten
                        "
                        [korrektur]="form.value.bemerkungenOeffnungszeiten"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot
                        ?.bemerkungenOeffnungszeiten
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 class="uppercase">
                {{ 'FERIENBETREUUNG_KOOPERATION' | translate }}
            </h3>

            <dv-input-container-x [verticalAlign]="true">
                <label for="am-angebot-beteiligte-gemeinden">
                    {{
                        'FERIENBETREUUNG_FINANZIELL_BETEILIGTE_GEMEINDEN'
                            | translate
                    }}
                    <dv-tooltip
                        [text]="
                            'FERIENBETREUUNG_FINANZIELL_BETEILIGTE_GEMEINDEN_HINT'
                                | translate
                        "
                    ></dv-tooltip>
                </label>
                <mat-form-field *ngIf="bfsGemeinden?.length">
                    <mat-select
                        class="form-control"
                        formControlName="finanziellBeteiligteGemeinden"
                        name="finanziellBeteiligteGemeinden"
                        id="am-angebot-beteiligte-gemeinden"
                        [multiple]="true"
                    >
                        <mat-option
                            *ngFor="let gemeinde of bfsGemeinden"
                            [value]="gemeinde.name"
                        >
                            {{ gemeinde.name }}
                        </mat-option>
                    </mat-select>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot?.finanziellBeteiligteGemeinden?.join(
                                ', '
                            )
                        "
                        [korrektur]="
                            form.value.finanziellBeteiligteGemeinden
                                ?.sort()
                                .join(', ')
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <p class="margin-bottom-30">
                {{ 'FERIENBETREUUNG_WIE_ORGANISIERT' | translate }}
            </p>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formValidationTriggered &&
                            form.controls.gemeindeFuehrtAngebotSelber.errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_GEMEINDE_FUEHRT_ANGEBOT_SELBER'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="gemeindeFuehrtAngebotSelber"
                        horizontal
                        formControlName="gemeindeFuehrtAngebotSelber"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.gemeindeFuehrtAngebotSelber
                        "
                        [korrektur]="form.value.gemeindeFuehrtAngebotSelber"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label>
                    {{
                        'FERIENBETREUUNG_GEMEINDE_FUEHRT_ANGEBOT_IN_KOOPERATION'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="gemeindeFuehrtAngebotInKooperation"
                        horizontal
                        formControlName="gemeindeFuehrtAngebotInKooperation"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.gemeindeFuehrtAngebotInKooperation
                        "
                        [korrektur]="
                            form.value.gemeindeFuehrtAngebotInKooperation
                        "
                        [showBisher]="container.isAtLeastInPruefungKanton()"
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formValidationTriggered &&
                            form.controls.gemeindeBeauftragtExterneAnbieter
                                .errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_GEMEINDE_BEAUFTRAGT_EXTERNE_ANBIETER'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="gemeindeBeauftragtExterneAnbieter"
                        horizontal
                        formControlName="gemeindeBeauftragtExterneAnbieter"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.gemeindeBeauftragtExterneAnbieter
                        "
                        [korrektur]="
                            form.value.gemeindeBeauftragtExterneAnbieter
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label>
                    {{
                        'FERIENBETREUUNG_ANGEBOT_VEREINE_UND_PRIVATE_INTEGRIERT'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="angebotVereineUndPrivateIntegriert"
                        horizontal
                        formControlName="angebotVereineUndPrivateIntegriert"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.angebotVereineUndPrivateIntegriert
                        "
                        [korrektur]="
                            form.value.angebotVereineUndPrivateIntegriert
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x [verticalAlign]="true">
                <label for="bemerkungen_kooperation">
                    {{ 'BEMERKUNGEN' | translate }}
                </label>
                <mat-form-field floatLabel="always" class="no-margin-left">
                    <textarea
                        matInput
                        id="bemerkungen_kooperation"
                        formControlName="bemerkungenKooperation"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.bemerkungenKooperation
                        "
                        [korrektur]="form.value.bemerkungenKooperation"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 class="uppercase">
                {{ 'FERIENBETREUUNG_PERSONAL_UND_QUALITAET' | translate }}
            </h3>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formValidationTriggered &&
                            form.controls.leitungDurchPersonMitAusbildung.errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_LEITUNG_DURCH_PERSON_MIT_AUSBILDUNG'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="leitungDurchPersonMitAusbildung"
                        horizontal
                        formControlName="leitungDurchPersonMitAusbildung"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.leitungDurchPersonMitAusbildung
                        "
                        [korrektur]="form.value.leitungDurchPersonMitAusbildung"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formValidationTriggered &&
                            form.controls.betreuungDurchPersonenMitErfahrung
                                .errors
                    }"
                >
                    {{
                        'FERIENBETREUUNG_LEITUNG_DURCH_PERSON_MIT_ERFAHRUNG'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="betreuungDurchPersonenMitErfahrung"
                        horizontal
                        formControlName="betreuungDurchPersonenMitErfahrung"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.betreuungDurchPersonenMitErfahrung
                        "
                        [korrektur]="
                            form.value.betreuungDurchPersonenMitErfahrung
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label
                    required
                    [ngClass]="{
                        'label-required-error':
                            formValidationTriggered &&
                            form.controls.anzahlKinderAngemessen.errors
                    }"
                >
                    {{ 'FERIENBETREUUNG_ANZAHL_KINDER_ANGEMESSEN' | translate }}
                    <dv-tooltip
                        [text]="
                            'FERIENBETREUUNG_ANZAHL_KINDER_ANGEMESSEN_HINT'
                                | translate
                        "
                    ></dv-tooltip>
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="anzahlKinderAngemessen"
                        horizontal
                        formControlName="anzahlKinderAngemessen"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.anzahlKinderAngemessen
                        "
                        [korrektur]="form.value.anzahlKinderAngemessen"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>
        </div>

        <div class="flex-row">
            <dv-input-container-x class="max-width-container-750">
                <label
                    for="betreuungsschluessel_id"
                    required
                    [ngClass]="{
                        'label-required-error':
                            form && form.controls.betreuungsschluessel.errors
                    }"
                >
                    {{ 'FERIENBETREUUNG_BETREUUNGSSCHLUESSEL' | translate }}
                </label>
                <mat-form-field floatLabel="always">
                    <input
                        matInput
                        id="betreuungsschluessel_id"
                        formControlName="betreuungsschluessel"
                        class="form-control dv-input"
                    />
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.betreuungsschluessel.errors?.required
                        "
                        translate
                        >ERROR_REQUIRED
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form &&
                            form.controls.betreuungsschluessel.errors
                                ?.isNotInteger
                        "
                        translate
                        >ERROR_NOT_INTEGER
                    </mat-error>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.betreuungsschluessel
                        "
                        [korrektur]="form.value.betreuungsschluessel"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
            <dv-ferienbetreuung-last-year-value
                *ngIf="vorgaenger$ | async as vorgaenger"
                [gesuchsperiode]="
                    vorgaenger.gesuchsperiode?.gesuchsperiodeString
                "
                [value]="
                    vorgaenger.angabenKorrektur?.angebot?.betreuungsschluessel
                "
            ></dv-ferienbetreuung-last-year-value>
        </div>

        <div class="max-width-container-750">
            <dv-input-container-x [verticalAlign]="true">
                <label for="bemerkungen_personal">
                    {{ 'BEMERKUNGEN' | translate }}
                </label>
                <mat-form-field floatLabel="always" class="no-margin-left">
                    <textarea
                        matInput
                        id="bemerkungen_personal"
                        formControlName="bemerkungenPersonal"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.bemerkungenPersonal
                        "
                        [korrektur]="form.value.bemerkungenPersonal"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>

        <mat-divider></mat-divider>

        <div class="max-width-container-750">
            <h3 class="uppercase">
                {{ 'FERIENBETREUUNG_TARIFSYSTEM' | translate }}
            </h3>

            <dv-input-container-x>
                <label>
                    {{
                        'FERIENBETREUUNG_FIXER_TARIF_KINDER_DER_GEMEINDE'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="fixerTarifKinderDerGemeinde"
                        horizontal
                        formControlName="fixerTarifKinderDerGemeinde"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.fixerTarifKinderDerGemeinde
                        "
                        [korrektur]="form.value.fixerTarifKinderDerGemeinde"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label>
                    {{
                        'FERIENBETREUUNG_EINKOMMENSABHAENGIGER_TARIF_KINDER'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="einkommensabhaengigerTarifKinderDerGemeinde"
                        horizontal
                        formControlName="einkommensabhaengigerTarifKinderDerGemeinde"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.einkommensabhaengigerTarifKinderDerGemeinde
                        "
                        [korrektur]="
                            form.value
                                .einkommensabhaengigerTarifKinderDerGemeinde
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label>
                    {{
                        'FERIENBETREUUNG_TAGESSCHULE_TARIF_GILT_FUER_FERIENBETREUUNG'
                            | translate
                    }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="tagesschuleTarifGiltFuerFerienbetreuung"
                        horizontal
                        formControlName="tagesschuleTarifGiltFuerFerienbetreuung"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.tagesschuleTarifGiltFuerFerienbetreuung
                        "
                        [korrektur]="
                            form.value.tagesschuleTarifGiltFuerFerienbetreuung
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label>
                    {{ 'FERIENBETREUUNG_TARIF_ABGELEITET' | translate }}
                </label>
                <dv-radio-container-x>
                    <mat-radio-group
                        class=""
                        name="ferienbetreuungTarifWirdAusTagesschuleTarifAbgeleitet"
                        horizontal
                        formControlName="ferienbetreuungTarifWirdAusTagesschuleTarifAbgeleitet"
                    >
                        <mat-radio-button [value]="true">{{
                            'LABEL_JA' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{
                            'LABEL_NEIN' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.ferienbetreuungTarifWirdAusTagesschuleTarifAbgeleitet
                        "
                        [korrektur]="
                            form.value
                                .ferienbetreuungTarifWirdAusTagesschuleTarifAbgeleitet
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </dv-radio-container-x>
            </dv-input-container-x>

            <dv-input-container-x>
                <label for="kinder-aus-anderen-gemeinden-zahlen-anderen-tarif">
                    {{
                        'FERIENBETREUUNG_KINDER_ANDERER_GEMEINDEN_ANDERER_TARIF'
                            | translate
                    }}
                </label>
                <mat-form-field>
                    <mat-select
                        class="form-control"
                        formControlName="kinderAusAnderenGemeindenZahlenAnderenTarif"
                        name="kinderAusAnderenGemeindenZahlenAnderenTarif"
                        id="kinder-aus-anderen-gemeinden-zahlen-anderen-tarif"
                        [multiple]="false"
                    >
                        <mat-option value="JA">
                            {{ 'LABEL_JA' | translate }}
                        </mat-option>
                        <mat-option value="NEIN">
                            {{ 'LABEL_NEIN' | translate }}
                        </mat-option>
                        <mat-option value="KEINE_KINDER_AUS_ANDEREN_GEMEINDEN">
                            {{
                                'KEINE_KINDER_AUS_ANDEREN_GEMEINDEN' | translate
                            }}
                        </mat-option>
                    </mat-select>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.kinderAusAnderenGemeindenZahlenAnderenTarif
                        "
                        [korrektur]="
                            form.value
                                .kinderAusAnderenGemeindenZahlenAnderenTarif
                        "
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>

            <dv-input-container-x [verticalAlign]="true">
                <label for="bemerkungen_tarifsystem_id">
                    {{ 'BEMERKUNGEN' | translate }}
                </label>
                <mat-form-field floatLabel="always" class="no-margin-left">
                    <textarea
                        matInput
                        id="bemerkungen_tarifsystem_id"
                        formControlName="bemerkungenTarifsystem"
                        class="form-control dv-input"
                    ></textarea>
                    <dv-bisher-x
                        [deklaration]="
                            container?.angabenDeklaration?.angebot
                                ?.bemerkungenTarifsystem
                        "
                        [korrektur]="form.value.bemerkungenTarifsystem"
                        [showBisher]="
                            container.isAtLeastInPruefungKantonOrZurueckgegeben()
                        "
                        [specificBisherLabel]="'LATS_BISHER_LABEL' | translate"
                    >
                    </dv-bisher-x>
                </mat-form-field>
            </dv-input-container-x>
        </div>
    </form>

    <div class="actions">
        <button
            type="button"
            class="dv-btn cancel-button"
            *ngIf="canSeeFalscheAngaben | async"
            (click)="onFalscheAngaben()"
            [textContent]="'FERIENBETREUUNG_ANGABEN_KORRIGIEREN' | translate"
        ></button>
        <button
            type="button"
            class="dv-btn cancel-button"
            *ngIf="canSeeSave | async"
            (click)="save()"
            [textContent]="'SAVE' | translate"
        ></button>
        <dv-loading-button
            *ngIf="canSeeAbschliessen | async"
            type="button"
            (buttonClick)="onAbschliessen()"
        >
            <span *ngIf="!container?.isInPruefungKanton()" translate
                >ABSCHLIESSEN</span
            >
            <span *ngIf="container?.isInPruefungKanton()" translate
                >GEPRUEFT</span
            >
        </dv-loading-button>
    </div>
</div>
