<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2017 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->

<div class="zahlungsauftrag-view">
    <!-- Toggle fuer den Auszahlungstyp (anstelle von Tabs, da GUI sonst identisch) -->
    <div *ngIf="showAuszahlungsTypToggle()" class="switch-auszahlungstyp">
        <dv-switch
            [switchOptionLeft]="'GEMEINDE_INSTITUTION'"
            [switchOptionRight]="'GEMEINDE_ANTRAGSTELLER'"
            [switchOptionLabelRight]="getMsgKeyForToggleRight()"
            [(switchValue)]="zahlungslaufTyp"
            (switchValueChange)="toggleAuszahlungslaufTyp()"
        ></dv-switch>
    </div>

    <form
        role="form"
        #form="ngForm"
        name="form"
        [ngClass]="{'ng-submitted': form.submitted}"
        unsaved-warning-form=""
        novalidate
        *ngIf="showForm()"
    >
        <div class="row actions">
            <div>
                <div class="row zahlungen-form flex-row-start-center">
                    <!-- Gemeinde -->
                    <div
                        [ngClass]="{
                            'col-md-3': !testMode,
                            'col-md-2': testMode
                        }"
                    >
                        <div class="form-group">
                            <label class="md-no-float" for="gemeinde" translate
                                >GEMEINDE</label
                            >
                            <div class="dv-select-style">
                                <select
                                    [(ngModel)]="gemeinde"
                                    class="form-control"
                                    id="gemeinde"
                                    name="gemeinde"
                                    required
                                >
                                    <option [ngValue]="null"></option>
                                    <option
                                        *ngFor="
                                            let gemeindeEntry of gemeindenList
                                        "
                                        [ngValue]="gemeindeEntry"
                                    >
                                        {{ gemeindeEntry.name }}
                                    </option>
                                </select>
                                <dv-error-messages
                                    [errorObject]="
                                        form?.form.get('gemeinde')?.errors
                                    "
                                    class="error"
                                ></dv-error-messages>
                            </div>
                        </div>
                    </div>

                    <div
                        [ngClass]="{
                            'col-md-3': !testMode,
                            'col-md-2': testMode
                        }"
                    >
                        <div class="form-group">
                            <label
                                class="md-no-float"
                                for="faelligkeitsdatumPicker"
                                translate
                                >ZAHLUNG_FAELLIGKEITSDATUM</label
                            >
                            <input
                                class="dv-mat-datepicker"
                                type="text"
                                name="faelligkeitsdatumPicker"
                                id="faelligkeitsdatumPicker"
                                [(ngModel)]="faelligkeitsdatum"
                                [matDatepicker]="faelligkeitsdatumPicker"
                                placeholder="{{
                                    'DATE_PLACEHOLDER' | translate
                                }}"
                                required
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="faelligkeitsdatumPicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                                #faelligkeitsdatumPicker
                            ></mat-datepicker>
                            <dv-error-messages
                                [errorObject]="
                                    (form?.form.get('faelligkeitsdatumPicker')
                                        ?.dirty ||
                                        form?.submitted) &&
                                    form?.form.get('faelligkeitsdatumPicker')
                                        ?.errors
                                "
                                class="error"
                            ></dv-error-messages>
                        </div>
                    </div>
                    <div class="col-md-2" *ngIf="testMode">
                        <div class="form-group">
                            <label
                                class="md-no-float"
                                for="datumGeneriertPicker"
                                translate
                                >ZAHLUNG_GENERIERT</label
                            >
                            <input
                                class="dv-mat-datepicker"
                                type="text"
                                name="datumGeneriertPicker"
                                id="datumGeneriertPicker"
                                [(ngModel)]="datumGeneriert"
                                [matDatepicker]="datumGeneriertPicker"
                                placeholder="{{
                                    'DATE_PLACEHOLDER' | translate
                                }}"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="datumGeneriertPicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                                #datumGeneriertPicker
                            ></mat-datepicker>
                            <dv-error-messages
                                [errorObject]="
                                    form?.form.get('datumGeneriertPicker')
                                        ?.errors
                                "
                                class="error"
                            ></dv-error-messages>
                        </div>
                    </div>
                    <div
                        [ngClass]="{
                            'col-md-4': !testMode,
                            'col-md-3': testMode
                        }"
                    >
                        <div class="form-group">
                            <label class="md-no-float" translate for="beschrieb"
                                >ZAHLUNG_BESCHRIEB</label
                            >
                            <input
                                id="beschrieb"
                                name="beschrieb"
                                [(ngModel)]="beschrieb"
                                class="form-control input-dv input-element"
                                required
                            />
                            <dv-error-messages
                                [errorObject]="
                                    form?.form.get('beschrieb')?.errors
                                "
                                class="error"
                            ></dv-error-messages>
                        </div>
                    </div>
                    <div flex layout="row" layout-align="center center">
                        <dv-loading-button
                            (buttonClick)="createZahlungsauftrag()"
                            [ariaLabel]="getLabelZahlungslaufErstellen()"
                            type="submit"
                        >
                            <span class="uppercase">{{
                                getLabelZahlungslaufErstellen()
                            }}</span>
                        </dv-loading-button>
                    </div>
                    <div class="col-md-3" *ngIf="checkboxAuszahlungInZukunft">
                        <dv-checkbox-x
                            [label]="'CHECKBOX_AUSZAHLUNG_IN_ZUKUNFT'"
                            [(model)]="auszahlungInZukunft"
                        ></dv-checkbox-x>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="col-md-12" ng-show="showInfotext()">
        <p translate>ZAHLUNGSLAUF_INFO_1</p>
        <p translate>ZAHLUNGSLAUF_INFO_2</p>
    </div>

    <div class="row">
        <div class="col-md-11">
            <h3 translate>ZAHLUNGSAUFTRAEGE</h3>
        </div>
        <div class="col-md-1 text-right">
            <h3>{{ totalResult }}</h3>
        </div>
    </div>
    <div class="table-responsive" *ngIf="showGemeindeFilter()">
        <table class="table ebegu-table fixedtable table-striped">
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>
                        <div class="dv-select-style">
                            <select
                                name="gemeindeFilter"
                                [(ngModel)]="filterGemeinde"
                                (ngModelChange)="updateZahlungsauftrag()"
                            >
                                <option [ngValue]="null" selected>
                                    {{ 'ALLE' | translate }}
                                </option>
                                <option
                                    *ngFor="let gemeindeItem of gemeindenList"
                                    [ngValue]="gemeindeItem"
                                >
                                    {{ gemeindeItem.name }}
                                </option>
                            </select>
                        </div>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
        </table>
    </div>
    <div class="dv-table dv-simple-table table-striped" *ngIf="datasource">
        <div class="mat-elevation-z0">
            <mat-table
                matSort
                (matSortChange)="sortData($event)"
                [dataSource]="datasource"
            >
                <ng-container
                    *ngFor="let column of tableColumns"
                    [matColumnDef]="column.attributeName"
                >
                    <mat-header-cell
                        [mat-sort-header]="column.attributeName"
                        *matHeaderCellDef
                        class="ellipsis"
                    >
                        <span>{{ column.displayedName | translate }}</span>
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        class="ellipsis pointer-cursor"
                        (click)="gotoZahlung(element)"
                    >
                        <span
                            [title]="
                                getDisplayValue(element, column) | translate
                            "
                            [innerHTML]="
                                getDisplayValue(element, column) | translate
                            "
                        ></span>
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="'betragTotalAuftrag'">
                    <mat-header-cell *matHeaderCellDef class="ellipsis">
                        <span translate>ZAHLUNG_TOTAL</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis">
                        <i
                            class="fa fa-exclamation-triangle warning-negative-zahlungen"
                            *ngIf="element.hasNegativeZahlungen"
                            [title]="
                                zahlungslaufTyp === 'GEMEINDE_INSTITUTION'
                                    ? ('TOOLTIP_NEGATIVE_WERTE_INSTITUTIONEN'
                                      | translate)
                                    : ('TOOLTIP_NEGATIVE_WERTE_ANTRAGSSTELLENDE'
                                      | translate)
                            "
                        ></i>
                        <span [title]="element.betragTotalAuftrag">{{
                            element.betragTotalAuftrag | currency: '' : ''
                        }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="'zahlungPain'">
                    <mat-header-cell *matHeaderCellDef class="ellipsis">
                        <span
                            [innerHTML]="'ZAHLUNG_PAIN001' | translate"
                        ></span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis">
                        <dv-loading-button
                            type="button"
                            (buttonClick)="downloadPain(element)"
                            [buttonClass]="'dv-btn-operation'"
                        >
                            <i class="fa fa-lg fa-file-code-o"></i>
                        </dv-loading-button>
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="'zahlungInfoma'">
                    <mat-header-cell *matHeaderCellDef class="ellipsis">
                        <span [innerHTML]="'ZAHLUNG_INFOMA' | translate"></span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis">
                        <dv-loading-button
                            *ngIf="element.gemeinde.infomaZahlungen"
                            type="button"
                            (buttonClick)="downloadInfoma(element)"
                            [buttonClass]="'dv-btn-operation'"
                        >
                            <i class="fa fa-lg fa-file-code-o"></i>
                        </dv-loading-button>
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="'zahlungPainExcel'">
                    <mat-header-cell *matHeaderCellDef class="ellipsis">
                        <span translate>ZAHLUNG_DETAILPOSITIONEN</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis">
                        <dv-loading-button
                            type="button"
                            (buttonClick)="downloadAllDetails(element)"
                            [buttonClass]="'dv-btn-operation'"
                        >
                            <i class="fa fa-lg fa-file-code-o"></i>
                        </dv-loading-button>
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="'editSave'">
                    <mat-header-cell *matHeaderCellDef class="ellipsis">
                        <span translate></span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis">
                        <dv-loading-button
                            *ngIf="
                                isEditable(element.status) &&
                                !isEditMode(element.id)
                            "
                            type="button"
                            (buttonClick)="edit(element)"
                            [buttonClass]="'dv-btn-operation'"
                        >
                            <i class="fa fa-lg fa-pencil-square-o"></i>
                        </dv-loading-button>
                        <dv-loading-button
                            *ngIf="isEditMode(element.id) && isEditValid()"
                            type="button"
                            (buttonClick)="save()"
                            [buttonClass]="'dv-btn-operation'"
                        >
                            <i class="fa fa-lg fa-floppy-o"></i>
                        </dv-loading-button>
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="'datumFaellig'">
                    <mat-header-cell
                        *matHeaderCellDef
                        class="ellipsis"
                        [mat-sort-header]="'datumFaellig'"
                    >
                        <span translate>ZAHLUNG_FAELLIGKEIT</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis">
                        <div *ngIf="isEditMode(element.id)" class="form-group">
                            <label
                                class="md-no-float"
                                for="faelligkeitsdatumRow"
                                translate
                                >ZAHLUNG_GENERIERT</label
                            >
                            <input
                                class="dv-mat-datepicker"
                                type="text"
                                name="faelligkeitsdatumRow"
                                id="faelligkeitsdatumRow"
                                [(ngModel)]="zahlungsauftragToEdit.datumFaellig"
                                [matDatepicker]="faelligkeitsdatumRow"
                                required
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="faelligkeitsdatumRow"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                                #faelligkeitsdatumRow
                            ></mat-datepicker>
                            <div
                                ng-messages="vm.form.faelligkeitsdatumRow.$error"
                                role="alert"
                                class="error"
                            ></div>
                        </div>
                        <!-- datumFaellig -->
                        <div
                            *ngIf="!isEditMode(element.id)"
                            (click)="gotoZahlung(element)"
                            class="pointer-cursor full-width-heigth"
                        >
                            <span
                                [attr.title]="
                                    element.datumFaellig.format('DD.MM.YYYY')
                                "
                            >
                                {{ element.datumFaellig.format('DD.MM.YYYY') }}
                            </span>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="'beschrieb'">
                    <mat-header-cell
                        *matHeaderCellDef
                        class="ellipsis"
                        [mat-sort-header]="'beschrieb'"
                    >
                        <span translate>ZAHLUNG_BESCHRIEB</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis">
                        <div *ngIf="isEditMode(element.id)" class="form-group">
                            <label
                                class="md-no-float"
                                for="beschriebRow"
                                translate
                                >BESCHRIEB</label
                            >
                            <input
                                type="text"
                                name="beschriebRow"
                                id="beschriebRow"
                                [(ngModel)]="zahlungsauftragToEdit.beschrieb"
                                required
                            />
                            <div
                                ng-messages="vm.form.faelligkeitsdatumRow.$error"
                                role="alert"
                                class="error"
                            ></div>
                        </div>
                        <!-- datumFaellig -->
                        <div
                            *ngIf="!isEditMode(element.id)"
                            (click)="gotoZahlung(element)"
                            class="pointer-cursor full-width-heigth"
                        >
                            <span [attr.title]="element.beschrieb">
                                {{ element.beschrieb }}
                            </span>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="'ausloesen'">
                    <mat-header-cell *matHeaderCellDef class="ellipsis">
                        <span translate></span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis">
                        <td class="symbol text-center">
                            <dv-loading-button
                                *ngIf="
                                    isEditable(element.status) &&
                                    !isEditMode(element.id)
                                "
                                type="button"
                                (buttonClick)="ausloesen(element.id)"
                                [buttonClass]="'dv-btn-operation'"
                            >
                                <i
                                    class="fa fa-lg fa-arrow-right"
                                    style="color: green"
                                ></i>
                            </dv-loading-button>
                        </td>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="getColumnsAttributeName()"
                ></mat-header-row>
                <mat-row
                    *matRowDef="let element; columns: getColumnsAttributeName()"
                ></mat-row>
            </mat-table>

            <mat-paginator [pageSize]="PAGE_SIZE"></mat-paginator>

            <div class="bottom-pagination">
                <nav>
                    <ul class="pagination">
                        <li
                            *ngFor="let index of paginationItems"
                            [ngClass]="{active: index - 1 === page}"
                        >
                            <a
                                (click)="
                                    handlePagination({pageIndex: index - 1})
                                "
                                >{{ index }}</a
                            >
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
