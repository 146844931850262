/*
 * Copyright (C) 2018 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {StateService} from '@uirouter/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthServiceRS} from '../../../../../authentication/service/AuthServiceRS.rest';
import {GemeindeRS} from '../../../../../gesuch/service/gemeindeRS.rest';
import {GesuchModelManager} from '../../../../../gesuch/service/gesuchModelManager';
import {SearchRS} from '../../../../../gesuch/service/searchRS.rest';
import {TSAntragStatus} from '../../../../../models/enums/TSAntragStatus';
import {TSAntragDTO} from '../../../../../models/TSAntragDTO';
import {TSRoleUtil} from '../../../../../utils/TSRoleUtil';
import {LogFactory} from '@kibon/shared/util-fn/log-factory';
import {DVAntragListFilter} from '../../../../shared/interfaces/DVAntragListFilter';
import {DVAntragListItem} from '../../../../shared/interfaces/DVAntragListItem';
import {DVPaginationEvent} from '../../../../shared/interfaces/DVPaginationEvent';

const LOG = LogFactory.createLog('PendenzenListViewComponent');

@Component({
    selector: 'pendenzen-list-view',
    templateUrl: './pendenzen-list-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendenzenListViewComponent implements OnInit, OnDestroy {
    public hasGemeindenInStatusAngemeldet: boolean = false;

    public data$: BehaviorSubject<DVAntragListItem[]> = new BehaviorSubject<
        DVAntragListItem[]
    >([]);
    public pagination: {
        number: number;
        totalItemCount: number;
        start: number;
    } = {
        number: 20,
        totalItemCount: 0,
        start: 0
    };
    private readonly search: {predicateObject: DVAntragListFilter} = {
        predicateObject: {}
    };

    private sort: {
        predicate?: string;
        reverse?: boolean;
    } = {};

    public initialFilter: DVAntragListFilter = {};

    private readonly unsubscribe$ = new Subject<void>();

    // used to cancel the previous subscription so we don't have two data loads racing each other
    private dataLoadingSubscription: Subscription;

    public constructor(
        private readonly gesuchModelManager: GesuchModelManager,
        private readonly $state: StateService,
        private readonly searchRS: SearchRS,
        private readonly authServiceRS: AuthServiceRS,
        private readonly gemeindeRS: GemeindeRS
    ) {}

    public ngOnInit(): void {
        this.authServiceRS.principal$
            .pipe(filter(principal => !!principal))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                principal => {
                    this.initialFilter.verantwortlicherGemeinde =
                        principal.getFullName();
                    this.search.predicateObject = this.initialFilter;
                    this.countData();
                    this.loadData();
                },
                error => {
                    LOG.error(error);
                }
            );
        this.initHasGemeindenInStatusAngemeldet();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private countData(): void {
        this.searchRS
            .countPendenzenList({
                pagination: this.pagination,
                search: this.search,
                sort: this.sort
            })
            .subscribe(
                response =>
                    (this.pagination.totalItemCount = response ? response : 0),
                error => LOG.error(error)
            );
    }

    private loadData(): void {
        // cancel previous subscription if not closed
        this.dataLoadingSubscription?.unsubscribe();
        this.dataLoadingSubscription = this.searchRS
            .getPendenzenList({
                pagination: this.pagination,
                search: this.search,
                sort: this.sort
            })
            .subscribe(
                response => {
                    // we lose the "this" if we don't map here
                    this.data$.next(
                        response.antragDTOs.map(antragDto => ({
                            fallNummer: antragDto.fallNummer,
                            dossierId: antragDto.dossierId,
                            antragId: antragDto.antragId,
                            gemeinde: antragDto.gemeinde,
                            status: antragDto.status,
                            familienName: antragDto.familienName,
                            kinder: antragDto.kinder,
                            laufNummer: antragDto.laufnummer,
                            antragTyp: antragDto.antragTyp,
                            periodenString: antragDto.gesuchsperiodeString,
                            aenderungsdatum: antragDto.aenderungsdatum,
                            internePendenz: antragDto.internePendenz,
                            internePendenzAbgelaufen:
                                antragDto.internePendenzAbgelaufen,
                            dokumenteHochgeladen:
                                antragDto.dokumenteHochgeladen,
                            angebote: antragDto.angebote,
                            institutionen: antragDto.institutionen,
                            verantwortlicheTS: antragDto.verantwortlicherTS,
                            verantwortlicheBG: antragDto.verantwortlicherBG,
                            hasBesitzer: () => antragDto.hasBesitzer(),
                            isSozialdienst: antragDto.isSozialdienst
                        }))
                    );
                },
                error => LOG.error(error)
            );
    }

    public onFilterChange(listFilter: DVAntragListFilter): void {
        this.search.predicateObject = {
            ...listFilter
        };
        this.loadData();
        this.countData();
    }

    public editpendenzJA(pendenz: TSAntragDTO, event: any): void {
        if (pendenz) {
            const isCtrlKeyPressed: boolean = event && event.ctrlKey;
            this.openPendenz(pendenz, isCtrlKeyPressed);
        }
    }

    private openPendenz(pendenz: TSAntragDTO, isCtrlKeyPressed: boolean): void {
        this.gesuchModelManager.clearGesuch();
        if (
            this.authServiceRS.isOneOfRoles(TSRoleUtil.getSteueramtOnlyRoles())
        ) {
            const navObj: any = {
                gesuchId: pendenz.antragId
            };
            this.navigate('gesuch.familiensituation', navObj, isCtrlKeyPressed);
        } else if (
            pendenz.status === TSAntragStatus.IN_BEARBEITUNG_SOZIALDIENST
        ) {
            const navObj: any = {
                gesuchId: pendenz.antragId,
                dossierId: pendenz.dossierId,
                fallId: pendenz.fallId,
                gemeindeId: pendenz.gemeindeId
            };
            this.navigate(
                'gesuch.sozialdienstfallcreation',
                navObj,
                isCtrlKeyPressed
            );
        } else {
            const navObj: any = {
                gesuchId: pendenz.antragId,
                dossierId: pendenz.dossierId
            };
            this.navigate('gesuch.fallcreation', navObj, isCtrlKeyPressed);
        }
    }

    private navigate(
        path: string,
        navObj: any,
        isCtrlKeyPressed: boolean
    ): void {
        if (isCtrlKeyPressed) {
            const url = this.$state.href(path, navObj);
            window.open(url, '_blank');
        } else {
            this.$state.go(path, navObj);
        }
    }

    private initHasGemeindenInStatusAngemeldet(): void {
        if (
            !this.authServiceRS.isOneOfRoles(
                TSRoleUtil.getAdministratorBgTsGemeindeRole()
            )
        ) {
            return;
        }
        this.gemeindeRS.hasGemeindenInStatusAngemeldet().then(result => {
            this.hasGemeindenInStatusAngemeldet = result;
        });
    }

    public onPagination(paginationEvent: DVPaginationEvent): void {
        this.pagination.number = paginationEvent.pageSize;
        this.pagination.start = paginationEvent.page * paginationEvent.pageSize;

        this.loadData();
    }

    public onSort(sort: {predicate?: string; reverse?: boolean}): void {
        this.sort = sort;

        this.loadData();
    }

    public calculatePage(): number {
        return Math.floor(this.pagination.start / this.pagination.number);
    }
}
