<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2017 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->

<div class="admin-modul" flex="auto">
    <div class="col-md-8 col-md-offset-2">
        <h1 class="access-for-all-title" translate>AFA_VERSENDETE_MAILS</h1>
        <div class="view-title">
            <h2 translate>VERSENDETE_MAILS_TITLE</h2>
            <hr class="header" />
        </div>

        <div class="filter margin-bottom-20">
            <input
                class="filter"
                matInput
                type="text"
                (keyup)="doFilter($event.target.value)"
                placeholder="{{
                    'VERSENDETE_MAILS_SUCHE_PLATZHALTER' | translate
                }}"
                data-test="SearchBarSentMails"
            />
        </div>

        <div class="table-responsive">
            <mat-table #table [dataSource]="dataSource" matSort>
                <!-- Column zeitpunktversand -->
                <ng-container matColumnDef="zeitpunktVersand">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ZEITPUNKTVERSAND_MAIL' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item" data-test="sentTimeMails">
                        {{ item.zeitpunktVersand }}
                    </mat-cell>
                </ng-container>

                <!-- Column empfaengeradresse -->
                <ng-container matColumnDef="empfaengerAdresse">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'EMPFAENGERADRESSE_MAIL' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let item"
                        data-test="sentMailsAdress"
                    >
                        {{ item.empfaengerAdresse }}
                    </mat-cell>
                </ng-container>

                <!-- Column betreff -->
                <ng-container matColumnDef="betreff">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'BETREFF_MAIL' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let item"
                        data-test="sentMailsSubject"
                    >
                        {{ item.betreff }}
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    class="pointer-cursor"
                ></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>
