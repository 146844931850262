/*
 * Copyright (C) 2021 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import {isMoment} from 'moment';
import {CONSTANTS} from '@kibon/shared/model/constants';
@Pipe({
    name: 'ebeguDateTime'
})
export class EbeguDateTimePipe implements PipeTransform {
    public transform(date: moment.Moment): unknown {
        if (!isMoment(date)) {
            return '';
        }
        return date.format(CONSTANTS.DATE_TIME_FORMAT);
    }
}
