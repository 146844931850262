<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="dv-date-picker-x">
    <dv-input-container-x class="form-group">
        <label
            *ngIf="label"
            class="md-no-float"
            [ngClass]="{required: required}"
            [for]="(inputId ? inputId : 'date-') + randId"
            >{{ label | translate
            }}<dv-tooltip
                *ngIf="tooltip"
                [text]="tooltip | translate"
            ></dv-tooltip>
        </label>
        <input
            [ngClass]="{
                'dv-mat-datepicker': datePickerEnabled,
                'dv-mat-datepicker-wide': !datePickerEnabled
            }"
            type="text"
            [name]="(inputId ? inputId : 'date-' + randId) + '-name'"
            [id]="(inputId ? inputId : 'date-') + randId"
            #dateRef="ngModel"
            [(ngModel)]="date"
            [noFutureDate]="noFutureDate"
            (dateChange)="emit()"
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="datePicker"
            placeholder="{{ 'DATE_PLACEHOLDER' | translate }}"
            [required]="required"
            [disabled]="disabled"
        />
        <mat-datepicker-toggle
            *ngIf="datePickerEnabled"
            matSuffix
            [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        @if (dateRef.touched) {
            <dv-error-messages
                [errorObject]="dateRef.errors"
                class="error"
            ></dv-error-messages>
        }
    </dv-input-container-x>
</div>
