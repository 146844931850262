<h2 mat-dialog-title *ngIf="frage" [innerHTML]="frage | translate"></h2>

<mat-dialog-content *ngIf="text" class="margin-bottom-20">
    <span [innerHTML]="text | translate"></span>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dv-dialog-actions">
        <button
            class="dv-btn"
            (click)="ja()"
            [textContent]="'LABEL_JA' | translate"
            data-test="remove-ok"
        ></button>
        <button
            class="dv-btn"
            (click)="nein()"
            [textContent]="'LABEL_NEIN' | translate"
            data-test="remove-ok"
        ></button>
        <button
            class="dv-btn"
            (click)="cancel()"
            [textContent]="'CANCEL' | translate"
            data-test="remove-cancel"
        ></button>
    </div>
</mat-dialog-actions>
