<!--
  ~ Copyright (C) 2024 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="gesuch-modul">
    <h1 class="access-for-all-title" translate>AFA_GESUCHSFORMULAR</h1>

    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                <span translate [translateParams]="{basisjahr: ''}"
                    >FINANZIELLE_SITUATION_RESULTATE</span
                >
            </h2>
            <hr class="header" />
        </div>
    </div>
    <form #form="ngForm" disabled>
        <div class="row">
            <div class="col-sm-6">
                <dv-input-label-field
                    [model]="resultate?.massgebendesEinkVorAbzFamGrGS1"
                    [labelMessageKey]="
                        'FINANZIELLE_SITUATION_MASSGEBENDES_EINKOMMEN_MIT_GS'
                    "
                    [labelMessageKeyArgs]="{namegs: extractFullNameGS1()}"
                    [inputDisabled]="true"
                    data-test="massgebendesEinkommenGS1"
                >
                </dv-input-label-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <dv-input-label-field
                    [model]="resultate?.massgebendesEinkVorAbzFamGrGS2"
                    [labelMessageKey]="
                        'FINANZIELLE_SITUATION_MASSGEBENDES_EINKOMMEN_MIT_GS'
                    "
                    [labelMessageKeyArgs]="{namegs: extractFullNameGS2()}"
                    [inputDisabled]="true"
                    data-test="massgebendesEinkommenGS2"
                >
                </dv-input-label-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <dv-input-label-field
                    [model]="resultate?.massgebendesEinkVorAbzFamGr"
                    [labelMessageKey]="
                        'FINANZIELLE_SITUATION_MASSGEBENDES_EINKOMMEN'
                    "
                    [inputDisabled]="true"
                    data-test="massgebendesEinkommenTotal"
                >
                </dv-input-label-field>
            </div>
        </div>
    </form>

    <dv-navigation-x
        (dvSave)="navigate($event.onResult)"
        [dvNext]="true"
        [dvPrevious]="true"
        [dvSubStep]="gesuchmodelManager.gesuchstellerNumber"
        [dvSubStepName]="getSubStepName()"
    ></dv-navigation-x>
</div>
