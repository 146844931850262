/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

export enum TSDokumentTyp {
    NACHWEIS_TRENNUNG = 'NACHWEIS_TRENNUNG',
    FACHSTELLENBESTAETIGUNG = 'FACHSTELLENBESTAETIGUNG',
    NACHWEIS_ERWERBSPENSUM = 'NACHWEIS_ERWERBSPENSUM',
    NACHWEIS_SELBSTAENDIGKEIT = 'NACHWEIS_SELBSTAENDIGKEIT',
    NACHWEIS_AUSBILDUNG = 'NACHWEIS_AUSBILDUNG',
    NACHWEIS_RAV = 'NACHWEIS_RAV',
    BESTAETIGUNG_ARZT = 'BESTAETIGUNG_ARZT',
    STEUERVERANLAGUNG = 'STEUERVERANLAGUNG',
    STEUERERKLAERUNG = 'STEUERERKLAERUNG',
    JAHRESLOHNAUSWEISE = 'JAHRESLOHNAUSWEISE',
    NACHWEIS_FAMILIENZULAGEN = 'NACHWEIS_FAMILIENZULAGEN',
    NACHWEIS_ERSATZEINKOMMEN = 'NACHWEIS_ERSATZEINKOMMEN',
    NACHWEIS_ERHALTENE_ALIMENTE = 'NACHWEIS_ERHALTENE_ALIMENTE',
    NACHWEIS_GELEISTETE_ALIMENTE = 'NACHWEIS_GELEISTETE_ALIMENTE',
    NACHWEIS_VERMOEGEN = 'NACHWEIS_VERMOEGEN',
    NACHWEIS_SCHULDEN = 'NACHWEIS_SCHULDEN',
    ERFOLGSRECHNUNGEN_JAHR = 'ERFOLGSRECHNUNGEN_JAHR',
    ERFOLGSRECHNUNGEN_JAHR_MINUS1 = 'ERFOLGSRECHNUNGEN_JAHR_MINUS1',
    ERFOLGSRECHNUNGEN_JAHR_MINUS2 = 'ERFOLGSRECHNUNGEN_JAHR_MINUS2',
    DIV = 'DIV',
    ORIGINAL_PAPIERGESUCH = 'ORIGINAL_PAPIERGESUCH',
    UNTERSTUETZUNGSBESTAETIGUNG = 'UNTERSTUETZUNGSBESTAETIGUNG',
    ERLAUTERUNG_ZUR_VERFUEGUNG = 'ERLAUTERUNG_ZUR_VERFUEGUNG',
    VORLAGE_MERKBLATT_TS = 'VORLAGE_MERKBLATT_TS',
    MERKBLATT_ANMELDUNG_TS = 'MERKBLATT_ANMELDUNG_TS',
    VORLAGE_VERFUEGUNG_LATS = 'VORLAGE_VERFUEGUNG_LATS',
    VORLAGE_VERFUEGUNG_FERIENBETREUUNG = 'VORLAGE_VERFUEGUNG_FERIENBETREUUNG',
    AUSWEIS_ID = 'AUSWEIS_ID',
    NACHWEIS_ABGERECHNETE_QUELLENSTEUERN = 'NACHWEIS_ABGERECHNETE_QUELLENSTEUERN',
    NACHWEIS_NETTOLOHN = 'NACHWEIS_NETTOLOHN',
    NACHWEIS_EINKAEUFE_VORSORGE = 'NACHWEIS_EINKAEUFE_VORSORGE',
    NACHWEIS_ABZUEGE_LIEGENSCHAFT = 'NACHWEIS_ABZUEGE_LIEGENSCHAFT',
    NACHWEIS_HOEHERE_BEITRAEGE_BEEINTRAECHTIGUNG = 'NACHWEIS_HOEHERE_BEITRAEGE_BEEINTRAECHTIGUNG'
}
