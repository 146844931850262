<div class="gesuch-modul">
    <h1 class="access-for-all-title" translate>AFA_GESUCHSFORMULAR</h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2>
                <span
                    translate
                    [translateParams]="{gesuchsteller: '', basisjahr: ''}"
                    >FINANZIELLE_SITUATION</span
                >
            </h2>
            <hr class="header" />
        </div>
    </div>

    <ng-container
        *ngIf="
            !hasMultipleGS || gs2Ausgefuellt;
            then allGSAusgefuellt;
            else notAllGSAusgefuellt
        "
    ></ng-container>
</div>

<ng-template #allGSAusgefuellt>
    <form #form="ngForm" class="gesuch">
        <dv-auszahlungsdaten
            [auszahlungsdaten]="model.zahlungsinformationen"
            [auszahlungsdatenGS]="model.zahlungsinformationenGS"
            [showAddress]="true"
        >
        </dv-auszahlungsdaten>

        <ng-container
            *ngIf="hasMultipleGS; then mehrereGS; else alleinigesGesuch"
        ></ng-container>

        <ng-template #mehrereGS>
            <dv-steuerveranlagung-gemeinsam
                [model]="model"
                (gemeinsamChanged)="this.gemeinsamChanged($event)"
            ></dv-steuerveranlagung-gemeinsam>
            <dv-steuerveranlagt-schwyz
                *ngIf="model.familienSituation.gemeinsameSteuererklaerung"
                [finSitJA]="model.getFiSiConToWorkWith().finanzielleSituationJA"
                [finSitGS]="model.getFiSiConToWorkWith().finanzielleSituationGS"
                [readonly]="gesuchModelManager.isGesuchReadonly()"
                (valueChanged)="recalculateMassgendesEinkommen()"
            >
            </dv-steuerveranlagt-schwyz>
            <div
                class="row margin-bottom-20"
                *ngIf="model.familienSituation.gemeinsameSteuererklaerung"
            >
                <div class="col-sm-6">
                    <dv-input-label-field
                        [model]="resultate?.massgebendesEinkVorAbzFamGr"
                        [labelMessageKey]="
                            'FINANZIELLE_SITUATION_MASSGEBENDES_EINKOMMEN'
                        "
                        [inputDisabled]="true"
                    >
                    </dv-input-label-field>
                </div>
            </div>
        </ng-template>

        <ng-template #alleinigesGesuch>
            <dv-finanzielle-situation-single-gs-schwyz
                [readonly]="gesuchModelManager.isGesuchReadonly()"
                (valueChanged)="recalculateMassgendesEinkommen()"
                [finanzModel]="model"
            >
            </dv-finanzielle-situation-single-gs-schwyz>

            <div
                class="row margin-bottom-20"
                *ngIf="
                    isNotNullOrUndefined(
                        getModel().finanzielleSituationJA.quellenbesteuert
                    )
                "
            >
                <div class="col-sm-6">
                    <dv-input-label-field
                        [model]="resultate?.massgebendesEinkVorAbzFamGr"
                        [labelMessageKey]="
                            'FINANZIELLE_SITUATION_MASSGEBENDES_EINKOMMEN'
                        "
                        [inputDisabled]="true"
                    >
                    </dv-input-label-field>
                </div>
            </div>
        </ng-template>
    </form>

    <dv-navigation-x
        (dvSave)="prepareSave($event.onResult)"
        [dvNext]="true"
        [dvPrevious]="true"
        [dvSubStep]="getSubStepIndex()"
        [dvSubStepName]="getSubStepName()"
    ></dv-navigation-x>
</ng-template>

<ng-template #notAllGSAusgefuellt>
    <div data-test="warnung-gs2-ausfuellen" class="well well-status-warten">
        <span translate>WARNUNG_GS2_AUSFUELLEN</span>
    </div>
</ng-template>
