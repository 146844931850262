<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="row">
    <div class="col-xs-12 margin-bottom-30">
        <mat-divider class="margin-bottom-50"></mat-divider>
        <h3 class="uppercase ebegu-h1" *ngIf="isGemeinsam">
            {{
                'VERANLAGUNG_GEMEINSAM_TITLE'
                    | translate
                        : {
                              gs1: antragsteller1Name(),
                              gs2: antragsteller2Name(),
                              year: year
                          }
            }}
        </h3>
        <h3 class="uppercase ebegu-h1" *ngIf="!isGemeinsam">
            {{
                'VERANLAGUNG_ALLEINE_TITLE'
                    | translate
                        : {
                              gs1: antragstellerName(),
                              year: year
                          }
            }}
        </h3>
    </div>
</div>
<fieldset>
    <div class="form-group">
        <div class="row">
            <div class="col-sm-12 dv-input-container-medium">
                <div class="form-group">
                    <dv-input-label-field
                        [name]="'steuerbaresEinkommen'"
                        [(model)]="
                            model.finanzielleSituationJA.steuerbaresEinkommen
                        "
                        [labelMessageKey]="
                            'FINANZIELLE_SITUATION_STEUERBARES_EINKOMMEN'
                        "
                        [inputDisabled]="readOnly"
                        [dvOnBlur]="onValueChangeFunction"
                        [inputRequired]="true"
                        [deklaration]="
                            model.finanzielleSituationGS?.steuerbaresEinkommen
                        "
                        [korrektur]="
                            model.finanzielleSituationJA.steuerbaresEinkommen
                        "
                        [showBisher]="
                            isNotNullOrUndefined(model.finanzielleSituationGS)
                        "
                    >
                    </dv-input-label-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 dv-input-container-medium">
                <div class="form-group">
                    <dv-input-label-field
                        [name]="'steuerbaresVermoegen'"
                        [(model)]="
                            model.finanzielleSituationJA.steuerbaresVermoegen
                        "
                        [labelMessageKey]="
                            'FINANZIELLE_SITUATION_STEUERBARES_VERMOEGEN'
                        "
                        [inputDisabled]="readOnly"
                        [dvOnBlur]="onValueChangeFunction"
                        [inputRequired]="true"
                        [deklaration]="
                            model.finanzielleSituationGS?.steuerbaresVermoegen
                        "
                        [korrektur]="
                            model.finanzielleSituationJA.steuerbaresVermoegen
                        "
                        [showBisher]="
                            isNotNullOrUndefined(model.finanzielleSituationGS)
                        "
                    >
                    </dv-input-label-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 dv-input-container-medium">
                <div class="form-group">
                    <dv-input-label-field
                        [name]="'abzuegeLiegenschaft'"
                        [(model)]="
                            model.finanzielleSituationJA.abzuegeLiegenschaft
                        "
                        [labelMessageKey]="
                            'FINANZIELLE_SITUATION_ABZUEGE_LIEGENSCHAFT'
                        "
                        [tooltipMessageKey]="
                            'FINANZIELLE_SITUATION_ABZUEGE_LIEGENSCHAFT_TOOLTIP'
                        "
                        [inputDisabled]="readOnly"
                        [dvOnBlur]="onValueChangeFunction"
                        [inputRequired]="true"
                        [allowNegative]="true"
                        [deklaration]="
                            model.finanzielleSituationGS?.abzuegeLiegenschaft
                        "
                        [korrektur]="
                            model.finanzielleSituationJA.abzuegeLiegenschaft
                        "
                        [showBisher]="
                            isNotNullOrUndefined(model.finanzielleSituationGS)
                        "
                    >
                    </dv-input-label-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 dv-input-container-medium">
                <div class="form-group">
                    <dv-input-label-field
                        [name]="'geschaeftsverlust'"
                        [(model)]="
                            model.finanzielleSituationJA.geschaeftsverlust
                        "
                        [labelMessageKey]="
                            'FINANZIELLE_SITUATION_GESCHAEFTSVERLUST'
                        "
                        [tooltipMessageKey]="
                            'FINANZIELLE_SITUATION_GESCHAEFTSVERLUST_TOOLTIP'
                        "
                        [inputDisabled]="readOnly"
                        [dvOnBlur]="onValueChangeFunction"
                        [inputRequired]="true"
                        [deklaration]="
                            model.finanzielleSituationGS?.geschaeftsverlust
                        "
                        [korrektur]="
                            model.finanzielleSituationJA.geschaeftsverlust
                        "
                        [showBisher]="
                            isNotNullOrUndefined(model.finanzielleSituationGS)
                        "
                    >
                    </dv-input-label-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 dv-input-container-medium">
                <div class="form-group">
                    <dv-input-label-field
                        [name]="'einkaeufeVorsorge'"
                        [(model)]="
                            model.finanzielleSituationJA.einkaeufeVorsorge
                        "
                        [labelMessageKey]="
                            'FINANZIELLE_SITUATION_EINKAEUFE_VORSORGE'
                        "
                        [tooltipMessageKey]="
                            'FINANZIELLE_SITUATION_EINKAEUFE_VORSORGE_TOOLTIP'
                        "
                        [inputDisabled]="readOnly"
                        [dvOnBlur]="onValueChangeFunction"
                        [inputRequired]="true"
                        [deklaration]="
                            model.finanzielleSituationGS?.einkaeufeVorsorge
                        "
                        [korrektur]="
                            model.finanzielleSituationJA.einkaeufeVorsorge
                        "
                        [showBisher]="
                            isNotNullOrUndefined(model.finanzielleSituationGS)
                        "
                    >
                    </dv-input-label-field>
                </div>
            </div>
        </div>
    </div>
</fieldset>
