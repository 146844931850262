import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
    TSBetreuungsstandort,
    TSInstitutionStammdaten
} from '@kibon/shared/model/entity';
import {CONSTANTS} from '@kibon/shared/model/constants';

@Component({
    selector: 'dv-edit-betreuungsstandort',
    templateUrl: './edit-betreuungsstandort.component.html',
    styleUrls: ['./edit-betreuungsstandort.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBetreuungsstandortComponent {
    @Input()
    public stammdaten: TSInstitutionStammdaten;

    public readonly CONSTANTS: any = CONSTANTS;

    public addStandort(): void {
        const newStandort = new TSBetreuungsstandort();
        this.stammdaten.institutionStammdatenBetreuungsgutscheine.betreuungsstandorte.push(
            newStandort
        );
    }

    public removeAllStandorte(): void {
        this.stammdaten.institutionStammdatenBetreuungsgutscheine.betreuungsstandorte =
            [];
    }

    /**
     * Für den Moment sind maximal zwei Standorte möglich. Der erste Standort wird jeweils aus den Stammdaten
     * übernommen.
     */
    public toggleMehrereBetreuungsstandorte(): void {
        if (
            this.stammdaten.institutionStammdatenBetreuungsgutscheine
                .betreuungsstandorte.length === 0
        ) {
            this.addStandort();
            return;
        }
        this.removeAllStandorte();
    }
}
