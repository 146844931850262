<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->
<div
    class="gemeinde-antraege-view"
    dv-show-element
    dv-show-allowed-roles="vm.TSRoleUtil.getAllRolesForLastenausgleich()"
>
    <form
        role="form"
        class=""
        unsaved-warning-form=""
        novalidate
        dv-show-element
        [formGroup]="formGroup"
        *ngIf="canCreateAntrag() | async"
    >
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="row gemeinde-antraege-form layout-row" layout="row">
                    <!-- Jahr -->
                    <div class="col-md-3">
                        <label
                            class="md-no-float required"
                            for="gemeindeantragperiode"
                            translate
                            >GESUCHSPERIODEN</label
                        >
                        <select
                            aria-describedby="gemeindeantragtyp-error"
                            name="gemeindeantragperiode"
                            id="gemeindeantragperiode"
                            [formControl]="formGroup.controls.periode"
                            class="form-control"
                        >
                            <option value="" style="display: none"></option>
                            <option
                                *ngFor="
                                    let periode of getGesuchsperiodenOptions()
                                "
                                [value]="periode.id"
                            >
                                {{ periode.gesuchsperiodeString }}
                            </option>
                        </select>
                        <div class="dv-error-messages">
                            <div
                                *ngIf="
                                    (formGroup.controls.periode.touched ||
                                        triedSending) &&
                                    formGroup.controls.periode.errors?.required
                                "
                                class="error"
                                translate
                            >
                                ERROR_REQUIRED
                            </div>
                        </div>
                    </div>

                    <!-- Gemeindeantragtyp -->
                    <div
                        [ngClass]="{
                            'col-md-3': ferienBetreuungSelected(),
                            'col-md-5': !ferienBetreuungSelected()
                        }"
                    >
                        <div>
                            <label
                                class="md-no-float required"
                                for="gemeindeantragtyp"
                                translate
                                >TYP</label
                            >
                            <select
                                aria-describedby="gemeindeantragtyp-error"
                                name="gemeindeantragtyp"
                                id="gemeindeantragtyp"
                                [formControl]="formGroup.controls.antragTyp"
                                (change)="onAntragTypChange()"
                                class="form-control"
                            >
                                <option
                                    *ngFor="let typ of creatableTypes"
                                    [value]="typ"
                                    translate
                                >
                                    {{ typ }}
                                </option>
                            </select>
                            <div class="dv-error-messages">
                                <div
                                    *ngIf="
                                        (formGroup.controls.antragTyp.touched ||
                                            triedSending) &&
                                        formGroup.controls.antragTyp.errors
                                            ?.required
                                    "
                                    class="error"
                                    translate
                                >
                                    ERROR_REQUIRED
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--                        Gemeinde-->
                    <div class="col-md-3" *ngIf="ferienBetreuungSelected()">
                        <div>
                            <label
                                class="md-no-float"
                                [ngClass]="{
                                    required: ferienBetreuungSelected()
                                }"
                                for="select-gemeinde"
                                translate
                                >GEMEINDE</label
                            >
                            <select
                                aria-describedby="select-gemeinde-error"
                                name="gemeinde"
                                id="select-gemeinde"
                                [formControl]="formGroup.controls.gemeinde"
                                class="form-control"
                            >
                                <option
                                    *ngFor="let gemeinde of gemeinden"
                                    [value]="gemeinde.id"
                                >
                                    {{ gemeinde.name }}
                                </option>
                            </select>
                            <div class="dv-error-messages">
                                <div
                                    *ngIf="
                                        (formGroup.controls.gemeinde.touched ||
                                            triedSending) &&
                                        formGroup.controls.gemeinde.errors
                                            ?.required
                                    "
                                    class="error"
                                    translate
                                >
                                    ERROR_REQUIRED
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        *ngIf="
                            !ferienBetreuungSelected() && isMandant() | async
                        "
                        class="col-md-3 layout-align-center-center layout-row flex actions"
                        flex
                        layout="row"
                        layout-align="center center"
                    >
                        <div
                            dvLoadingButtonX
                            [ariaLabel]="'LATS_ANTRAEGE_ERSTELLEN' | translate"
                            type="submit"
                            (buttonClick)="createAllAntraege()"
                        >
                            <span class="uppercase" translate
                                >LATS_ANTRAEGE_ERSTELLEN</span
                            >
                        </div>
                        <button
                            *ngIf="latsDeletePossible$ | async"
                            type="button"
                            class="dv-btn cancel-button margin-left-5"
                            (click)="deleteAllLatsAntraege()"
                        >
                            <span class="uppercase" translate
                                >LATS_ANTRAEGE_LOESCHEN</span
                            >
                        </button>
                    </div>

                    <div
                        *ngIf="ferienBetreuungSelected()"
                        class="col-md-3 layout-align-center-center layout-row flex actions"
                        flex
                        layout="row"
                        layout-align="center center"
                    >
                        <div
                            dvLoadingButtonX
                            [ariaLabel]="
                                'GEMEINDE_ANTRAEGE_ANTRAG_ERSTELLEN' | translate
                            "
                            type="submit"
                            (buttonClick)="createAntrag()"
                        >
                            <span class="uppercase" translate
                                >GEMEINDE_ANTRAEGE_ANTRAG_ERSTELLEN</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <dv-new-antrag-list
        [hiddenColumns]="hiddenDVTableColumns"
        [data$]="antragList$"
        (rowClicked)="navigate($event.antrag, $event.event)"
        [page]="calculatePage()"
        (filterChange)="onFilterChange($event)"
        (sortChange)="onSortChange($event)"
        (paginationEvent)="onPagination($event)"
        [filterStateList]="getStateFilter()"
        [filterTypeList]="types"
        [stateStoreId]="'gemeindeantraege'"
        [showRemoveButton]="isMandant() | async"
        (removeClicked)="deleteGemeindeAntrag($event)"
        [totalItems]="totalItems"
    ></dv-new-antrag-list>
</div>
