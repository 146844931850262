<!--
  ~ Copyright (C) 2021 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<dv-fall-toolbar
    [fallId]="getFallId()"
    [dossierId]="dossier?.id"
    [nameGs]="gs1Name"
    hide-xs
    hide-sm
>
</dv-fall-toolbar>

<dossier-toolbar
    flex="nogrow"
    *ngIf="dossierToolbarVisible()"
    dossierId="{{ dossier?.id }}"
>
</dossier-toolbar>

<dossier-toolbar-gesuchsteller
    flex="nogrow"
    class="dossier-toolbar-gesuchsteller"
    dossierId="{{ dossier?.id }}"
    *ngIf="dossierToolbarGesuchstellerVisible()"
>
</dossier-toolbar-gesuchsteller>

<div class="dv-view overflow-auto" flex="auto">
    <div class="row">
        <div class="col-md-11">
            <h3 translate="VERLAUF_LIST"></h3>
        </div>
        <div class="col-md-1 text-right">
            <h3>{{ tableData?.length || 0 }}</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <dv-simple-table
                *ngIf="tableData"
                [columns]="tableColumns"
                [data]="tableData"
                [config]="tableConfig"
            >
            </dv-simple-table>
        </div>
    </div>
</div>
