<!--
  ~ Copyright (C) 2022 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="gesuch-modul">
    <h1 class="access-for-all-title" translate>AFA_GESUCHSFORMULAR</h1>
    <div class="row view-title">
        <div class="col-md-12">
            <h2 *ngIf="!isGemeinsam()">
                <span
                    translate
                    [translateParams]="{
                        gesuchsteller: this.gesuchModelManager
                            .getStammdatenToWorkWith()
                            ?.extractFullName(),
                        basisjahr:
                            this.gesuchModelManager.getBasisjahrToWorkWith()
                    }"
                    >EKVI_EINKOMMENSVERSCHLECHTERUNG_FUER_BASISJAHR</span
                >
            </h2>
            <h2 *ngIf="isGemeinsam()">
                <span
                    translate
                    [translateParams]="{
                        gesuchsteller: this.ekvViewUtil.getGemeinsameFullname(
                            this.gesuchModelManager
                        ),
                        basisjahr:
                            this.gesuchModelManager.getBasisjahrToWorkWith()
                    }"
                    >EKVI_EINKOMMENSVERSCHLECHTERUNG_FUER_BASISJAHR</span
                >
            </h2>
            <hr class="header" />
            <!-- Infotext -->
            <p class="inline-hint" data-translate="EKVI_HINT_MONAT_FELDER"></p>
        </div>
    </div>
    <div class="row">
        <form role="form" #form="ngForm" novalidate>
            <div class="col-md-12">
                <dv-selbstdeklaration
                    [antragstellerNummer]="this.model.getGesuchstellerNumber()"
                    [isGemeinsam]="isGemeinsam()"
                    [readOnly]="this.gesuchModelManager.isGesuchReadonly()"
                    [finanzModel]="model"
                    [model]="this.model.getEkvToWorkWith()"
                    [basisJahr]="
                        this.gesuchModelManager
                            .getBasisjahrToWorkWith()
                            .toString()
                    "
                    [basisJahrPlus]="
                        this.gesuchModelManager.getBasisJahrPlusNumber()
                    "
                    [isEKV]="true"
                    [isQuellenbesteuert]="
                        this.model.getFiSiConToWorkWith().finanzielleSituationJA
                            .quellenbesteuert
                    "
                    [isKorrekturModusJungendamtOrFreigegeben]="
                        this.isKorrekturModusJugendamtOrFreigegeben()
                    "
                ></dv-selbstdeklaration>
                <dv-navigation-x
                    (dvSave)="save($event.onResult)"
                    [dvPrevious]="true"
                    [dvNext]="true"
                    [dvSubStep]="3"
                ></dv-navigation-x>
            </div>
        </form>
    </div>
</div>
