<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2018 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->

<h2 mat-dialog-title [textContent]="title | translate"></h2>

<mat-dialog-content>
    <a
        class="md-default-theme"
        (click)="close()"
        href="{{ link }}"
        data-test="dialog-link"
    >
        <span class="ng-binding ng-scope" [textContent]="link"></span>
    </a>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dv-dialog-actions">
        <button
            class="dv-btn"
            (click)="close()"
            [textContent]="'LABEL_OK' | translate"
        ></button>
    </div>
</mat-dialog-actions>
