/*
 * Ki-Tax: System for the management of external childcare subsidies
 * Copyright (C) 2017 City of Bern Switzerland
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

import {IComponentOptions} from 'angular';

export class DvMobileNavigationToggleComponentConfig
    implements IComponentOptions
{
    public transclude = false;
    public bindings = {};
    public template = require('./dv-mobile-navigation-toggle.html');
    public controller = DvMobileNavigationToggleController;
    public controllerAs = 'vm';
}

export class DvMobileNavigationToggleController {
    public static $inject: ReadonlyArray<string> = ['$mdSidenav'];

    public constructor(
        private readonly $mdSidenav: ng.material.ISidenavService
    ) {}

    public toggleSidenav(componentId: string): void {
        this.$mdSidenav(componentId).toggle();
    }

    public closeSidenav(componentId: string): void {
        this.$mdSidenav(componentId).close();
    }
}
