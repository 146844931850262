<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="tutorial-login row">
    <h2 class="col-md-4 col-md-offset-4 tutorial-willkommen" translate>
        TUTORIAL_WILLKOMMEN
    </h2>

    <div class="col-md-4 col-md-offset-4">
        <div>
            <h3 class="tutorial-login-h2" translate>
                TUTORIAL_INSTITUTION_TEXT
            </h3>
        </div>
        <div class="tutorial-login-button">
            <div
                class="first-row panel panel-default mark-hover panel-body"
                [textContent]="'TUTORIAL_LOGIN' | translate"
                (click)="logIn()"
            ></div>
        </div>
    </div>
</div>
