<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<dv-full-height-inner-padding-container>
    <form
        role="form"
        #form="ngForm"
        [ngClass]="{'ng-submitted': form.submitted}"
        *ngIf="stammdaten$ | async as stammdaten"
        (ngSubmit)="persistGemeindeStammdaten(stammdaten)"
        novalidate
    >
        <dv-stammdaten-header
            (logoImageChange)="collectLogoChange($event)"
            [preTitel]="stammdaten.gemeinde.bfsNummer.toString()"
            [titel]="getHeaderTitle(stammdaten.gemeinde)"
            [administratoren]="stammdaten.administratoren"
            [sachbearbeiter]="stammdaten.sachbearbeiter"
            [logoImageUrl]="getLogoImageUrl(stammdaten.gemeinde)"
            [allowedRoles]="getMitarbeiterVisibleRoles()"
            [editMode]="editMode"
        >
            <!-- this is the edit view -->
        </dv-stammdaten-header>

        <mat-tab-group
            dynamicHeight
            [(selectedIndex)]="currentTab"
            animationDuration="0ms"
        >
            <mat-tab
                label="{{ 'GEMEINDE_TAB_STAMMDATEN' | translate }}"
                ngForm="gemeinde_stammdaten"
            >
                <dv-edit-gemeinde-stammdaten
                    [stammdaten$]="stammdaten$"
                    [gemeindeId]="gemeindeId"
                    [editMode]="editMode"
                    [(altTSAdresse)]="altTSAdresse"
                    [tageschuleEnabledForMandant]="tageschuleEnabledForMandant"
                    [tfoEnabledForMandant]="tfoEnabledForMandant"
                    [gemeindeVereinfachteKonfigAktiv]="
                        gemeindeVereinfachteKonfigAktiv
                    "
                >
                </dv-edit-gemeinde-stammdaten>
            </mat-tab>
            <mat-tab
                label="{{ 'GEMEINDE_TAB_BG' | translate }}"
                ngForm="gemeinde_bg"
                *ngIf="stammdaten.gemeinde.angebotBG"
            >
                <dv-edit-gemeinde-bg
                    [stammdaten$]="stammdaten$"
                    [gemeindeId]="gemeindeId"
                    [beguStartStr]="beguStartStr"
                    [editMode]="isEditModeForBG()"
                    [(altBGAdresse)]="altBGAdresse"
                    [(keineBeschwerdeAdresse)]="keineBeschwerdeAdresse"
                    [beguStartDatum]="beguStartDatum"
                    [gemeindeList$]="gemeindeList$"
                    [gemeindeVereinfachteKonfigAktiv]="
                        gemeindeVereinfachteKonfigAktiv
                    "
                >
                </dv-edit-gemeinde-bg>
            </mat-tab>
            <mat-tab
                label="{{ 'GEMEINDE_TAB_TS' | translate }}"
                ngForm="gemeinde_ts"
                *ngIf="
                    tageschuleEnabledForMandant && stammdaten.gemeinde.angebotTS
                "
            >
                <dv-edit-gemeinde-ts
                    (altLogoImageChange)="altCollectLogoChange($event)"
                    [stammdaten$]="stammdaten$"
                    [gemeindeId]="gemeindeId"
                    [editMode]="editModeForTSFI()"
                    [(altTSAdresse)]="altTSAdresse"
                    [tsAnmeldungenStartStr]="tsAnmeldungenStartStr"
                    [tsAnmeldungenStartDatum]="tsAnmeldungenStartDatum"
                    [externalClients]="externalClients"
                    [isSuperAdmin]="isSuperAdmin()"
                    [(usernameScolaris)]="usernameScolaris"
                    [altLogoImageUrl]="
                        getAlternativeLogoImageUrl(stammdaten.gemeinde)
                    "
                >
                </dv-edit-gemeinde-ts>
            </mat-tab>
            <mat-tab
                label="{{ 'GEMEINDE_TAB_FI' | translate }}"
                ngForm="gemeinde_fi"
                *ngIf="
                    tageschuleEnabledForMandant && stammdaten.gemeinde.angebotFI
                "
            >
                <dv-edit-gemeinde-fi
                    [stammdaten$]="stammdaten$"
                    [gemeindeId]="gemeindeId"
                    [editMode]="editModeForTSFI()"
                    [fiAnmeldungenStartDatum]="fiAnmeldungenStartDatum"
                    [fiAnmeldungenStartStr]="fiAnmeldungenStartStr"
                >
                </dv-edit-gemeinde-fi>
            </mat-tab>
            <mat-tab
                label="{{ 'GEMEINDE_TAB_KORRESPONDENZ' | translate }}"
                ngForm="gemeinde_korrespondenz"
            >
                <dv-edit-gemeinde-korrespondenz
                    [stammdaten$]="stammdaten$"
                    [editMode]="editMode"
                >
                </dv-edit-gemeinde-korrespondenz>
            </mat-tab>
            <mat-tab
                label="{{ 'GEMEINDE_TAB_INSTITUTION' | translate }}"
                ngForm="gemeinde_institution"
                *ngIf="erlaubenInstitutionenZuWaehlen"
            >
                <dv-edit-gemeinde-institution
                    [stammdaten]="stammdaten"
                    [editMode]="editMode"
                >
                </dv-edit-gemeinde-institution>
            </mat-tab>
        </mat-tab-group>

        <!-- Buttons -->
        <div class="dv-footer">
            <dv-loading-button
                type="button"
                *ngIf="!editMode && isGemeindeEditable()"
                data-test="container.edit"
                (buttonClick)="setEditMode()"
            >
                <span translate>GEMEINDE_EDIT</span>
            </dv-loading-button>
            <dv-loading-button
                *ngIf="editMode"
                data-test="container.save"
                [type]="'submit'"
            >
                <span translate>GEMEINDE_SPEICHERN</span>
            </dv-loading-button>

            <button
                type="button"
                class="dv-btn cancel-button"
                data-test="container.cancel"
                *ngIf="!isRegistering()"
                (click)="cancel()"
                [textContent]="'CANCEL' | translate"
            ></button>
        </div>
    </form>
</dv-full-height-inner-padding-container>
