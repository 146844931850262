<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2017 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->

<mat-dialog-content id="dialogContent" role="document" tabindex="-1">
    <ng-container [ngSwitch]="mandant$ | async">
        <ng-container
            *ngSwitchCase="mandantTypes.BERN"
            [ngTemplateOutlet]="be"
        ></ng-container>
        <ng-container
            *ngSwitchCase="mandantTypes.LUZERN"
            [ngTemplateOutlet]="lu"
        ></ng-container>
        <ng-container
            *ngSwitchCase="mandantTypes.SOLOTHURN"
            [ngTemplateOutlet]="so"
        ></ng-container>
        <ng-container
            *ngSwitchCase="mandantTypes.APPENZELL_AUSSERRHODEN"
            [ngTemplateOutlet]="ar"
        ></ng-container>
        <ng-container
            *ngSwitchCase="mandantTypes.SCHWYZ"
            [ngTemplateOutlet]="sz"
        ></ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="be"></ng-container>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions class="margin-top-40" layout-align="center center">
    <div class="dv-dialog-actions">
        <button
            (click)="close()"
            [textContent]="'SCHLIESSEN' | translate"
            class="dv-btn"
        ></button>
    </div>
</mat-dialog-actions>

<ng-template #be>
    <div class="margin-top-10">
        <h2 [textContent]="'TECHNISCHE_HILFE' | translate"></h2>
        <p>DV Bern AG</p>
        <a (click)="openSupportanfrage()" class="a-link">
            <i class="fa fa-question-circle-o">&nbsp;</i>
            <span translate="SUPPORTANFRAGE_STELLEN"></span>
        </a>
        <p><a href="tel:+41 31 378 24 33">+41 31 378 24 33</a></p>
        <p>
            <a
                href="https://get.teamviewer.com/dvbv11"
                rel="noopener noreferrer"
                target="_blank"
                translate
                >TEAMVIEWER_LINK</a
            >
        </p>
    </div>

    <div class="margin-top-40">
        <h2 [textContent]="'FACHLICHE_HILFE_BG' | translate"></h2>
        <p [textContent]="'MANDANT_DIREKTION_BG' | translate"></p>
        <p><a href="mailto:info.bg@be.ch">info.bg&#64;be.ch</a></p>
        <p><a href="tel:+41 31 633 78 83">+41 31 633 78 83</a></p>
    </div>

    <div class="margin-top-40">
        <h2 [textContent]="'FACHLICHE_HILFE_TS' | translate"></h2>
        <p [textContent]="'MANDANT_DIREKTION_TS' | translate"></p>
        <p>
            <a [href]="'MANDANT_MAIL_BKD_LINK' | translate">{{
                'MANDANT_MAIL_BKD' | translate
            }}</a>
        </p>
        <p>
            <a [href]="'MANDANT_TEL_BKD_LINK' | translate">{{
                'MANDANT_TEL_BKD' | translate
            }}</a>
        </p>
    </div>

    <div class="margin-top-40">
        <h2 [textContent]="'SCHNELLHILFE' | translate"></h2>
        <p>
            <a (click)="startTour()" href="">
                <span translate="TOUR_STARTEN"></span>
            </a>
        </p>
    </div>

    <ng-container [ngTemplateOutlet]="blog"></ng-container>
</ng-template>

<ng-template #blog>
    <div *ngIf="hasRoleGemeinde || hasRoleInstitution" class="margin-top-40">
        <h2>Blog</h2>
        <p *ngIf="hasRoleMandant">
            <a
                href="{{ 'BLOG_MANDANT_LINK' | translate }}"
                rel="noopener noreferrer"
                target="_blank"
            >
                <span translate="KANTON"></span>
            </a>
        </p>
        <p *ngIf="hasRoleGemeinde">
            <a
                href="{{ 'BLOG_GEMEINDE_LINK' | translate }}"
                rel="noopener noreferrer"
                target="_blank"
            >
                <span translate="GEMEINDE"></span>
            </a>
        </p>
        <p *ngIf="hasRoleInstitution">
            <a
                href="{{ 'BLOG_INSTITUTION_LINK' | translate }}"
                rel="noopener noreferrer"
                target="_blank"
            >
                <span translate="INSTITUTION"></span>
            </a>
        </p>
    </div>
</ng-template>

<ng-template #so>
    <div class="margin-top-40">
        <h2 [textContent]="'FACHLICHE_HILFE_BG' | translate"></h2>
        <p [innerHTML]="'MANDANT_DIREKTION_BG' | translate"></p>
        <p><a href="tel:+41 58 258 16 82">+41 58 258 16 82</a></p>
        <p><a href="mailto:kibon@vseg.ch">kibon&#64;vseg.ch</a></p>
    </div>

    <div class="margin-top-40">
        <h2 [textContent]="'SCHNELLHILFE' | translate"></h2>
        <p>
            <a (click)="startTour()" href="">
                <span translate="TOUR_STARTEN"></span>
            </a>
        </p>
    </div>
</ng-template>

<ng-template #lu>
    <div class="margin-top-40">
        <h2 [textContent]="'FACHLICHE_HILFE_BG' | translate"></h2>
        <br />
        <p [innerHTML]="'MANDANT_DIREKTION_BG' | translate"></p>
        <p><a href="tel:+41 41 208 81 90">+41 41 208 81 90</a></p>
        <p>
            <a href="mailto:betreuungsgutscheine@stadtluzern.ch"
                >betreuungsgutscheine&#64;stadtluzern.ch</a
            >
        </p>
        <p>
            <a href="https://www.kinderbetreuung.stadtluzern.ch"
                >www.kinderbetreuung.stadtluzern.ch</a
            >
        </p>
        <br />
        <p [innerHTML]="'MANDANT_DIREKTION_BG_HORW' | translate"></p>
        <p><a href="tel:+41 41 349 12 41">+41 41 349 12 41</a></p>
        <p>
            <a href="mailto:Sozial@horw.ch">Sozial&#64;horw.ch</a>
        </p>
        <p>
            <a href="https://www.horw.ch/dienstleistungen/16789"
                >Gemeinde Horw - Betreuungsgutscheine</a
            >
        </p>
    </div>

    <div class="margin-top-40">
        <h2 [textContent]="'SCHNELLHILFE' | translate"></h2>
        <p>
            <a (click)="startTour()" href="">
                <span translate="TOUR_STARTEN"></span>
            </a>
        </p>
    </div>
</ng-template>

<ng-template #ar>
    <div class="margin-top-40">
        <h2 [textContent]="'FACHLICHE_HILFE_BG' | translate"></h2>
        <p><a href="tel:071 354 51 51">071 354 51 51</a></p>
        <p><a href="mailto:kibeg@sovar.ch">kibeg&#64;sovar.ch</a></p>
    </div>

    <div class="margin-top-40">
        <h2 [textContent]="'SCHNELLHILFE' | translate"></h2>
        <p>
            <a (click)="startTour()" href="">
                <span translate="TOUR_STARTEN"></span>
            </a>
        </p>
    </div>
</ng-template>

<ng-template #sz>
    <div class="margin-top-10">
        <h2 [textContent]="'TECHNISCHE_HILFE' | translate"></h2>
        <p>DV Bern AG</p>
        <a (click)="openSupportanfrage()" class="a-link">
            <i class="fa fa-question-circle-o">&nbsp;</i>
            <span translate="SUPPORTANFRAGE_STELLEN"></span>
        </a>
        <p><a href="tel:+41 31 378 24 33">+41 31 378 24 33</a></p>
        <p>
            <a
                href="https://get.teamviewer.com/dvbv11"
                rel="noopener noreferrer"
                target="_blank"
                translate
                >TEAMVIEWER_LINK</a
            >
        </p>
    </div>
    <div class="margin-top-40">
        <h2 [textContent]="'FACHLICHE_HILFE_BG' | translate"></h2>
        <p [innerHTML]="'MANDANT_DIREKTION_BG' | translate"></p>
    </div>

    <div class="margin-top-40">
        <h2 [textContent]="'SCHNELLHILFE' | translate"></h2>
        <p>
            <a (click)="startTour()" href="">
                <span translate="TOUR_STARTEN"></span>
            </a>
        </p>
    </div>
</ng-template>
