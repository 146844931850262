<!--
  ~ Copyright (C) 2022 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<mat-dialog-content>
    <div class="gesuch-modul">
        <h1 class="access-for-all-title" translate>AFA_GESUCHSFORMULAR</h1>
        <div class="row view-title">
            <div class="col-md-12">
                <h2>
                    <span
                        translate
                        [translateParams]="{gs1: gs1Name(), gs2: gs2Name()}"
                        >FINANZIELLE_SITUATION_AUFTEILUNG</span
                    >
                </h2>
                <hr class="header" />
                <p
                    class="inline-hint"
                    [innerHTML]="
                        'FINANZIELLE_SITUATION_AUFTEILUNG_HINT'
                            | translate: {gs1: gs1Name(), gs2: gs2Name()}
                    "
                ></p>
            </div>
        </div>
        <form role="form" #form="ngForm" novalidate>
            <ng-container *ngIf="aufteilungDTO">
                <dv-aufteilung
                    [aufteilung]="aufteilungDTO.bruttoertraegeVermoegen"
                    [groupName]="'bruttoertraegeVermoegen'"
                    [label]="'FINANZIELLE_SITUATION_BRUTTOERTRAEGE_VERMOEGEN'"
                ></dv-aufteilung>
                <dv-aufteilung
                    [aufteilung]="aufteilungDTO.nettoertraegeErbengemeinschaft"
                    [groupName]="'nettoertraegeErbengemeinschaft'"
                    [label]="
                        'FINANZIELLE_SITUATION_NETTOERTRAEGE_ERBENGEMEINSCHAFTEN'
                    "
                    [allowNegative]="true"
                ></dv-aufteilung>
                <dv-aufteilung
                    [aufteilung]="aufteilungDTO.abzugSchuldzinsen"
                    [groupName]="'abzugSchuldzinsen'"
                    [label]="'FINANZIELLE_SITUATION_ABZUG_SCHULDZINSEN'"
                ></dv-aufteilung>
                <dv-aufteilung
                    [aufteilung]="aufteilungDTO.gewinnungskosten"
                    [groupName]="'gewinnungskosten'"
                    [label]="'FINANZIELLE_SITUATION_ABZUG_GEWINNUNGSKOSTEN'"
                ></dv-aufteilung>
                <dv-aufteilung
                    [aufteilung]="aufteilungDTO.geleisteteAlimente"
                    [groupName]="'geleisteteAlimente'"
                    [label]="'FINANZIELLE_SITUATION_GELEISTETEALIMENTE'"
                ></dv-aufteilung>
                <dv-aufteilung
                    [aufteilung]="aufteilungDTO.nettovermoegen"
                    [groupName]="'nettovermoegen'"
                    [label]="'FINANZIELLE_SITUATION_NETTOVERMOEGEN_TITLE'"
                    [allowNegative]="true"
                ></dv-aufteilung>
            </ng-container>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dv-dialog-actions">
        <button
            [disabled]="!isValid()"
            class="dv-btn"
            (click)="save()"
            translate
        >
            SAVE
        </button>
        <button
            class="dv-btn cancel-button"
            (click)="close()"
            [textContent]="'CANCEL' | translate"
        ></button>
    </div>
</mat-dialog-actions>
