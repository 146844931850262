/*
 * Copyright (C) 2018 DV Bern AG, Switzerland
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {
    Category,
    HookResult,
    Transition,
    TransitionService
} from '@uirouter/core';
import {LogFactory, LogLevel} from '@kibon/shared/util-fn/log-factory';
import {OnBeforePriorities} from './onBeforePriorities';

const LOG = LogFactory.createLog('debugHookRunBlock');

/**
 * This file contains a Transition Hook which enables debug output when ?debug is appended to the URL.
 */

export function debugHookRunBlock($transitions: TransitionService): void {
    $transitions.onBefore(
        {
            // the hook is only active when the app loads initially, e.g. when we are not yet on any state
            from: state => !state.name
        },
        activateDebugMode,
        {priority: OnBeforePriorities.DEBUG}
    );
}

function activateDebugMode(transition: Transition): HookResult {
    const debug = !!transition.params().debug;
    if (!debug) {
        return;
    }

    LOG.info('activating debug log level and transition tracing');
    transition.router.trace.enable(Category.TRANSITION, Category.HOOK);
    LogFactory.logLevel = LogLevel.DEBUG;
}
